import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PortalLists from './PortalLists';
import { PortalList } from 'containers/common/user/Portal/PortalListingType';
import usePortalId from 'hooks/portal/usePortalId';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  open: boolean;
  handleOpenClose: () => void;
  portals: PortalList[];
  disconnectPortal: (id: number) => void;
}
type Props = OwnProps;
const PortalListDialogue: React.FC<Props> = ({
  open,
  handleOpenClose,
  portals,
  disconnectPortal,
}) => {
  const { t } = useTranslation();
  const portalIdCurrent = usePortalId();

  return (
    <Dialog
      onClose={handleOpenClose}
      open={open}
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>{t('hubspot_portals')}</DialogTitle>

      <PortalLists
        currentPortalId={portalIdCurrent}
        handleOpenClose={handleOpenClose}
        portals={portals}
        disconnectPortal={disconnectPortal}
      />
    </Dialog>
  );
};
export default PortalListDialogue;
