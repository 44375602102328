import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  open: boolean;
  handleOpenCloseConfirmDialogue: () => void;
  disconnectPortal: (event: any) => void;
}
type Props = OwnProps;
const DeletePortalConfirmDialogue: React.FC<Props> = ({
  open,
  handleOpenCloseConfirmDialogue,
  disconnectPortal,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>{t('cancel_subscription')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingTop: '25px' }}>
          <b>{t('warning')}: </b>
          {t('deletePortalWarning')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={disconnectPortal}>{t('delete_portal')}</Button>
        <Button onClick={handleOpenCloseConfirmDialogue}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeletePortalConfirmDialogue;
