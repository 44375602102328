import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_USER } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children?: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_USER.portals} />;
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
}
