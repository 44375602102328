import { TableRow, TableCell, Typography, Box } from '@mui/material';
import Label from 'components/Label';
import { fDate } from 'utils/formatTime';
import { CustomerClaimRewards } from '../PointsRewardsType';

type Props = {
  row: CustomerClaimRewards;
};

export default function CustomerHistoryTableRow({ row }: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    created_at,
    reward_name,
    usage_status,
    code,
    redeemed_points,
    used_at,
  } = row;

  const usedAtArr: number[] = [];
  used_at.map(val => {
    usedAtArr.push(val.order_id);
  });

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap align='left'>
          {fDate(Number(created_at))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {reward_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          <Label
            color={(usage_status === 'used' && 'success') || 'default'}
            sx={{ textTransform: 'capitalize' }}
          >
            {usage_status}
          </Label>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {code}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {redeemed_points}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {usedAtArr.join(',')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
