import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs, Typography } from '@mui/material';
import {
  PATH_USER,
  POINTSANDREWARD,
  POINTS_AND_REWARDS_NAVIGATION,
} from 'routes/paths';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import usePortalId from 'hooks/portal/usePortalId';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StoreSetup from './child/StoreSetup';
import General from './child/General';
import Configuration from './child/Configuration';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import Notices from 'containers/common/Notices/Notices';

export default function Settings() {
  useIfValidityExpired(POINTSANDREWARD);
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const ACCOUNT_TABS = [
    {
      value: 'store-setup',
      icon: '',
      component: <StoreSetup />,
    },
    {
      value: 'general',
      icon: '',
      component: <General />,
    },
    {
      value: 'configuration',
      icon: '',
      component: <Configuration />,
    },
  ];

  return (
    <Page title={t('settings')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant='h4' gutterBottom>
          {t('settings')}
        </Typography>
        <HeaderBreadcrumbs
          heading={''}
          links={[
            {
              name: t('app_listing'),
              href: PATH_USER.appListing(portalId),
            },
            {
              name: t('points_and_rewards'),
              href: '',
            },
            {
              name: t('settings'),
              href: '',
            },
          ]}
        />
        <Notices appName={POINTSANDREWARD} />
        <Box sx={{ mb: 5 }} />

        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={tab}
          onChange={(e, tabIndex) =>
            navigate(
              POINTS_AND_REWARDS_NAVIGATION.general.settings(portalId, tabIndex)
            )
          }
        >
          {ACCOUNT_TABS.map(tab => (
            <Tab
              disableRipple
              key={t(tab.value)}
              label={capitalCase(t(tab.value))}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map(tabVal => {
          const isMatched = tabVal.value === tab;

          return isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
