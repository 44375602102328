import {
  AppDetailsType,
  CouponDetailType,
  HubSpotUserDetailsType,
  ISources,
  StoreDetailType,
} from './../../containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import axiosInstance from '../../utils/axios';
import { useQuery, UseQueryResult } from 'react-query';
import {
  COUPON_RULE_DETAIL_API_URL,
  COUPON_RULE_LISTING_API_URL,
  GET_APP_DETAIL_API_URL,
  GET_HUBSPOT_USERS,
  GET_SOURCES_URL,
  STORE_DETAIL_API_URL,
  STORE_LISTING_API_URL,
} from '../ApiConstants/DiscountCouponsApiConstants';
import {
  CouponRuleListingType,
  WooStore,
} from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';

const couponListingRequest = async (
  portalId: number
): Promise<CouponRuleListingType> => {
  const { data } = await axiosInstance.get<CouponRuleListingType>(
    `${COUPON_RULE_LISTING_API_URL}`,
    {
      params: {
        portalId,
      },
    }
  );

  return data;
};

export const useCouponListing = (
  portalId: number
): UseQueryResult<CouponRuleListingType> => {
  return useQuery(
    [COUPON_RULE_LISTING_API_URL],
    () => couponListingRequest(portalId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const wooCommerceStoresRequest = async (
  portalId: number,
  appCode: string
): Promise<{
  data: WooStore[];
}> => {
  const data = await axiosInstance.get<{
    data: WooStore[];
  }>(STORE_LISTING_API_URL, {
    params: {
      portalId,
      appCode,
    },
  });

  return data.data;
};

export const useWooCommerceStores = (
  portalId: number,
  appCode: string
): UseQueryResult<{
  data: WooStore[];
}> => {
  return useQuery(
    [STORE_LISTING_API_URL],
    () => wooCommerceStoresRequest(portalId, appCode),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const getAllSourcesRequest = async (): Promise<{ data: ISources[] }> => {
  const { data } = await axiosInstance.get<{ data: ISources[] }>(
    `${GET_SOURCES_URL}`
  );

  return data;
};

export const useGetAllSources = (): UseQueryResult<{ data: ISources[] }> => {
  return useQuery([GET_SOURCES_URL], () => getAllSourcesRequest(), {
    refetchOnWindowFocus: false,
  });
};

const storeDetailRequest = async (
  portalId: number,
  storeId: number,
  appCode: string
): Promise<StoreDetailType> => {
  const { data } = await axiosInstance.get<StoreDetailType>(
    `${STORE_DETAIL_API_URL}`,
    {
      params: {
        portalId,
        storeId,
        appCode,
      },
    }
  );

  return data;
};

export const useStoreDetails = (
  portalId: number,
  storeId: number,
  appCode: string
): UseQueryResult<StoreDetailType> => {
  return useQuery(
    [STORE_DETAIL_API_URL],
    () => storeDetailRequest(portalId, storeId, appCode),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const couponDetailRequest = async (
  portalId: number,
  couponId: string
): Promise<CouponDetailType> => {
  const { data } = await axiosInstance.get<CouponDetailType>(
    `${COUPON_RULE_DETAIL_API_URL}`,
    {
      params: {
        portalId,
        couponId,
      },
    }
  );

  return data;
};

export const useCouponDetails = (
  portalId: number,
  couponId: string
): UseQueryResult<CouponDetailType> => {
  return useQuery(
    [COUPON_RULE_DETAIL_API_URL],
    () => couponDetailRequest(portalId, couponId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const appDetailRequest = async (
  portalId: number,
  appName: string
): Promise<AppDetailsType> => {
  const { data } = await axiosInstance.get<AppDetailsType>(
    `${GET_APP_DETAIL_API_URL}`,
    {
      params: {
        portal_id: portalId,
        app_name: appName,
      },
    }
  );

  return data;
};

export const useAppDetail = (
  portalId: number,
  appName: string
): UseQueryResult<AppDetailsType> => {
  return useQuery(
    [GET_APP_DETAIL_API_URL],
    () => appDetailRequest(portalId, appName),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const hubSpotUserRequest = async (
  portalId: number
): Promise<HubSpotUserDetailsType> => {
  const { data } = await axiosInstance.get<HubSpotUserDetailsType>(
    `${GET_HUBSPOT_USERS}`,
    {
      params: {
        portal_id: portalId,
      },
    }
  );

  return data;
};

export const useHubSpotUser = (
  portalId: number
): UseQueryResult<HubSpotUserDetailsType> => {
  return useQuery([GET_HUBSPOT_USERS], () => hubSpotUserRequest(portalId), {
    refetchOnWindowFocus: false,
  });
};
