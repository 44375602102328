import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  open: boolean;
  title: string;
  content: string;
  handleOpenCloseConfirmDialogue: () => void;
  handleDelete: () => void;
}
type Props = OwnProps;
const DeleteConfirmDialogue: React.FC<Props> = ({
  open,
  title,
  content,
  handleOpenCloseConfirmDialogue,
  handleDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingTop: '25px' }}>
          <b>{t('warning')}: </b>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>{t('yes')}</Button>
        <Button onClick={handleOpenCloseConfirmDialogue}>{t('no')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteConfirmDialogue;
