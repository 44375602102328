import { TableRow, TableCell, Typography } from '@mui/material';
import Label from 'components/Label';
import { t } from 'i18next';
import { fDate } from 'utils/formatTime';
import { CustomerActivity } from '../PointsRewardsType';

type Props = {
  row: CustomerActivity;
};

export default function CustomerHistoryTableRow({ row }: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { created_at, points, status, event, message } = row;

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap align='left'>
          {fDate(Number(created_at))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {points}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          <Label
            color={
              (status === 'approved' && 'success') ||
              (status === 'hold' && 'warning') ||
              'default'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {t(status)}
          </Label>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='right'>
          {event}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap align='center'>
          {message}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
