// @mui
import { Stack, Typography, Card, Box } from '@mui/material';
// utils
import { fShortenNumber } from 'utils/formatNumber';
// components
import Iconify from 'components/Iconify';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

type Props = {
  icon: string;
  title: string;
  total: number;
  currency?: string;
};

export default function CustomerAnalytic({
  title,
  total,
  icon,
  currency,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        minWidth: '19%',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          mt: 2,
          my: 2,
        }}
        spacing={1}
      >
        <Iconify icon={icon} sx={{ width: 32, height: 32 }} />
        <Typography variant='h5'>
          {title === t('total_revenue') && currency !== undefined ? (
            <span dangerouslySetInnerHTML={{ __html: currency + ' ' }}></span>
          ) : null}
          {fShortenNumber(total)}{' '}
        </Typography>
        <Box
          component='span'
          sx={{ color: 'text.secondary', typography: 'body2' }}
        >
          {title}
        </Box>
      </Stack>
    </Card>
  );
}
