import { Card, Container, Pagination, Stack, TableBody } from '@mui/material';
import usePortalId from 'hooks/portal/usePortalId';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  REWARD_LISTING_API_URL,
  REWARD_ENABLE_DISABLE_API_URL,
  DELETE_REWARD_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { useQuery } from 'react-query';
import axiosInstance from 'utils/axios';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { TableNoData } from 'components/table';
import Iconify from 'components/Iconify';
import { LoadingButton } from '@mui/lab';
import useActiveApps from 'hooks/apps/useActiveApps';
import {
  PATH_PAGE,
  POINTSANDREWARD,
  POINTS_AND_REWARDS_NAVIGATION,
} from 'routes/paths';
import {
  RewardListingObjType,
  RewardListingType,
} from '../../PointsRewardsType';
import RewardTableRow from '../../Table/RewardTableRow';
import DeleteRewardConfirmDialog from './DeleteRewardConfirmDialog';

type Props = {
  storeId: string;
};
const RewardsListingTable: FunctionComponent<Props> = ({ storeId }) => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [loading, setLoading] = useState(false);
  const activeApps = useActiveApps(portalId);
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [skip, setSkip] = useState(0);

  const ROWS_PER_PAGE = 10;

  async function fetchData(storeId: string) {
    const response = await axiosInstance.get<RewardListingType>(
      REWARD_LISTING_API_URL,
      {
        params: {
          storeId,
          portalId,
          limit: ROWS_PER_PAGE,
          ...(skip ? { skip } : {}),
        },
      }
    );

    return response.data;
  }

  const {
    data: rewardResponse,
    isFetching,
    refetch,
  } = useQuery<RewardListingType>(
    [REWARD_LISTING_API_URL, skip],
    () => fetchData(storeId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const navigate = useNavigate();

  if (!activeApps.includes(POINTSANDREWARD)) {
    navigate(PATH_PAGE.page404);
  }

  if (!rewardResponse) {
    return <PartialPageSpinner />;
  }

  const handleChangeSwitch = async (_id: string, enabled: boolean) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        REWARD_ENABLE_DISABLE_API_URL + _id,
        {
          storeId: storeId,
          portalId: portalId,
          is_enabled: enabled,
        }
      );
      if (res.data.success) {
        setLoading(false);
        refetch();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleManageCode = (id: string) => {
    navigate(
      POINTS_AND_REWARDS_NAVIGATION.general.manageCodesReward(portalId, id)
    );
  };

  const handleEditRow = (id: string) => {
    navigate(POINTS_AND_REWARDS_NAVIGATION.general.editReward(portalId, id));
  };

  const deleteReward = async (_id: string) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(DELETE_REWARD_API_URL + _id, {
        portalId: Number(portalId),
        storeId: Number(storeId),
      });
      if (res.data.success) {
        setLoading(false);
        enqueueSnackbar(res.data.message, { variant: 'success' });
        refetch();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = (_id: string) => {
    confirmDialogueOpenClose();
    if (_id) {
      setSelectedId(_id);
    }
  };

  const confirmDialogueOpenClose = () => {
    setConfirmDialogueOpen(!confirmDialogueOpen);
  };

  let contentLoad: React.ReactNode = {};

  if (isFetching || loading) {
    contentLoad = <PartialPageSpinner />;
  }

  const totalPage = rewardResponse
    ? Math.ceil((rewardResponse?.totalCount ?? 0) / ROWS_PER_PAGE)
    : 0;

  const mapPages = (data: RewardListingObjType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <RewardTableRow
          key={row._id}
          row={row}
          onChangeSwitch={(enabled: boolean) =>
            handleChangeSwitch(row._id, enabled)
          }
          onManageCode={() => handleManageCode(row._id)}
          onEditRow={() => handleEditRow(row._id)}
          onDeleteRow={() => handleDeleteRow(row._id)}
        />
      );
    });

    return rows;
  };

  if (rewardResponse) {
    contentLoad = rewardResponse.data.length ? (
      mapPages(rewardResponse.data)
    ) : (
      <>
        <TableBody sx={{ display: 'flex', justifyContent: 'center' }}>
          <TableNoData isNotFound={true} Message={t('reward')} />
        </TableBody>
      </>
    );
  }

  return (
    <Container>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <LoadingButton
          sx={{
            alignItems: 'center',
            borderRadius: '8px',
            padding: '6px 8px',
            marginBottom: '10px',
            width: '175px',
          }}
          variant='text'
          onClick={() =>
            navigate(
              POINTS_AND_REWARDS_NAVIGATION.general.createReward(portalId)
            )
          }
          startIcon={<Iconify icon='eva:plus-fill' />}
          loading={false}
        >
          {t('create_new_reward')}
        </LoadingButton>
      </Stack>
      {contentLoad}
      {rewardResponse && totalPage > 1 && (
        <Card
          sx={{
            display: 'flex',
            paddingBottom: '25px',
            justifyContent: 'center',
            paddingTop: '10px',
          }}
        >
          <Pagination
            color='primary'
            variant='outlined'
            count={totalPage}
            page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
            onChange={(e, pageNo) => setSkip((pageNo - 1) * ROWS_PER_PAGE)}
          />
        </Card>
      )}
      <Stack>
        <DeleteRewardConfirmDialog
          open={confirmDialogueOpen}
          handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
          deleteReward={() => {
            confirmDialogueOpenClose();
            deleteReward(selectedId);
          }}
        />
      </Stack>
    </Container>
  );
};

export default RewardsListingTable;
