import { LoadingButton } from '@mui/lab';
import { Card, Typography, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { POINTS_AND_REWARDS_NAVIGATION } from 'routes/paths';

export default function AddStore() {
  const portalId = usePortalId();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card sx={{ p: 5, marginTop: 5 }}>
      <Stack>
        <Typography variant='h5'>{t('you_have_no_store_added')}</Typography>
        <LoadingButton
          fullWidth
          sx={{ marginTop: '35px' }}
          variant='contained'
          onClick={() =>
            navigate(
              POINTS_AND_REWARDS_NAVIGATION.general.createStore(portalId)
            )
          }
          startIcon={<Iconify icon='eva:plus-fill' />}
          loading={false}
        >
          {t('create_new_store')}
        </LoadingButton>
      </Stack>
    </Card>
  );
}
