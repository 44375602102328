import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import AppCard from 'components/AppCard';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import {
  SCHEDULE_NOW_MEETING_URL,
  SUPPORT_URL,
  TUTORIAL_URL,
} from 'routes/paths';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import { useAppListing } from 'api/ApiHooks/GeneralHooks';
import usePortalId from 'hooks/portal/usePortalId';

export default function EcommerceProductList() {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { user } = useAuth();
  const { themeStretch } = useSettings();
  const smUp = useResponsive('up', 'sm');
  const { data } = useAppListing(Number(portalId));

  if (data === undefined) {
    return <PartialPageSpinner />;
  }
  const appList = data?.data;

  return (
    <Page title={t('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ backgroundColor: '#CCF1FF', marginBottom: '32px' }}>
          <CardContent
            sx={{
              padding: '16px 32px',
              color: 'grey.800',
            }}
          >
            <Box>
              <Grid>
                <Typography gutterBottom variant='h4'>
                  {t('need_help')}, {user?.email}?
                </Typography>
                <Stack width='80%'>
                  <Typography variant='body2'>
                    {t('schedule_meeting')}
                    <Link
                      target='_blank'
                      variant='body2'
                      href={SCHEDULE_NOW_MEETING_URL}
                    >
                      {' - '}
                      {t('schedule_now')}
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
              <Button
                variant='contained'
                href={SUPPORT_URL}
                sx={
                  smUp
                    ? {
                        position: 'absolute',
                        right: 32,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: '#2196F3',
                      }
                    : {
                        position: 'unset',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '40px',
                        right: 32,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: '#2196F3',
                      }
                }
              >
                {t('support')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: smUp ? 'row' : 'column',
            flexWrap: 'wrap',
          }}
        >
          <Card
            sx={{
              width: '100%',
              padding: '24px',
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {appList.map((app, index) => {
                  return <AppCard row={app} key={index} />;
                })}
                <Box
                  sx={{
                    pt: 4,
                    pl: smUp ? 4 : 2,
                    mx: 'auto',
                    mt: smUp ? 10 : '',
                  }}
                >
                  <iframe
                    width={smUp ? '560' : 'auto'}
                    height={smUp ? '315' : 'auto'}
                    src='https://www.youtube.com/embed/ALsHxN02Z4k'
                    title='YouTube video player'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    // allowfullscreen
                  ></iframe>
                </Box>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
