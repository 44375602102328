import { useRewardDetails } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import RewardForm from '../../child/form/RewardForm';

type Props = {
  storeId: string;
};

const EditRewardWrapper: FunctionComponent<Props> = ({ storeId }) => {
  const portalId = usePortalId();
  const { rewardId } = useParams<{
    rewardId: string;
  }>();

  const { data: rewardDetailResponse, isFetching } = useRewardDetails(
    Number(portalId),
    storeId,
    rewardId!
  );

  if (isFetching) {
    return <PartialPageSpinner />;
  }
  const rewardDetails = rewardDetailResponse?.data;

  return (
    <RewardForm type='edit' rewardDetails={rewardDetails} storeId={storeId} />
  );
};

export default EditRewardWrapper;
