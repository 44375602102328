import {
  GET_WOO_BRANDS,
  STORE_LISTING_API_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import {
  APP_LISTING_API_URL,
  APP_PLANS_API_URL,
  GET_ALL_APPS_WITH_BILLING_DETAILS_API_URL,
  PAYMENT_SUMMARY_API_URL,
  PORTAL_APP_PAYMENT_API_URL,
  PORTAL_LISTING_API_URL,
  WOO_PRODUCTS_GET_URL,
} from 'api/ApiConstants/GeneralConstants';
import {
  StoreDetailType,
  StoreListingType,
} from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import {
  AppListingType,
  IWooBrands,
  IWooProducts,
} from 'containers/common/dashboard/EcommerceProductListType';
import {
  AllAppsWithBillingDetailType,
  AppPlanDataType,
  PaymentSummaryType,
  PortalAppPaymentType,
} from 'containers/common/payments/PaymentType';
import { PortalListingType } from 'containers/common/user/Portal/PortalListingType';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import axiosInstance from 'utils/axios';

const appListingRequest = async (portalId: number): Promise<AppListingType> => {
  const { data } = await axiosInstance.get<AppListingType>(
    `${APP_LISTING_API_URL}`,
    {
      params: {
        portal_id: portalId,
      },
    }
  );

  return data;
};

export const useAppListing = (
  portalId: number
): UseQueryResult<AppListingType> => {
  return useQuery([APP_LISTING_API_URL], () => appListingRequest(portalId), {
    refetchOnWindowFocus: false,
  });
};

const portalListingRequest = async (): Promise<PortalListingType> => {
  const { data } = await axiosInstance.get<PortalListingType>(
    `${PORTAL_LISTING_API_URL}`
  );

  return data;
};

export const usePortalListing = (): UseQueryResult<PortalListingType> => {
  return useQuery([PORTAL_LISTING_API_URL], () => portalListingRequest(), {
    refetchOnWindowFocus: false,
  });
};

const getAllAppsWithBillingDetailsRequest = async (
  portalId: number
): Promise<AllAppsWithBillingDetailType> => {
  const { data } = await axiosInstance.get<AllAppsWithBillingDetailType>(
    `${GET_ALL_APPS_WITH_BILLING_DETAILS_API_URL}?portal_id=${portalId}`
  );

  return data;
};

export const useGetAllAppsWithBillingDetails = (
  portalId: number
): UseQueryResult<AllAppsWithBillingDetailType> => {
  return useQuery(
    [GET_ALL_APPS_WITH_BILLING_DETAILS_API_URL],
    () => getAllAppsWithBillingDetailsRequest(portalId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const paymentSummaryRequest = async (
  portalId: number
): Promise<PaymentSummaryType> => {
  const { data } = await axiosInstance.get<PaymentSummaryType>(
    `${PAYMENT_SUMMARY_API_URL}?portal_id=${portalId}`
  );

  return data;
};

export const usePaymentSummary = (
  portalId: number
): UseQueryResult<PaymentSummaryType> => {
  return useQuery(
    [PAYMENT_SUMMARY_API_URL],
    () => paymentSummaryRequest(portalId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const portalAppPaymentApiRequest = async (
  portalId: number
): Promise<PortalAppPaymentType> => {
  const { data } = await axiosInstance.get<PortalAppPaymentType>(
    `${PORTAL_APP_PAYMENT_API_URL}?portal_id=${portalId}`
  );

  return data;
};

export const usePortalAppPayment = (
  portalId: number
): UseQueryResult<PortalAppPaymentType> => {
  return useQuery(
    [PORTAL_APP_PAYMENT_API_URL],
    () => portalAppPaymentApiRequest(portalId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const appPlansRequest = async (appName: string): Promise<AppPlanDataType> => {
  const { data } = await axiosInstance.get<AppPlanDataType>(
    `${APP_PLANS_API_URL}?app_name=${appName}`
  );

  return data;
};

export const useAppPlans = (
  appName: string
): UseQueryResult<AppPlanDataType> => {
  return useQuery([APP_PLANS_API_URL], () => appPlansRequest(appName), {
    refetchOnWindowFocus: false,
  });
};

const wooProductsRequest = async (
  storeId: number,
  search: string,
  page: number,
  appCode: string,
  portalId: number,
  brand: string | string[]
): Promise<{ data: IWooProducts[]; currentPage: number }> => {
  const { data } = await axiosInstance.get<{
    data: IWooProducts[];
    currentPage: number;
  }>(WOO_PRODUCTS_GET_URL, {
    params: {
      appCode,
      storeId,
      search,
      page: page,
      per_page: 20,
      portalId,
      brand,
    },
  });

  return data;
};

export const useWooProducts = (params: {
  appCode: string;
  storeId: number;
  search: string;
  portalId: number;
  brand: string | string[];
}): UseInfiniteQueryResult<{
  data: IWooProducts[];
  plugin_brand?: string;
  currentPage: number;
}> => {
  return useInfiniteQuery(
    [
      WOO_PRODUCTS_GET_URL,
      params.search,
      params.storeId,
      params.appCode,
      params.portalId,
      params.brand,
    ],
    ({ pageParam }) =>
      wooProductsRequest(
        params.storeId,
        params.search,
        pageParam,
        params.appCode,
        params.portalId,
        params.brand
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: prevPage =>
        prevPage.data.length > 0 ? prevPage.currentPage + 1 : undefined,
      enabled: params.storeId !== -1,
    }
  );
};

const wooBrandsRequest = async (
  storeId: number,
  search: string | string[],
  page: number,
  appCode: string,
  portalId: number
): Promise<{ data: IWooBrands[]; currentPage: number }> => {
  const { data } = await axiosInstance.get<{
    data: IWooBrands[];
    currentPage: number;
  }>(GET_WOO_BRANDS, {
    params: {
      appCode,
      storeId,
      search,
      page: page,
      per_page: 10,
      portalId,
    },
  });

  return data;
};

export const useWooBrands = (params: {
  appCode: string;
  storeId: number;
  search: string;
  portalId: number;
}): UseInfiniteQueryResult<{
  data: IWooBrands[];
  currentPage: number;
}> => {
  return useInfiniteQuery(
    [
      GET_WOO_BRANDS,
      params.search,
      params.storeId,
      params.appCode,
      params.portalId,
    ],
    ({ pageParam }) =>
      wooBrandsRequest(
        params.storeId,
        params.search,
        pageParam,
        params.appCode,
        params.portalId
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: prevPage =>
        prevPage.data.length > 0 ? prevPage.currentPage + 1 : undefined,
      enabled: params.storeId !== -1,
      keepPreviousData: true,
    }
  );
};

async function fetchStoreData(
  portalId: number,
  appCode: string,
  storeId?: number
) {
  const response = await axiosInstance.post<StoreListingType | StoreDetailType>(
    STORE_LISTING_API_URL,
    undefined,
    {
      params: {
        portalId,
        appCode,
        ...(storeId ? { storeId } : {}),
      },
    }
  );

  return response.data;
}

/**
 * Function to get woocommerce store by portal id
 * @param params
 * @param onSuccess
 * @returns
 */

export const useStoreListing = (
  params: {
    portalId: number;
    appCode: string;
    storeId?: number;
  },
  enabled?: boolean,
  onSuccess?: (data: StoreDetailType) => void
): UseQueryResult<StoreDetailType> => {
  return useQuery(
    [STORE_LISTING_API_URL, params],
    () => fetchStoreData(params.portalId, params.appCode, params?.storeId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess,
      enabled: !!enabled,
    }
  );
};

/**
 * Function to get woocommerce store by portal id and store id
 * @param params
 * @param onSuccess
 * @returns
 */

export const useStoreListingById = (
  params: {
    portalId: number;
    appCode: string;
    storeId: number;
  },
  enabled?: boolean,
  onSuccess?: (data: StoreDetailType) => void
): UseQueryResult<StoreDetailType> => {
  return useQuery(
    [STORE_LISTING_API_URL, params],
    () => fetchStoreData(params.portalId, params.appCode, params?.storeId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: enabled,
      onSuccess,
    }
  );
};
