import React, { useRef, useState, FunctionComponent } from 'react';
import {
  Grid,
  Card,
  TextField,
  Select,
  MenuItem,
  TableBody,
  Table,
  TableRow,
  FormControl,
  InputLabel,
  TableCell,
  TableContainer,
  SelectChangeEvent,
  FormHelperText,
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material';
import Page from 'components/Page';
import useResponsive from 'hooks/useResponsive';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSettings from 'hooks/useSettings';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axiosInstance from 'utils/axios';
import CardInput from './CardInput';
import usePortalId from 'hooks/portal/usePortalId';
import { AllPlansData, AppPlanData } from '../PaymentType';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
// import SubscriptionCancel from './SubscriptionCancel';
import { AUTOMATEDCOUPON, PATH_USER } from 'routes/paths';
import { useTranslation } from 'react-i18next';
import { PlanCard } from './PlanCard';
import { AppDetails } from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import {
  BILLING_CONFIRM_PAYMENT_API_URL,
  BILLING_START_API_URL,
  UPDATE_SUBSCRIPTION_API_URL,
} from 'api/ApiConstants/GeneralConstants';
import AppLogo from 'components/AppLogo/AppLogo';

interface PaymentType {
  full_name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

type Props = {
  appPlans: AllPlansData;
  appDetails: AppDetails;
};

const initialErrorStateData = {
  full_name: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  card: '',
};

export const Payments: FunctionComponent<Props> = ({
  appPlans,
  appDetails,
}) => {
  const { appName } = useParams<{ appName: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const portalId = usePortalId();
  const stripe = useStripe();
  const elements = useElements();
  const smUp = useResponsive('up', 'sm');
  const { themeStretch } = useSettings();
  const isErrorRef = useRef(false);
  const [stateError, setStateError] = useState(initialErrorStateData);
  const [cardError, setCardError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState<AppPlanData>();
  const [payment, setPayment] = useState<PaymentType>({
    full_name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'select',
  });

  const currentPlanId =
    appDetails?.payment_plan?.plan_data?.type !== 'trial' &&
    appDetails?.payment_plan?.plan_data?._id
      ? appDetails?.payment_plan?.plan_data?._id
      : '';

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    setPayment({ ...payment, [e.target.name]: e.target.value });
  };

  const validateForm = (
    validateFormData: PaymentType,
    amountToBePaid: number
  ) => {
    const validate = {
      full_name: (full_name: string) =>
        full_name.trim().length > 0 ? true : t('name_is_required'),
      phone: (phone: number) =>
        phone
          .toString()
          .trim()
          .match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? true
          : t('phone_number_not_valid'),
      email: (email: string) =>
        email
          .trim()
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
          ? true
          : t('email_must_be_a_valid_email_address'),
      address: (address: string) =>
        address.trim().length > 0 ? true : t('address_is_required'),
      city: (city: string) =>
        city.trim().length > 0 ? true : t('city_is_required'),
      state: (state: string) =>
        state.trim().length > 0 ? true : t('state_is_required'),
      zip: (zip: string) =>
        zip.trim().length > 0 ? true : t('zip_is_required'),
      country: (country: string) =>
        country !== 'select' ? true : t('country_is_required'),
    };
    const errObj = {
      ...initialErrorStateData,
    };
    let errorFieldsVar = false;
    _.forIn(validateFormData, (value, key) => {
      const error = validate[key as keyof typeof validate](value as never);
      if (error !== true) {
        errorFieldsVar = true;
      }
      // @ts-ignore
      errObj[key] = error ?? '';
    });
    isErrorRef.current = errorFieldsVar;
    setStateError({ ...stateError, ...errObj });
  };

  const handleSubmitSub = async (event: any) => {
    setCardError('');
    if (selectedPlan?.price === undefined) {
      return false;
    }
    if (currentPlanId === '') {
      validateForm(payment, selectedPlan.price);
    }
    if (!stripe || !elements || isErrorRef.current) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    if (cardElement !== null) {
      setIsSubmitting(true);
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            city: payment.city,
            country: payment.country,
            line1: payment.address,
            postal_code: payment.zip,
            state: payment.state,
          },
          email: payment.email,
          name: payment.full_name,
          phone: payment.phone,
        },
      });
      if (result.error) {
        setIsSubmitting(false);
        isErrorRef.current = true;
        setCardError(result.error.message ?? '');
      } else {
        try {
          setIsSubmitting(true);
          let res;
          if (currentPlanId === selectedPlan._id || currentPlanId === '') {
            res = await axiosInstance.post(BILLING_START_API_URL, {
              payment_method: result.paymentMethod.id,
              email: payment.email,
              plan_id: selectedPlan._id,
              portal_id: Number(portalId),
              app_name: selectedPlan.app_name,
            });
          } else {
            res = await axiosInstance.post(UPDATE_SUBSCRIPTION_API_URL, {
              new_plan_id: selectedPlan._id,
              portal_id: Number(portalId),
              app_name: selectedPlan.app_name,
            });
          }

          // eslint-disable-next-line camelcase
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { client_secret, status } = res.data.data;
          if (status === 'requires_action') {
            setIsSubmitting(true);
            stripe
              .confirmCardPayment(client_secret)
              .then(async function (result) {
                if (result.error) {
                  setIsSubmitting(false);
                  enqueueSnackbar(t('issue_payment_failed'), {
                    variant: 'error',
                  });
                } else {
                  try {
                    await axiosInstance.post(BILLING_CONFIRM_PAYMENT_API_URL, {
                      payment_intent: result.paymentIntent.id,
                      portal_id: portalId,
                      app_name: appName,
                    });
                    enqueueSnackbar(
                      t('payment_successful_redirect_to_dashboard'),
                      { variant: 'success' }
                    );
                    navigate(
                      PATH_USER.general.accountSettings(portalId, 'billing')
                    );
                  } catch (error: any) {
                    enqueueSnackbar(t('issue_payment_failed'), {
                      variant: 'error',
                    });
                  }
                }
              });
          } else {
            setIsSubmitting(false);
            enqueueSnackbar(t('payment_successful_redirect_to_dashboard'), {
              variant: 'success',
            });
            navigate(PATH_USER.general.accountSettings(portalId, 'billing'));
          }
        } catch (error: any) {
          setIsSubmitting(false);
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
          navigate('/');
        }
      }
    } else {
      try {
        await axiosInstance.post(UPDATE_SUBSCRIPTION_API_URL, {
          new_plan_id: selectedPlan._id,
          portal_id: Number(portalId),
          app_name: selectedPlan.app_name,
        });
        setIsSubmitting(false);
        enqueueSnackbar(t('payment_successful_redirect_to_dashboard'), {
          variant: 'success',
        });
        navigate(PATH_USER.general.accountSettings(portalId, 'billing'));
      } catch (error: any) {
        enqueueSnackbar(t('issue_payment_failed'), {
          variant: 'error',
        });
      }
    }
  };

  const updateSelectedPlan = (appPlan: AppPlanData) => {
    setSelectedPlan(appPlan);
    if (currentPlanId === '') setExpanded(false);
  };

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Page title={t('payment')}>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{ paddingBottom: '25px' }}
      >
        <HeaderBreadcrumbs
          heading={t('checkout')}
          links={[
            {
              name: t('user_accounts'),
              href: PATH_USER.general.accountSettings(portalId, 'billing'),
            },
            { name: t('checkout') },
          ]}
        />
        <Box>
          <Typography sx={{ paddingBottom: '20px' }} variant='h4'>
            {t('selected_plan')}
          </Typography>
          <Accordion expanded={expanded} onChange={handleExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                border: '1px solid rgba(145, 158, 171, 0.32)',
                borderRadius: '8px',
                height: '110px',
              }}
            >
              <Box>
                {selectedPlan ? (
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'absolute',
                      left: !smUp ? '60%' : '80%',
                      paddingTop: '5px',
                    }}
                  >
                    <>
                      <Typography variant='h4'>
                        <div>
                          ${selectedPlan?.price}/
                          {selectedPlan.payment_type === 'monthly'
                            ? t('month')
                            : t('year')}
                        </div>
                      </Typography>
                    </>
                  </Stack>
                ) : null}
                <AppLogo appName={appDetails.app_name} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: smUp ? 'row' : 'column',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {appPlans.plans.length > 0 &&
                      _.orderBy(appPlans.plans, ['order'], ['asc']).map(
                        plan => {
                          return (
                            <PlanCard
                              key={plan._id}
                              appPlan={plan}
                              appDetails={appDetails}
                              updateSelectedPlan={updateSelectedPlan}
                              currentPlanId={currentPlanId}
                              selectedPlanId={selectedPlan?._id}
                            />
                          );
                        }
                      )}
                  </Grid>
                </Box>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        {!expanded && currentPlanId === '' ? (
          <>
            <Box sx={{ paddingTop: 5 }}>
              <Grid container spacing={8}>
                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    columnSpacing={10}
                    direction='column'
                    spacing={4}
                  >
                    <Grid item xs={8}>
                      <Card sx={{ p: 3 }}>
                        <Typography
                          sx={{ margin: '15px 0px 15px 0px' }}
                          variant='subtitle2'
                          color='text secondary'
                        >
                          {t('billing_details')}
                        </Typography>
                        <Box
                          sx={{
                            display: 'grid',
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(2, 1fr)',
                            },
                          }}
                        >
                          <TextField
                            id='outlined-basic'
                            name='full_name'
                            label={t('full_name')}
                            variant='outlined'
                            value={payment.full_name}
                            onChange={handleChange}
                            error={stateError.full_name.length ? true : false}
                            helperText={stateError.full_name}
                          />
                          <TextField
                            id='outlined-basic'
                            type='number'
                            name='phone'
                            label={t('phone')}
                            variant='outlined'
                            value={payment.phone}
                            onChange={handleChange}
                            error={stateError.phone.length ? true : false}
                            helperText={stateError.phone}
                          />
                          <TextField
                            id='outlined-basic'
                            name='email'
                            label={t('email_address')}
                            variant='outlined'
                            value={payment.email}
                            onChange={handleChange}
                            error={stateError.email.length ? true : false}
                            helperText={stateError.email}
                          />
                          <TextField
                            id='outlined-basic'
                            name='address'
                            label={t('address')}
                            variant='outlined'
                            value={payment.address}
                            onChange={handleChange}
                            error={stateError.address.length ? true : false}
                            helperText={stateError.address}
                          />
                          <TextField
                            id='outlined-basic'
                            name='city'
                            label={t('city')}
                            variant='outlined'
                            value={payment.city}
                            onChange={handleChange}
                            error={stateError.city.length ? true : false}
                            helperText={stateError.city}
                          />
                          <TextField
                            id='outlined-basic'
                            name='state'
                            label={t('state')}
                            variant='outlined'
                            value={payment.state}
                            onChange={handleChange}
                            error={stateError.state.length ? true : false}
                            helperText={stateError.state}
                          />
                          <TextField
                            id='outlined-basic'
                            name='zip'
                            label={t('zip')}
                            variant='outlined'
                            value={payment.zip}
                            onChange={handleChange}
                            error={stateError.zip.length ? true : false}
                            helperText={stateError.zip}
                          />
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              {t('country')}
                            </InputLabel>
                            <Select
                              sx={{ width: '50%' }}
                              id='demo-simple-select-helper'
                              label={t('country')}
                              name='country'
                              value={payment.country}
                              onChange={handleChange}
                              error={stateError.country.length ? true : false}
                            >
                              <MenuItem key='select' value={'select'}>
                                {t('select_country')}
                              </MenuItem>
                              {_.map(appPlans.countries, function (value, key) {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {stateError.country.length > 0 && (
                              <FormHelperText error={true}>
                                {stateError.country}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card sx={{ p: 3 }}>
                        <Typography
                          sx={{ margin: '15px 0px 15px 0px' }}
                          variant='subtitle2'
                          color='text secondary'
                        >
                          {t('payment_info')}
                        </Typography>

                        <CardInput />
                        {cardError.length > 0 && (
                          <FormHelperText error={true}>
                            {cardError}
                          </FormHelperText>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    columnSpacing={10}
                    direction='column'
                    spacing={4}
                  >
                    <Grid item xs={4}>
                      <Card sx={{ p: 3 }}>
                        <Typography
                          sx={{ margin: '15px 0px 15px 0px' }}
                          variant='subtitle2'
                          color='text secondary'
                        >
                          {t('order_summary')}
                        </Typography>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant='subtitle2'
                                    color='#637381'
                                  >
                                    {t('sub_total')}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    color='#212B36'
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                    }}
                                    variant='subtitle2'
                                  >
                                    ${selectedPlan?.price}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='h5'>
                                    {t('total')}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    color='#212B36'
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                    }}
                                    variant='subtitle2'
                                  >
                                    ${selectedPlan?.price}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card sx={{ mb: 3 }}>
                        <div className='save-button'>
                          <LoadingButton
                            type='button'
                            fullWidth
                            variant='contained'
                            loading={isSubmitting}
                            onClick={handleSubmitSub}
                          >
                            {t('complete_payment')}
                          </LoadingButton>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : selectedPlan?._id && currentPlanId ? (
          <Stack sx={{ paddingTop: '25px', margin: '0 auto', width: '50%' }}>
            <LoadingButton
              type='button'
              fullWidth
              variant='contained'
              loading={isSubmitting}
              onClick={handleSubmitSub}
            >
              {t('subscribe')}
            </LoadingButton>
          </Stack>
        ) : null}
      </Container>
    </Page>
  );
};
