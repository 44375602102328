import { useAppDetail } from 'api/ApiHooks/DiscountCouponsApiHooks';
import usePortalId from 'hooks/portal/usePortalId';
import { useNavigate } from 'react-router-dom';
import { PATH_USER } from 'routes/paths';

const useIfValidityExpired = (appName: string) => {
  const navigate = useNavigate();
  const portalId = usePortalId();
  const { data } = useAppDetail(Number(portalId), appName);

  if (data !== undefined) {
    const appDetail = data.data;

    if (appDetail?.payment_plan?.type === 'noSubscription') {
      navigate(PATH_USER.payments.payments(portalId, appName));
    } else if (
      new Date(Number(appDetail.payment_plan.validity)) <= new Date()
    ) {
      navigate(PATH_USER.payments.payments(portalId, appName));
    }
  }
};

export default useIfValidityExpired;
