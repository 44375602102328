import React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  PATH_USER,
} from 'routes/paths';
import { useCouponDetails } from 'api/ApiHooks/DiscountCouponsApiHooks';

import CouponForm from './form/CouponForm';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';

export default function EditCoupon() {
  useIfValidityExpired(AUTOMATEDCOUPON);
  const { couponId } = useParams<{
    couponId: string;
  }>();

  const { t } = useTranslation();
  const portalId = usePortalId();
  const { data, isFetching } = useCouponDetails(Number(portalId), couponId!);
  if (data === undefined || isFetching) {
    return <PartialPageSpinner />;
  }
  const couponDetails = data?.data;

  return (
    <Page title={t('edit_coupon_rule')} className='px-6'>
      <HeaderBreadcrumbs
        heading={t('coupon_rule')}
        links={[
          {
            name: t('all_apps'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('coupons'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
              portalId,
              'coupons'
            ),
          },
          {
            name: t('edit_coupon_rule'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.createCoupon(portalId),
          },
        ]}
      />
      <Typography variant='subtitle1'>{t('edit_coupon_rule')}</Typography>

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-3/6 py-5'>
          <CouponForm type='edit' couponDetails={couponDetails} />
        </div>
        <div className='sm:basis-3/6'>
          <Typography variant='h5'>{t('About Discount Coupon')}</Typography>
          <div className='text-gray-500'>{t('coupon_content_line_one')}</div>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_two')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_three')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_four')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_five')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_four')}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <div className='text-gray-500'>{t('coupon_content_line_seven')}</div>
        </div>
      </div>
    </Page>
  );
}
