import {
  Box,
  Card,
  InputAdornment,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from '@mui/material';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_LISTING_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import Page from 'components/Page';
import axiosInstance from 'utils/axios';
import { TableHeadCustom, TableNoData } from 'components/table';
import Iconify from 'components/Iconify';
import useActiveApps from 'hooks/apps/useActiveApps';
import { POINTS_AND_REWARDS_NAVIGATION, PATH_PAGE } from 'routes/paths';
import useResponsive from 'hooks/useResponsive';
import {
  CustomerListingType,
  CustomerListingObjectType,
} from '../../PointsRewardsType';
import CustomerTableRow from '../../Table/CustomerTableRow';

const ROWS_PER_PAGE = 10;

type Props = {
  storeId: string;
};

const CustomerListingTable: FunctionComponent<Props> = ({ storeId }) => {
  const portalId = usePortalId();
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const TABLE_HEAD = [
    { id: 'username', label: t('customer') },
    { id: 'activity', label: t('activity') },
    { id: 'points', label: t('points') },
    { id: 'rewards', label: t('rewards') },
    { id: 'status', label: t('status') },
    { id: 'action', label: t('action') },
  ];
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState<string>('');
  const activeApps = useActiveApps(portalId);

  async function fetchData(skip = 0, search = '') {
    const response = await axiosInstance.get<CustomerListingType>(
      CUSTOMER_LISTING_API_URL,
      {
        params: {
          storeId,
          portalId,
          limit: ROWS_PER_PAGE,
          ...(search ? { search } : {}),
          ...(skip ? { skip } : {}),
        },
      }
    );

    return response.data;
  }

  const { data: customerResponse, isFetching } = useQuery<CustomerListingType>(
    [CUSTOMER_LISTING_API_URL, skip, search],
    () => fetchData(skip as number, search as string),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: lastPage => {
        return lastPage.hasMore === true
          ? lastPage.skip + ROWS_PER_PAGE
          : undefined;
      },
    }
  );

  const navigate = useNavigate();

  if (!activeApps.includes('pointsandreward')) {
    navigate(PATH_PAGE.page404);
  }

  const totalPage = customerResponse
    ? Math.ceil((customerResponse?.totalCount ?? 0) / ROWS_PER_PAGE)
    : 0;

  let contentLoad: React.ReactNode = {};

  const handleEditRow = (id: string) => {
    navigate(POINTS_AND_REWARDS_NAVIGATION.general.editCustomer(portalId, id));
  };

  const mapPages = (data: CustomerListingObjectType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <CustomerTableRow
          key={row._id}
          row={row}
          onEditRow={() => handleEditRow(row._id)}
        />
      );
    });

    return rows;
  };

  if (!isFetching) {
    contentLoad = customerResponse?.data?.length ? (
      <TableBody>{mapPages(customerResponse.data)}</TableBody>
    ) : (
      <TableBody>
        <TableNoData isNotFound={true} Message={t('customer')} />
      </TableBody>
    );
  }

  const onSearchChange = (value: string) => {
    setSearch(value);
    setSkip(0);
  };

  return (
    <Page title={t('customer_listing')}>
      <Box sx={{ mt: 3 }} />
      <Card>
        <Box sx={{ padding: '20px' }}>
          <TextField
            value={search}
            onChange={event => onSearchChange(event.target.value)}
            placeholder={t('search_customer')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {isFetching ? (
          <PartialPageSpinner />
        ) : (
          <Stack>
            <TableContainer>
              <Table
                sx={{ minWidth: smUp ? 800 : '' }}
                aria-label='simple table'
              >
                <TableHeadCustom headLabel={TABLE_HEAD} />
                {contentLoad}
              </Table>
            </TableContainer>
            {customerResponse && totalPage > 1 && (
              <Card
                sx={{
                  display: 'flex',
                  paddingBottom: '25px',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                <Pagination
                  color='primary'
                  variant='outlined'
                  count={totalPage}
                  page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                  onChange={(e, pageNo) =>
                    setSkip((pageNo - 1) * ROWS_PER_PAGE)
                  }
                />
              </Card>
            )}
          </Stack>
        )}
      </Card>
    </Page>
  );
};
export default CustomerListingTable;
