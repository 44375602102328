import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axiosInstance from 'utils/axios';
import { RewardListingObjType } from '../../PointsRewardsType';
import i18next from 'i18next';
import usePortalId from 'hooks/portal/usePortalId';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFSwitch,
} from 'components/hook-form';
import {
  DISCOUNT_COUPONS_NAVIGATION,
  POINTS_AND_REWARDS_NAVIGATION,
} from 'routes/paths';
import Iconify from 'components/Iconify';
import {
  CREATE_REWARD_API_URL,
  UPDATE_REWARD_API_URL,
  DELETE_REWARD_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { isApiError } from 'utils/apiFunctions';
import DeleteRewardConfirmDialog from '../../Rewards/child/DeleteRewardConfirmDialog';
import useResponsive from 'hooks/useResponsive';

const AddRewardSchema = Yup.object().shape({
  rewardTitle: Yup.string().required(i18next.t('reward_title_required')),
  rewardPointCost: Yup.number()
    .typeError(i18next.t('specify_number'))
    .integer()
    .required(i18next.t('points_are_required'))
    .min(1, i18next.t('min_value') + ' 1'),
  discountAmount: Yup.number()
    .when('discountType', {
      is: 'percent',
      then: Yup.number()
        .typeError(i18next.t('specify_number'))
        .max(100, i18next.t('max_value') + ' 100')
        .min(1, i18next.t('min_value') + ' 0.01'),
    })
    .when('discountType', {
      is: 'fixed_cart',
      then: Yup.number()
        .typeError(i18next.t('specify_number'))
        .integer()
        .required(i18next.t('discount_amount_is_required'))
        .min(1, i18next.t('min_value') + ' 1'),
    }),
  minimumSpend: Yup.number()
    .typeError(i18next.t('specify_number'))
    .integer()
    .required(i18next.t('minimum_spend_value_is_required'))
    .min(0, i18next.t('min_value') + ' 0'),
  couponUsageLimit: Yup.number()
    .typeError(i18next.t('specify_number'))
    .integer()
    .required(i18next.t('coupon_usage_limit_is_required'))
    .min(1, i18next.t('min_value') + ' 1'),
  count: Yup.number().when('enableExpiration', {
    is: true,
    then: Yup.number()
      .typeError(i18next.t('specify_number'))
      .integer()
      .required(i18next.t('count_value_required'))
      .min(1, i18next.t('min_value') + ' 1'),
  }),
});

type FormValuesProps = {
  rewardTitle: string;
  rewardPointCost: number;
  discountType: string;
  discountAmount: number;
  minimumSpend: number;
  couponUsageLimit: number;
  isEnabled: boolean;
  enableExpiration: boolean;
  count: number;
  interval: string;
  afterSubmit?: string;
};

type Props = {
  type: 'add' | 'edit';
  rewardDetails?: RewardListingObjType;
  storeId: string;
};

const RewardForm: FunctionComponent<Props> = ({
  type,
  rewardDetails,
  storeId,
}) => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const smUp = useResponsive('up', 'sm');
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);

  const defaultValues = {
    rewardTitle: rewardDetails?.title,
    rewardPointCost: rewardDetails?.points_cost,
    discountType: rewardDetails?.discount_type
      ? rewardDetails?.discount_type
      : 'fixed_cart' || 'percent',
    discountAmount: rewardDetails?.discount_amount,
    minimumSpend: rewardDetails?.minimum_order_amount,
    couponUsageLimit: rewardDetails?.usage_limit,
    isEnabled: rewardDetails?._id ? rewardDetails?.is_enabled : true,
    enableExpiration: rewardDetails?._id
      ? rewardDetails?.enable_expiration
      : false,
    count: rewardDetails?.expiry_interval.count || 1,
    interval: rewardDetails?.expiry_interval.interval || 'week',
  };

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(AddRewardSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isSubmitting },
  } = methods;

  const createReward = async (data: FormValuesProps) => {
    try {
      const result = await axiosInstance.post(CREATE_REWARD_API_URL, {
        portalId: Number(portalId),
        storeId: Number(storeId),
        title: data.rewardTitle,
        points_cost: data.rewardPointCost,
        discount_type: data.discountType,
        discount_amount: data.discountAmount,
        is_enabled: data.isEnabled,
        minimum_order_amount: data.minimumSpend,
        usage_limit: data.couponUsageLimit,
        expiry_interval: {
          count: data.count,
          interval: data.interval,
        },
        enable_expiration: data.enableExpiration,
      });
      if (result.data.success) {
        navigate(
          POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
            portalId,
            'rewards'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const updateReward = async (data: FormValuesProps) => {
    try {
      const result = await axiosInstance.post(
        UPDATE_REWARD_API_URL + rewardDetails?._id,
        {
          portalId: Number(portalId),
          storeId: Number(storeId),
          title: data.rewardTitle,
          points_cost: data.rewardPointCost,
          discount_type: data.discountType,
          discount_amount: data.discountAmount,
          is_enabled: data.isEnabled,
          minimum_order_amount: data.minimumSpend,
          usage_limit: data.couponUsageLimit,
          expiry_interval: {
            count: data.count,
            interval: data.interval,
          },
          enable_expiration: data.enableExpiration,
        }
      );
      if (result.data.success) {
        navigate(
          POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
            portalId,
            'rewards'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const deleteReward = async () => {
    try {
      const res = await axiosInstance.post(
        DELETE_REWARD_API_URL + rewardDetails?._id,
        {
          portalId: Number(portalId),
          storeId: Number(storeId),
        }
      );
      if (res.data.success) {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        navigate(
          POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
            portalId,
            'rewards'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const onDeleteReward = () => {
    confirmDialogueOpenClose();
  };

  const confirmDialogueOpenClose = () => {
    setConfirmDialogueOpen(!confirmDialogueOpen);
  };

  const onSubmit = (data: FormValuesProps) => {
    if (type === 'add') {
      createReward(data);
    } else {
      updateReward(data);
    }
  };

  return (
    <div>
      {storeId ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && (
              <Alert severity='error' sx={{ alignItems: 'center' }}>
                {errors.afterSubmit.message}
              </Alert>
            )}
            <Card sx={{ padding: '25px' }}>
              <Typography sx={{ mb: 2 }} variant='h6' component='div'>
                {t('Details')}
              </Typography>
              <RHFTextField
                name='rewardTitle'
                type='text'
                placeholder={t('title')}
                label={t('title')}
                fullWidth
              />
              <RHFTextField
                name='rewardPointCost'
                type='text'
                placeholder={t('point_cost')}
                label={t('point_cost')}
                sx={{ mt: 3 }}
                fullWidth
              />
            </Card>
            <Card sx={{ padding: '25px' }}>
              {!smUp && (
                <Typography sx={{ mt: 1, mr: 3 }} component='div'>
                  {t('discount_type')}
                </Typography>
              )}
              <RadioGroup
                className='mt-3 mb-5 flex flex-row gap-2'
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={watch('discountType')}
              >
                {smUp && (
                  <Typography sx={{ mt: 1, mr: 3 }} component='div'>
                    {t('discount_type')}
                  </Typography>
                )}
                <FormControlLabel
                  value='fixed_cart'
                  control={<Radio />}
                  label={t('flat')}
                  {...register('discountType')}
                />
                <FormControlLabel
                  value='percent'
                  control={<Radio />}
                  label={t('percentage')}
                  {...register('discountType')}
                />
              </RadioGroup>
              <RHFTextField
                name='discountAmount'
                type='text'
                placeholder={t('discount_amount')}
                label={t('discount_amount')}
                sx={{ mr: 2, width: smUp ? '48%' : '46%' }}
              />
              <RHFTextField
                name='minimumSpend'
                type='text'
                placeholder={t('minimum_spend')}
                label={t('minimum_spend')}
                sx={{ width: smUp ? '48%' : '46%' }}
              />
              <RHFTextField
                name='couponUsageLimit'
                type='text'
                placeholder={t('coupon_usage_limit')}
                label={t('coupon_usage_limit')}
                sx={{ mt: 3 }}
                fullWidth
              />
            </Card>
            <Card sx={{ padding: '25px' }}>
              <Typography
                variant='h6'
                component='div'
                sx={{ float: 'left', width: '75%' }}
              >
                {t('enable_coupon_expiration')}
              </Typography>
              <RHFSwitch
                name='enableExpiration'
                label={''}
                sx={{ float: 'right' }}
              />
              {watch('enableExpiration') === true && (
                <>
                  <RHFTextField
                    label={t('expire_after')}
                    name='count'
                    type='text'
                    placeholder={t('days')}
                    sx={{ mt: smUp ? 4 : 3, width: '48%', float: 'left' }}
                  />
                  <RHFSelect
                    placeholder={t('interval')}
                    name='interval'
                    label={t('interval')}
                    sx={{ mt: 3, width: '48%', float: 'right' }}
                  >
                    <option value='-1' className='text-gray-300' disabled>
                      {t('none')}
                    </option>
                    <option value='week'>{t('week')}</option>
                    <option value='month'>{t('month')}</option>
                    <option value='year'>{t('year')}</option>
                    <option value='day'>{t('day')}</option>
                  </RHFSelect>
                </>
              )}
            </Card>
            {rewardDetails?._id ? (
              <div>
                <LoadingButton
                  size='large'
                  type='submit'
                  variant='contained'
                  aria-label={t('update_reward')}
                  loading={isSubmitting}
                  className='mt-7 mr-3 py-8 px-10'
                  sx={{
                    boxShadow: '0 8px 16px 0 #FFFFFF',
                  }}
                >
                  {t('update_reward')}
                </LoadingButton>
                <LoadingButton
                  size='large'
                  type='button'
                  variant='contained'
                  aria-label={t('delete_reward')}
                  onClick={() => onDeleteReward()}
                  className='mt-7 py-8 px-10'
                  sx={{
                    backgroundColor: '#DFE3E8',
                    color: '#000000',
                    boxShadow: '0 8px 16px 0 #FFFFFF',
                    '&:hover': {
                      backgroundColor: '#B7BDC4',
                    },
                  }}
                >
                  {t('delete_reward')}
                </LoadingButton>
              </div>
            ) : (
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                aria-label={t('login')}
                loading={isSubmitting}
                className='mt-7 py-8 px-10'
                sx={{
                  boxShadow: '0 8px 16px 0 #FFFFFF',
                }}
              >
                {t('create')}
              </LoadingButton>
            )}
          </Stack>
        </FormProvider>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: '50px',
          }}
        >
          <Typography variant='h5'>{t('you_have_no_store_added')}</Typography>
          <LoadingButton
            sx={{ marginTop: '35px' }}
            variant='contained'
            onClick={() =>
              navigate(
                DISCOUNT_COUPONS_NAVIGATION.general.createStore(portalId)
              )
            }
            startIcon={<Iconify icon='eva:plus-fill' />}
            loading={false}
          >
            {t('create_new_store')}
          </LoadingButton>
        </Box>
      )}
      <Stack>
        <DeleteRewardConfirmDialog
          open={confirmDialogueOpen}
          handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
          deleteReward={() => {
            confirmDialogueOpenClose();
            deleteReward();
          }}
        />
      </Stack>
    </div>
  );
};

export default RewardForm;
