import { Box, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { PATH_USER } from 'routes/paths';
import Iconify from '../../../../../components/Iconify';
import { useTranslation } from 'react-i18next';
import { PortalList } from '../PortalListingType';
import { useState } from 'react';
import DeletePortalConfirmDialogue from './DeletePortalConfirmDialogue';
interface OwnProps {
  currentPortalId?: string;
  portals: PortalList[];
  portalMaxHeight?: string;
  disconnectPortal: (id: number) => void;
  handleOpenClose?: () => void;
}
type Props = OwnProps;
const PortalLists: React.FC<Props> = ({
  currentPortalId = '',
  portals,
  handleOpenClose,
  disconnectPortal,
  portalMaxHeight = '400px',
}) => {
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [selectedPortalId, setSelectedPortalId] = useState(0);
  const { t } = useTranslation();
  const redirectToAppListing = (portalId: number) => {
    window.location.href = PATH_USER.appListing(portalId.toString());
    if (handleOpenClose) handleOpenClose();
  };

  const handledisconnectPortal = () => {
    disconnectPortal(selectedPortalId);
    confirmDialogueOpenClose();
  };

  const confirmDialogueOpenClose = (portalId?: number) => {
    setConfirmDialogueOpen(!confirmDialogueOpen);
    if (portalId) {
      setSelectedPortalId(portalId);
    }
  };

  return (
    <>
      <Stack
        direction='row'
        sx={{
          mb: 5,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <List
            sx={{
              pt: 0,
              maxHeight: portalMaxHeight,
              overflow: 'auto',
            }}
          >
            {portals.map(portal =>
              portal.is_connected ? (
                <>
                  <ListItem
                    sx={{
                      padding: '16px 20px',
                      marginBottom: '15px',
                      display: 'flex',
                    }}
                    button
                    key={portal._id}
                  >
                    <ListItemText
                      sx={{ paddingLeft: '1px', paddingRight: '1px' }}
                      onClick={() => redirectToAppListing(portal.portal_id)}
                      primary={portal.hub_domain}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component='span'
                            variant='body2'
                            color='text.secondary'
                          >
                            {t('id')} {portal.portal_id}
                          </Typography>
                          {!portal.is_default ? (
                            <span style={{ float: 'right' }}>
                              <Iconify
                                sx={{ marginLeft: '15px' }}
                                icon='eva:trash-2-outline'
                                onClick={event => {
                                  event.stopPropagation();
                                  confirmDialogueOpenClose(portal.portal_id);
                                }}
                              />
                            </span>
                          ) : null}
                        </>
                      }
                    />
                  </ListItem>
                </>
              ) : null
            )}
          </List>
        </Box>
      </Stack>
      <DeletePortalConfirmDialogue
        open={confirmDialogueOpen}
        handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
        disconnectPortal={handledisconnectPortal}
      />
    </>
  );
};
export default PortalLists;
