import { FunctionComponent, useEffect, useState } from 'react';
import {
  Card,
  Pagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableHeadCustom, TableNoData } from 'components/table';
import { CustomerClaimRewards } from '../../PointsRewardsType';
import CustomerRewardTableRow from '../../Table/CustomerRewardTableRow';
import { capitalCase } from 'change-case';
import useTabs from 'hooks/useTabs';
import useTable, { getComparator } from 'hooks/useTable';
import useResponsive from 'hooks/useResponsive';

type Props = {
  claimedRewards: CustomerClaimRewards[];
};

const CustomerRewardsListing: FunctionComponent<Props> = ({
  claimedRewards,
}) => {
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const TABLE_HEAD = [
    { id: 'created_at', label: t('date'), align: 'left' },
    { id: 'reward_name', label: t('reward'), align: 'center' },
    { id: 'usage_status', label: t('status'), align: 'center' },
    { id: 'code', label: capitalCase(t('code')), align: 'center' },
    { id: 'redeemed_points', label: t('points_redeem'), align: 'center' },
    { id: 'used_at', label: t('used_at'), align: 'center' },
  ];
  const { order, orderBy, onSort } = useTable({
    defaultOrderBy: 'date',
  });
  const ROWS_PER_PAGE = 10;
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [skip, setSkip] = useState(0);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('all');

  const mapPages = (data: CustomerClaimRewards[]) => {
    const rows: React.ReactNode[] = [];
    const totalRows = skip + ROWS_PER_PAGE;

    for (let i = skip; i < totalRows; i++) {
      if (data[i] === undefined) break;

      rows.push(<CustomerRewardTableRow key={data[i]._id} row={data[i]} />);
    }

    return rows;
  };

  const tableData = claimedRewards;

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterStatus,
  });

  const totalPage = Math.ceil(dataFiltered.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (claimedRewards.length) {
      const STATUS_OPTIONS = ['all'];
      claimedRewards.forEach(element => {
        if (!STATUS_OPTIONS.includes(element.usage_status)) {
          STATUS_OPTIONS.push(element.usage_status);
        }
      });
      setStatusOptions(STATUS_OPTIONS);
    }
  }, []);

  let contentLoad: React.ReactNode = {};
  contentLoad = dataFiltered.length ? (
    <TableBody>{mapPages(dataFiltered)}</TableBody>
  ) : (
    <TableBody>
      <TableNoData isNotFound={true} Message={t('rewards')} />
    </TableBody>
  );

  return (
    <Stack>
      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        value={filterStatus}
        onChange={onChangeFilterStatus}
        sx={{
          bgcolor: 'background.neutral',
          borderRadius: '10px',
          ml: 1,
          px: 2,
        }}
      >
        {statusOptions.map(tab => (
          <Tab disableRipple key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: smUp ? 800 : '' }} aria-label='simple table'>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onSort={onSort}
            cellBackground='#FFFFFF'
          />
          {contentLoad}
        </Table>
      </TableContainer>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '25px',
          paddingTop: '25px',
        }}
      >
        {claimedRewards && totalPage > 1 ? (
          <Pagination
            color='primary'
            variant='outlined'
            count={totalPage}
            page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
            onChange={(e, pageNo) => setSkip((pageNo - 1) * ROWS_PER_PAGE)}
          />
        ) : null}
      </Card>
    </Stack>
  );
};

export default CustomerRewardsListing;

function applySortFilter({
  tableData,
  comparator,
  filterStatus,
}: {
  tableData: CustomerClaimRewards[];
  comparator: (a: any, b: any) => number;
  filterStatus: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });

  tableData = stabilizedThis.map(el => el[0]);

  if (filterStatus !== 'all') {
    tableData = tableData.filter(
      (item: Record<string, any>) => item.usage_status === filterStatus
    );
  }

  return tableData;
}
