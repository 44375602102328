import { TableRow, TableCell, Typography, Switch } from '@mui/material';
import { HubSpotUserDetails } from '../../DiscountCouponsType';
import { useState } from 'react';
import axiosInstance from 'utils/axios';
import { CREATE_HUBSPOT_USER } from 'api/ApiConstants/DiscountCouponsApiConstants';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import usePortalId from 'hooks/portal/usePortalId';

type Props = {
  row: HubSpotUserDetails;
  handleCouponAccess: (id: number) => void;
  accessedUsers: number[];
};

export default function HubSpotUserTable({
  row,
  handleCouponAccess,
  accessedUsers,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { userId, email, name, is_coupon_enabled } = row;
  const portalId = usePortalId();
  const [couponEnabled, setCouponEnabled] = useState(is_coupon_enabled);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const changeUserAccess = (id: number) => {
    setCouponEnabled(!couponEnabled);
    let arr: number[] = [];
    if (accessedUsers.includes(id)) {
      arr = accessedUsers.filter(item => item !== id);
    } else {
      arr = [...accessedUsers, id];
    }
    handleCouponAccess(id);
    updateUserForCouponAccess(arr);
  };

  const updateUserForCouponAccess = async (arr: number[]) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(CREATE_HUBSPOT_USER, {
        portal_id: portalId,
        activeUser: arr,
      });
      if (response?.data.success) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(t('something_went_wrong'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('something_went_wrong'), { variant: 'error' });
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>{userId}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{email ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{name ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        {isLoading ? (
          <CircularProgress size={25} sx={{ ml: 2 }} />
        ) : (
          <Switch
            checked={couponEnabled}
            onChange={() => changeUserAccess(userId)}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
