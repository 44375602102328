import {
  Box,
  Card,
  Container,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HUBSPOT_COUPON_API_URL } from 'api/ApiConstants/DiscountCouponsApiConstants';
import {
  CouponListingObjType,
  CouponListingType,
} from '../../DiscountCouponsType';
import Page from 'components/Page';
import axiosInstance from 'utils/axios';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import CouponTableRow from '../table/CouponTableRow';
import Iconify from 'components/Iconify';
import useActiveApps from 'hooks/apps/useActiveApps';
import { AUTOMATEDCOUPON, PATH_PAGE } from 'routes/paths';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import { useAppDetail } from 'api/ApiHooks/DiscountCouponsApiHooks';
import useResponsive from 'hooks/useResponsive';

const ROWS_PER_PAGE = 10;

export default function CouponListing() {
  const portalId = usePortalId();
  useIfValidityExpired(AUTOMATEDCOUPON);
  const appDetailsData = useAppDetail(Number(portalId), AUTOMATEDCOUPON);
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'couponCode', label: t('coupon_name'), align: 'left' },
    { id: 'rulename', label: t('rule_name'), align: 'left' },
    { id: 'expiresAt', label: t('expires_at') },
    { id: 'created_at', label: t('created') },
  ];

  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const activeApps = useActiveApps(portalId);

  async function fetchData(search: string, skip = 0) {
    const response = await axiosInstance.get<CouponListingType>(
      HUBSPOT_COUPON_API_URL,
      {
        params: {
          search,
          portalId,
          skip,
          limit: ROWS_PER_PAGE,
        },
      }
    );

    return response.data;
  }

  const { data: couponResponse, isFetching } = useQuery<CouponListingType>(
    [HUBSPOT_COUPON_API_URL, skip, search],
    () => fetchData(search, skip as number),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: lastPage => {
        return lastPage.hasMore === true
          ? lastPage.skip + ROWS_PER_PAGE
          : undefined;
      },
    }
  );

  const navigate = useNavigate();

  if (!activeApps.includes('automatedcoupon')) {
    navigate(PATH_PAGE.page404);
  }
  if (!couponResponse || appDetailsData === undefined) {
    return <PartialPageSpinner />;
  }

  const totalPage = Math.ceil(couponResponse?.totalCount / ROWS_PER_PAGE);

  let contentLoad: React.ReactNode = {};

  if (isFetching || loading) {
    contentLoad = (
      <TableBody sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </TableBody>
    );
  }

  const mapPages = (data: CouponListingObjType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(<CouponTableRow key={row._id} row={row} />);
    });

    return rows;
  };

  if (!isFetching) {
    contentLoad = couponResponse.data.length ? (
      <TableBody>{mapPages(couponResponse.data)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('coupon')} />
      </>
    );
  }

  const onSearchChange = (value: string) => {
    setSkip(0);
    setSearch(value);
  };

  return (
    <Page title={t('coupon_listing')}>
      <Container>
        <Box sx={{ mb: 5 }}>
          <Box sx={{ mt: 2 }}>
            <TextField
              value={search}
              onChange={event => onSearchChange(event.target.value)}
              placeholder={t('search_coupon')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {smUp &&
            !appDetailsData.data?.data.payment_plan.custom_plan &&
            appDetailsData.data?.data.payment_plan.max_coupon_count &&
            appDetailsData.data?.data.payment_plan.current_month_coupons ? (
              <Stack sx={{ float: 'right' }}>
                <Typography variant='button'>
                  {t('total_coupons')}:{' '}
                  {appDetailsData.data?.data.payment_plan.max_coupon_count}
                </Typography>
                <Typography variant='button'>
                  {t('coupons_used')} :{' '}
                  {appDetailsData.data?.data.payment_plan.current_month_coupons}
                </Typography>
              </Stack>
            ) : null}
          </Box>
        </Box>
        <Card sx={{ height: '750px' }}>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                <TableHeadCustom headLabel={TABLE_HEAD} />
                {contentLoad}
              </Table>
              <Card
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingBottom: '25px',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                {couponResponse && totalPage > 1 ? (
                  <Pagination
                    color='primary'
                    variant='outlined'
                    count={totalPage}
                    page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                    onChange={(e, pageNo) =>
                      setSkip((pageNo - 1) * ROWS_PER_PAGE)
                    }
                  />
                ) : null}
              </Card>
            </TableContainer>
          </Scrollbar>
          {!smUp &&
          !appDetailsData.data?.data.payment_plan.custom_plan &&
          appDetailsData.data?.data.payment_plan.max_coupon_count &&
          appDetailsData.data?.data.payment_plan.current_month_coupons ? (
            <Stack sx={{ padding: '20px', float: 'right' }}>
              <Typography variant='button'>
                {t('total_coupons')}:{' '}
                {appDetailsData.data?.data.payment_plan.max_coupon_count}
              </Typography>
              <Typography variant='button'>
                {t('coupons_used')} :{' '}
                {appDetailsData.data?.data.payment_plan.current_month_coupons}
              </Typography>
            </Stack>
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
