import { HOST_API } from 'config';
export const LOGIN_API = HOST_API + '/user/login';
export const REGISTER_API = HOST_API + '/user/create';
export const VERIFY_EMAIL_TOKEN = HOST_API + '/user/verifyuser/';
export const USER_DETAILS_API_URL = HOST_API + '/user/getDetails';
export const FORGOT_PASSWORD_API_URL = HOST_API + '/user/forgot';
export const FORGOT_RESET_PASSWORD_API_URL = HOST_API + '/user/forgotreset';
export const UPDATE_PROFILE_API_URL = HOST_API + '/user/updateProfile';
export const RESET_PASSWORD_API_URL = HOST_API + '/user/resetpassword';
export const RESEND_VERIFICATION_MAIL_URL =
  HOST_API + '/user/resendConfirmation';
export const CONNECT_TO_HUBSPOT_API_URL =
  HOST_API + '/connector/get/installationForm';
export const PORTAL_LISTING_API_URL =
  HOST_API + '/formpayhome/portal/getAllPortals';
export const GATEWAY_LISTING_API_URL =
  HOST_API + '/formpayhome/gateway/getUserGateways';
export const GATEWAY_DETAIL_API_URL =
  HOST_API + '/formpayhome/gateway/getUserGatewayDetails';
export const SAVE_USER_GATEWAY_API_URL =
  HOST_API + '/formpayhome/gateway/saveUserGateway';
export const DELETE_PORTAL_API_URL =
  HOST_API + '/formpayhome/portal/disconnectPortal';
export const FORMS_LISTING_API_URL =
  HOST_API + '/formpayhome/portal/getPortalForms';
export const TRANSACTION_LISTING_API_URL =
  HOST_API + '/formpayhome/portal/getTransactions';
export const FORM_CREATE_API_URL =
  HOST_API + '/formpayhome/portal/addPortalForm';
export const GET_PORTAL_FORM_API_URL =
  HOST_API + '/formpayhome/portal/getPortalForm/';
export const DELETE_PORTAL_FORM_API_URL =
  HOST_API + '/formpayhome/portal/deletePortalForm/';
export const UPDATE_PORTAL_FORM_API_URL =
  HOST_API + '/formpayhome/portal/updatePortalForm/';
export const GET_ALL_HUBSPOT_FORM_API_URL =
  HOST_API + '/formpayhome/portal/getHubspotForms';
export const FORM_SUBMISSION_LISTING_API_URL =
  HOST_API + '/formpayhome/portal/getFormSubmissions';
export const BILLING_DETAIL_API_URL =
  HOST_API + '/formpayhome/billing/getDetails';
export const BILLING_START_API_URL = HOST_API + '/formpayhome/billing/start';
export const BILLING_CONFIRM_PAYMENT_API_URL =
  HOST_API + '/formpayhome/billing/confirmCardPayment';
export const BILLING_CANCEL_SUBSCRIPTION_API_URL =
  HOST_API + '/formpayhome/billing/cancel';
export const DELETE_FORM_API_URL =
  HOST_API + '/formpayhome/portal/deletePortalForm/';
