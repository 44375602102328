import { Button, Card, Stack, Typography } from '@mui/material';
import {
  CREATE_EMAIL_API_URL,
  CREATE_PROPERTIES_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import {
  useCreatedConfiguration,
  useWooCommerceStores,
} from 'api/ApiHooks/PointsRewardsApiHooks';
import Page from 'components/Page';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { POINTSANDREWARD } from 'routes/paths';
import AddStore from '../../AddStore/AddStore';
import ConfigurationCard from './ConfigurationCard';

const Configuration = () => {
  const portalId = usePortalId();
  const { t } = useTranslation();
  const stores = useWooCommerceStores(Number(portalId), POINTSANDREWARD);

  const { data, refetch } = useCreatedConfiguration(Number(portalId), '');

  if (data === undefined || stores.data === undefined) {
    return <PartialPageSpinner />;
  }

  if (stores.data.data.isConnected === false) {
    return <AddStore />;
  }

  const configurationData = data?.data;

  return (
    <Page
      title={
        t('configuration').charAt(0).toUpperCase().charAt(0).toUpperCase() +
        t('configuration').slice(1)
      }
      className='px-6'
    >
      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <ConfigurationCard
          configurationData={configurationData}
          refetch={refetch}
        />
        <div className='sm:basis-1/6'></div>
      </div>
    </Page>
  );
};

export default Configuration;
