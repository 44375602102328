import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs } from '@mui/material';
import { PATH_USER } from 'routes/paths';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import AccountGeneral from './AccountGeneral';
import AccountBilling from './AccountBilling';
import AccountChangePassword from './AccountChangePassword';
import usePortalId from 'hooks/portal/usePortalId';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function UserAccount() {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <AccountGeneral />,
    },
    {
      value: 'change_password',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <AccountChangePassword />,
    },
    {
      value: 'billing',
      icon: <Iconify icon={'ri:bill-fill'} width={20} height={20} />,
      component: <AccountBilling />,
    },
  ];

  return (
    <Page title={t('user_accounts')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('account_single')}
          links={[
            {
              name: t('app_listing'),
              href: PATH_USER.appListing(portalId),
            },
            { name: t('user_accounts') },
          ]}
        />

        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={tab}
          onChange={(e, tabIndex) =>
            navigate(PATH_USER.general.accountSettings(portalId, tabIndex))
          }
        >
          {ACCOUNT_TABS.map(tab => (
            <Tab
              disableRipple
              key={t(tab.value)}
              label={capitalCase(t(tab.value))}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map(tabVal => {
          const isMatched = tabVal.value === tab;

          return isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
