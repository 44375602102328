import { HOST_API } from 'config';
export const COUPON_RULE_LISTING_API_URL =
  HOST_API + '/automatedcouponhome/coupon/getCoupon';
export const STORE_LISTING_API_URL = HOST_API + '/ecommcore/store/getStores';
export const DELETE_COUPON_RULE_API_URL =
  HOST_API + '/automatedcouponhome/coupon/deleteCoupon/';
export const DELETE_STORE_API_URL = HOST_API + '/ecommcore/store/removeStore';
export const GET_SOURCES_URL = HOST_API + '/ecommcore/source/getSources';
export const CONNECT_TO_HUBSPOT_APP_API_URL =
  '/ecommcore/panel/connectHubspotApp';
export const CREATE_COUPON_API_URL = '/automatedcouponhome/coupon/createCoupon';
export const CREATE_STORE_API_URL = '/ecommcore/store/connectWooStore';
export const UPDATE_STORE_API_URL = '/ecommcore/store/updateWooStore';
export const STORE_DETAIL_API_URL = '/ecommcore/store/getStores';
export const COUPON_RULE_DETAIL_API_URL =
  '/automatedcouponhome/coupon/getSingleCoupon';
export const UPDATE_COUPON_RULE_API_URL =
  '/automatedcouponhome/coupon/updateCoupon';
export const HUBSPOT_COUPON_API_URL =
  '/automatedcouponhome/coupon/getHubCoupon';
export const SCHEDULE_COUPONS_API_URL =
  '/automatedcouponhome/coupon/scheduleCoupon';
export const GET_APP_DETAIL_API_URL = '/ecommcore/panel/getAppDetails';
export const CREATE_COUPON_INFRAME = '/automated-coupons/createcoupon';
export const AUTOMATED_COUPON_TOKEN_VALIDATE =
  HOST_API + '/automatedcouponhome/crmcard/verifyToken';
export const CREATE_AUTOMATED_COUPON =
  HOST_API + '/automatedcouponhome/crmcard/crmCardCouponCreate';
export const WOO_PRODUCTS_GET_URL =
  HOST_API + '/ecommcore/store/getWooProducts';
export const GET_WOO_BRANDS = HOST_API + '/ecommcore/store/getWooBrands';
export const GET_HUBSPOT_USERS =
  HOST_API + '/automatedcouponhome/request/fetchHubspotUsers';
export const CREATE_HUBSPOT_USER =
  HOST_API + '/automatedcouponhome/request/createHsUsers';
