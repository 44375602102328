import { Typography, TableCell, TableRow } from '@mui/material';
import { fDate2 } from 'utils/formatTime';
import { useTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import { RewardCouponListingObjType } from '../PointsRewardsType';
import { useTheme } from '@mui/material/styles';
import Label from 'components/Label';

type Props = {
  row: RewardCouponListingObjType;
};

export default function RewardCouponTableRow({ row }: Props) {
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { code, created_at, customer_id, usage_status, used_at } = row;
  const usedAtArr: number[] = [];
  used_at.map(val => {
    usedAtArr.push(val.order_id);
  });

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell>
        <Typography noWrap>{fDate2(Number(created_at))}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{code}</Typography>
      </TableCell>
      <TableCell>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={usage_status === 'used' ? 'primary' : 'success'}
        >
          {capitalCase(usage_status)}
        </Label>
      </TableCell>
      <TableCell>
        <Typography noWrap>{customer_id}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>
          {usedAtArr.length ? usedAtArr.join(',') : '-'}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
