import { HOST_API } from 'config';

export const REWARD_RULE_DETAIL_API_URL =
  '/pointsandrewardhome/reward/getSingleReward';
export const RULE_LISTING_API_URL =
  HOST_API + '/pointsandrewardhome/panel/getRules';
export const RULE_ENABLE_DISABLE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/enableDisableRule/';
export const RULES_TYPE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/getRulesType';
export const RULE_DETAIL_API_URL = '/pointsandrewardhome/panel/getRule';
export const DELETE_RULE_API_URL = '/pointsandrewardhome/panel/deleteRule';
export const REWARD_DETAIL_API_URL = '/pointsandrewardhome/panel/getReward';
export const REWARD_LISTING_API_URL = '/pointsandrewardhome/panel/getRewards';
export const REWARD_ENABLE_DISABLE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/enableDisableReward/';
export const REWARD_COUPON_LISTING_API_URL =
  HOST_API + '/pointsandrewardhome/panel/getClaimedRewardByReward';
export const HUBSPOT_OWNER_API_URL =
  'pointsandrewardhome/panel/getHubspotOwners';
export const APP_SETTINGS_API_URL = 'pointsandrewardhome/panel/getAppSettings';
export const UPDATE_APP_SETTINGS_API_URL =
  'pointsandrewardhome/panel/updateAppSettings';
export const WOOCOMMERCE_CURRENCY_API_URL =
  'pointsandrewardhome/panel/getWooCurrencies';
export const CREATE_REWARD_API_URL =
  HOST_API + '/pointsandrewardhome/panel/addReward';
export const UPDATE_REWARD_API_URL =
  HOST_API + '/pointsandrewardhome/panel/updateReward/';
export const DELETE_REWARD_API_URL =
  HOST_API + '/pointsandrewardhome/panel/deleteReward/';
export const CREATE_RULE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/addRule';
export const UPDATE_RULE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/updateRule';
//Customer.
export const CUSTOMER_LISTING_API_URL =
  HOST_API + '/pointsandrewardhome/panel/getCustomers/';
export const CUSTOMER_DETAILS_API_URL =
  HOST_API + '/pointsandrewardhome/panel/getCustomer';
export const UPDATE_CUSTOMER_POINTS_API_URL =
  HOST_API + '/pointsandrewardhome/panel/manuallyUpdatePoints';
export const BLOCK_CUSTOMER_API_URL =
  HOST_API + '/pointsandrewardhome/panel/blockUnblockCustomer';
export const UPDATE_CUSTOMER_BIRTH_DATE_API_URL =
  HOST_API + '/pointsandrewardhome/panel/changeCustomerBirthday';
export const CREATE_PROPERTIES_API_URL =
  HOST_API + '/pointsandrewardhome/panel/createHubSpotProperties';
export const CREATE_EMAIL_API_URL =
  HOST_API + '/pointsandrewardhome/panel/createHubspotMarketingEmails';
export const CREATE_WORKFLOW_API_URL =
  HOST_API + '/pointsandrewardhome/panel/createHubspotWorkflows';
export const GET_HUBSPOT_CREATED_CONFIG =
  HOST_API + '/pointsandrewardhome/panel/getHubspotCreatedData';
export const APPROVE_PENDING_POINTS_API_URL =
  HOST_API + '/pointsandrewardhome/panel/approvePendingPoints';
