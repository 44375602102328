import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Box,
  Card,
} from '@mui/material';
import {
  AUTOMATED_COUPON_TOKEN_VALIDATE,
  GET_SOURCES_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import axios from 'axios';
import usePortalId from 'hooks/portal/usePortalId';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_PAGE } from 'routes/paths';
import axiosInstance from 'utils/axios';
import { ISources } from '../DiscountCouponsType';
import WoocommerceForm from './form/Woocommerce';

const getAllSourcesRequest = async (
  accessToken: string
): Promise<{ data: ISources[] }> => {
  const { data } = await axiosInstance.get<{ data: ISources[] }>(
    `${GET_SOURCES_URL}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

const useGetAllSources = (
  accessToken: string
): UseQueryResult<{ data: ISources[] }> => {
  return useQuery([GET_SOURCES_URL], () => getAllSourcesRequest(accessToken), {
    refetchOnWindowFocus: false,
  });
};

const AutomatedCouponForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const search = useLocation().search;
  const tokenId = new URLSearchParams(search).get('token');
  const accessToken = new URLSearchParams(search).get('login');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data } = useGetAllSources(accessToken as string);
  const [selectedSource, setselectedSource] = useState('woocommerce');
  const [tokenValidity, setTokenValidity] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.post(
          `${AUTOMATED_COUPON_TOKEN_VALIDATE}`,
          { portalId: portalId, token: tokenId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setTokenValidity(response.data.success);
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
      }
    }
    fetchData();
  }, []);

  if (!tokenValidity) {
    navigate(PATH_PAGE.page404);
  }

  const handleChangeSource = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    setselectedSource(e.target.value);
  };
  const getStoreForm = (store: string) => {
    switch (store) {
      case 'woocommerce':
        return <WoocommerceForm accessToken={accessToken} />;
      case 'shopify':
        return <h1>shopify</h1>;
      case 'magento':
        return <h1>magento</h1>;
      default:
        return <h1>Select A Store First</h1>;
    }
  };

  return (
    <Box>
      <Card
        sx={{
          width: '100%',
          maxWidth: '500px',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          marginTop: '15px',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-helper-label'>
            {t('sources')}
          </InputLabel>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            value={selectedSource}
            label={t('stores')}
            onChange={handleChangeSource}
          >
            {_.map(data?.data, function (store, key) {
              return (
                <MenuItem
                  disabled={store.name !== 'woocommerce'}
                  key={store._id}
                  value={store.name}
                >
                  {store.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {getStoreForm(selectedSource)}
      </Card>
    </Box>
  );
};

export default AutomatedCouponForm;
