import { useWooCommerceStores } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import usePortalId from 'hooks/portal/usePortalId';
import { POINTSANDREWARD } from 'routes/paths';
import EditRuleContent from './child/EditRuleContent';

export default function EditRule() {
  useIfValidityExpired(POINTSANDREWARD);
  const portalId = usePortalId();
  const { data } = useWooCommerceStores(Number(portalId), POINTSANDREWARD);
  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  return <EditRuleContent storeId={data.data._id} />;
}
