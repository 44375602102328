import { TableRow, TableCell, Typography, Link } from '@mui/material';
import {
  PaymentList,
  PortalAppPayment,
} from 'containers/common/payments/PaymentType';
import { useTranslation } from 'react-i18next';
import { fDate } from 'utils/formatTime';

type Props = {
  row: PortalAppPayment;
};

export default function AccountBillingTableRow({ row }: Props) {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { amount, created_at, invoice_pdf } = row;

  return (
    <TableRow>
      <TableCell>{fDate(Number(created_at))}</TableCell>
      <TableCell>
        <Typography variant='subtitle2' noWrap>
          ${amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Link variant='subtitle2' color='#2196F3' href={invoice_pdf}>
          {t('pdf')}
        </Link>
      </TableCell>
    </TableRow>
  );
}
