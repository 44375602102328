import { Box, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
import AutomatedCouponForm from './AutomatedCouponForm';

const CreateCouponIframe = () => {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();

  return (
    <Page sx={{ padding: '10px' }} title={t('create_coupon')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography sx={{ textAlign: 'center' }} variant='subtitle1'>
          {t('create_coupon')}
        </Typography>
        <AutomatedCouponForm />
      </Container>
    </Page>
  );
};

export default CreateCouponIframe;
