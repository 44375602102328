import { Card, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from 'components/Iconify';
import { RuleListingObjectType } from '../PointsRewardsType';
import Image from 'components/Image';
import useResponsive from 'hooks/useResponsive';
import MoreMenuButton from 'components/MoreMenuButton';

type Props = {
  row: RuleListingObjectType;
  onEditRow: VoidFunction;
  onChangeSwitch(enabled: boolean): Promise<void>;
  onDeleteRow: VoidFunction;
};

export default function RulesTableRow({
  row,
  onEditRow,
  onChangeSwitch,
  onDeleteRow,
}: Props) {
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    title,
    name,
    rule_icon,
    is_enabled,
    points_through_rule,
    rewarded_count,
    value,
    custom_rule,
  } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [isEnabled, setIsEnabled] = useState(is_enabled);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <Card
      sx={{
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid rgba(145, 158, 171, 0.24)',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: smUp ? 'row' : 'column',
        justifyContent: 'space-between',
        marginBottom: '10px',
        padding: '20px',
      }}
    >
      <Stack
        sx={{ flexBasis: '35%', flexDirection: 'row', pb: smUp ? '' : '20px' }}
      >
        <Stack
          sx={{
            flexBasis: '15%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            pb: smUp ? '' : '20px',
          }}
        >
          {rule_icon && (
            <Image
              sx={{
                width: '36px',
                height: '36px',
                display: 'inline-block',
              }}
              visibleByDefault
              disabledEffect
              src={require('assets/images/pointsandreward_icon/' + rule_icon)}
              alt={title}
            />
          )}
        </Stack>
        <Typography
          noWrap
          sx={{ paddingTop: '8px', color: isEnabled ? '#212B36' : '#919EAB' }}
        >
          {title}
        </Typography>
      </Stack>
      {smUp ? (
        <>
          <Stack sx={{ flexBasis: '20%', flexDirection: 'row' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography noWrap>{points_through_rule ?? 0}</Typography>
              <Typography noWrap variant='caption' sx={{ color: '#919EAB' }}>
                {t('points_provided')}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ flexBasis: '15%', flexDirection: 'row' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography noWrap>{rewarded_count ?? 0}</Typography>
              <Typography noWrap variant='caption' sx={{ color: '#919EAB' }}>
                {t('triggered')}
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack sx={{ flexBasis: '35%', flexDirection: 'row', pb: '20px' }}>
          {points_through_rule && (
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography noWrap>{points_through_rule}</Typography>
              <Typography noWrap variant='caption' sx={{ color: '#919EAB' }}>
                {t('points_provided')}
              </Typography>
            </Stack>
          )}
          {rewarded_count && (
            <Stack
              sx={{ display: 'flex', flexDirection: 'column', ml: '20px' }}
            >
              <Typography noWrap>{rewarded_count}</Typography>
              <Typography noWrap variant='caption' sx={{ color: '#919EAB' }}>
                {t('triggered')}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Stack sx={{ flexBasis: '10%', flexDirection: 'row' }}>
        <Switch
          name={name}
          checked={isEnabled}
          value={value}
          size='small'
          onChange={e => {
            setIsEnabled(!isEnabled);
            onChangeSwitch(e.target.checked);
          }}
        />
      </Stack>
      <Stack sx={{ flexBasis: '5%', flexDirection: 'row' }}>
        <MoreMenuButton
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          smUp={smUp}
          actions={
            <>
              {custom_rule && (
                <MenuItem
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  {t('delete')}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  onEditRow();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                {t('edit')}
              </MenuItem>
            </>
          }
        />
      </Stack>
    </Card>
  );
}
