import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Card,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axiosInstance from 'utils/axios';
import {
  CREATE_EMAIL_API_URL,
  CREATE_PROPERTIES_API_URL,
  CREATE_WORKFLOW_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { useSnackbar } from 'notistack';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { POINTSANDREWARD } from 'routes/paths';
import { isApiError } from 'utils/apiFunctions';
import { CreatedConfig, PropertyObject } from '../SettingsType';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

type ButtonType = {
  properties: boolean;
  emails: boolean;
  workflows: boolean;
};

type Props = {
  configurationData: CreatedConfig;
  refetch: () => void;
};

const ConfigurationCard: FunctionComponent<Props> = ({
  configurationData,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const portalId = usePortalId();
  const { t } = useTranslation();

  const showHideCreateButton = (arr: PropertyObject[]) => {
    let showCreateBtn = false;
    arr.map(ar => {
      if (ar.is_created === false) {
        showCreateBtn = true;
      }
    });

    return showCreateBtn;
  };

  const [createState] = useState<ButtonType>({
    properties: showHideCreateButton(configurationData.properties),
    emails: showHideCreateButton(configurationData.emails),
    workflows: showHideCreateButton(configurationData.workflows),
  });

  const [showButtonState] = useState<ButtonType>({
    properties: !showHideCreateButton(configurationData.properties),
    emails: !showHideCreateButton(configurationData.emails),
    workflows: !showHideCreateButton(configurationData.workflows),
  });

  const [showContentState, setShowContentState] = useState<ButtonType>({
    properties: false,
    emails: false,
    workflows: false,
  });

  const handleCreate = async (type: string) => {
    let API_URL = '';
    if (type === 'properties') {
      API_URL = CREATE_PROPERTIES_API_URL;
    }
    if (type === 'workflows') {
      API_URL = CREATE_WORKFLOW_API_URL;
    }
    if (type === 'emails') {
      API_URL = CREATE_EMAIL_API_URL;
    }
    try {
      const response = await axiosInstance.post(API_URL, {
        portalId: Number(portalId),
        appCode: POINTSANDREWARD,
      });
      refetch();
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (error: any) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const getTableContent = (heading: string, ObjArr: PropertyObject[]) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>{heading}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ObjArr.map((obj, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {obj.label}
                </TableCell>
                <TableCell
                  component='th'
                  scope='row'
                  sx={{ textAlign: 'right' }}
                >
                  {obj.is_created ? (
                    <CheckCircleOutlineRoundedIcon />
                  ) : (
                    <CancelRoundedIcon />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <div className='flex-grow basis-5/6 py-5'>
        <Card sx={{ padding: '15px', marginBottom: '15px' }}>
          <Stack
            sx={{
              paddingTop: '25px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2'>
              {t('create')} {t('properties')}
            </Typography>
            <Stack
              sx={{
                paddingBottom: '25px',
                paddingLeft: '255px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {showHideCreateButton(configurationData.properties) ? (
                <Button
                  onClick={() => handleCreate('properties')}
                  variant='contained'
                  sx={{ marginRight: '10px' }}
                >
                  {t('create')}
                </Button>
              ) : null}
              {!showHideCreateButton(configurationData.properties) ? (
                <Button
                  onClick={() =>
                    setShowContentState({
                      ...showContentState,
                      properties: !showContentState.properties,
                    })
                  }
                  variant='text'
                >
                  {showContentState.properties ? t('hide') : t('show')}
                </Button>
              ) : null}
            </Stack>
          </Stack>
          {showContentState.properties ? (
            <Card sx={{ padding: '15px' }}>
              <Stack>
                {getTableContent(t('properties'), configurationData.properties)}
              </Stack>
            </Card>
          ) : null}
        </Card>
        <Card sx={{ padding: '15px', marginBottom: '15px' }}>
          <Stack
            sx={{
              paddingTop: '25px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2'>
              {t('create')} {t('Emails')}
            </Typography>
            <Stack
              sx={{
                paddingBottom: '25px',
                paddingLeft: '255px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {showHideCreateButton(configurationData.emails) ? (
                <Button
                  onClick={() => handleCreate('emails')}
                  variant='contained'
                  sx={{ marginRight: '10px' }}
                >
                  {t('create')}
                </Button>
              ) : null}

              {!showHideCreateButton(configurationData.emails) ? (
                <Button
                  onClick={() =>
                    setShowContentState({
                      ...showContentState,
                      emails: !showContentState.emails,
                    })
                  }
                  variant='text'
                >
                  {showContentState.emails ? t('hide') : t('show')}
                </Button>
              ) : null}
            </Stack>
          </Stack>
          {showContentState.emails ? (
            <Card sx={{ padding: '15px' }}>
              <Stack>
                {getTableContent(t('Emails'), configurationData.emails)}
              </Stack>
            </Card>
          ) : null}
        </Card>
        <Card sx={{ padding: '15px', marginBottom: '15px' }}>
          <Stack
            sx={{
              paddingTop: '25px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2'>
              {t('create')} {t('workflow')}
            </Typography>
            <Stack
              sx={{
                paddingBottom: '25px',
                paddingLeft: '255px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {showHideCreateButton(configurationData.workflows) ? (
                <Tooltip title={t('email_needs_to_be_published_before_emails')}>
                  <Button
                    onClick={() => handleCreate('workflows')}
                    variant='contained'
                    sx={{ marginRight: '10px' }}
                  >
                    {t('create')}
                  </Button>
                </Tooltip>
              ) : null}
              {!showHideCreateButton(configurationData.workflows) ? (
                <Button
                  onClick={() =>
                    setShowContentState({
                      ...showContentState,
                      workflows: !showContentState.workflows,
                    })
                  }
                  variant='text'
                >
                  {showContentState.workflows ? t('hide') : t('show')}
                </Button>
              ) : null}
            </Stack>
          </Stack>
          {showContentState.workflows ? (
            <Card sx={{ padding: '15px' }}>
              <Stack>
                {getTableContent(
                  t('workflows').charAt(0).toUpperCase() +
                    t('workflows').slice(1),
                  configurationData.workflows
                )}
              </Stack>
            </Card>
          ) : null}
        </Card>
      </div>
    </>
  );
};

export default ConfigurationCard;
