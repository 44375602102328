import {
  Box,
  Card,
  Tabs,
  Tab,
  Typography,
  MenuItem,
  Stack,
  IconButton,
  Tooltip,
  Grid,
  CardHeader,
} from '@mui/material';
import { capitalCase } from 'change-case';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  POINTS_AND_REWARDS_NAVIGATION,
  PATH_USER,
  PATH_PAGE,
  POINTSANDREWARD,
} from 'routes/paths';
import { customerDetailRequest } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/Iconify';
import useTabs from 'hooks/useTabs';
import useActiveApps from 'hooks/apps/useActiveApps';
import { FunctionComponent, useState } from 'react';
import { CUSTOMER_DETAILS_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { useQuery } from 'react-query';
import Image from 'components/Image';
import Reward from 'assets/images/rewards.svg';
import useResponsive from 'hooks/useResponsive';
import ButtonDropDown from 'components/ButtonDropDown';
import {
  CustomerListingObjectType,
  CustomerActivity,
  CustomerClaimRewards,
  CustomerDetailType,
} from '../../PointsRewardsType';
import CustomerAnalytic from './CustomerAnalytic';
import CustomerHistoryListing from './CustomerHistoryListing';
import CustomerRewardsListing from './CustomerRewardsListing';
import BlockDialogBox from './dialog/BlockDialogBox';
import DateDialogBox from './dialog/DateDialogBox';
import PointsDialogBox from './dialog/PointsDialogBox';
import PendingDialogueBox from './dialog/PendingDialogueBox';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import Notices from 'containers/common/Notices/Notices';

type Props = {
  storeId: string;
  customerId: string;
  currency: string;
};
const CustomerForm: FunctionComponent<Props> = ({
  storeId,
  customerId,
  currency,
}) => {
  useIfValidityExpired(POINTSANDREWARD);
  const { t } = useTranslation();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const activeApps = useActiveApps(portalId);
  const { currentTab, onChangeTab } = useTabs('history');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pointsDialogBoxOpen, setPointsDialogBoxOpen] = useState(false);
  const [pointsDialogTitle, setPointsDialogTitle] = useState('');
  const [pointsDialogContent, setPointsDialogContent] = useState('');
  const [pointType, setPointType] = useState<'add' | 'remove'>('add');
  const [blockType, setBlockType] = useState<'block' | 'unblock'>('block');
  const [blockDialogBoxOpen, setBlockDialogBoxOpen] = useState(false);
  const [blockDialogTitle, setBlockDialogTitle] = useState('');
  const [blockDialogContent, setBlockDialogContent] = useState('');
  const [dateDialogBoxOpen, setDateDialogBoxOpen] = useState(false);
  const [dateDialogTitle, setDateDialogTitle] = useState('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [customerDetails, setCustomerDetails] =
    useState<CustomerListingObjectType | null>();
  const [activities, setActivities] = useState<CustomerActivity[]>([]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [claimedRewards, setClaimedRewards] = useState<CustomerClaimRewards[]>(
    []
  );
  const [pendingDialogue, setPendingDialogue] = useState({
    open: false,
    title: '',
    content: '',
  });

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const pointsDialogBoxOpenClose = () => {
    setPointsDialogBoxOpen(!pointsDialogBoxOpen);
  };

  const blockDialogBoxOpenClose = () => {
    setBlockDialogBoxOpen(!blockDialogBoxOpen);
  };

  const dateDialogBoxOpenClose = () => {
    setDateDialogBoxOpen(!dateDialogBoxOpen);
  };

  if (!activeApps.includes('pointsandreward')) {
    navigate(PATH_PAGE.page404);
  }

  const onSuccess = (dataResult: CustomerDetailType) => {
    if (dataResult.success) {
      const birthDateCustomer =
        dataResult?.data?.date_of_birth &&
        'year' in dataResult?.data?.date_of_birth
          ? new Date(
              dataResult?.data?.date_of_birth.year ?? 0,
              (dataResult?.data?.date_of_birth.month
                ? dataResult?.data?.date_of_birth.month - 1
                : 0) ?? 0,
              dataResult?.data?.date_of_birth.day
            )
          : null;
      setBlockType(dataResult?.data.status === 'blocked' ? 'block' : 'unblock');
      setBirthDate(birthDateCustomer);
      setCurrentPoints(dataResult?.data?.point_balance?.available_points ?? 0);
      setActivities(dataResult?.data?.activities ?? []);
      setClaimedRewards(dataResult?.data?.claimed_rewards ?? []);
      setCustomerDetails(dataResult?.data);
    }
  };

  const { isFetching, refetch } = useQuery(
    [CUSTOMER_DETAILS_API_URL],
    () => customerDetailRequest(Number(portalId), storeId, customerId),
    {
      refetchOnWindowFocus: false,
      onSuccess,
    }
  );

  if (isFetching) {
    return <PartialPageSpinner />;
  }

  const EDIT_CUSTOMER_TABS = [
    {
      value: 'history',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <CustomerHistoryListing customerActivities={activities} />,
    },
    {
      value: 'rewards',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      component: <CustomerRewardsListing claimedRewards={claimedRewards} />,
    },
  ];

  const toggleBlockType = () => {
    if (blockType === 'block') {
      setBlockType('unblock');
    } else {
      setBlockType('block');
    }
  };

  return (
    <Page title={t('customer')} className='px-6'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Image
            sx={{
              width: '36px',
              height: '36px',
              display: 'inline-block',
            }}
            visibleByDefault
            disabledEffect
            src={Reward}
            alt='Points and Reward'
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h4' gutterBottom>
            {t('points_and_rewards')}
          </Typography>
        </Grid>
        <Grid item xs={smUp ? 7 : 6}>
          <LoadingButton
            sx={{ verticalAlign: 'text-bottom', float: 'right' }}
            variant='contained'
            loading={false}
            onClick={() =>
              navigate(PATH_USER.general.accountSettings(portalId, 'billing'))
            }
          >
            {t('manage_app')}
          </LoadingButton>
        </Grid>
      </Grid>
      <HeaderBreadcrumbs
        heading={t('')}
        links={[
          {
            name: t('all_apps'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('points_and_rewards'),
            href: '',
          },
          {
            name: t('customer'),
            href: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              'customer'
            ),
          },
          {
            name: t('edit_cutsomer'),
            href: '',
          },
        ]}
      />
      <Notices appName={POINTSANDREWARD} />
      <Box sx={{ mb: 5 }} />
      <Card sx={{ backgroundColor: '#CCF1FF', marginBottom: '32px' }}>
        <CardHeader
          title={customerDetails?.username}
          subheader={
            <Box>
              {customerDetails?.email +
                ' | ' +
                (birthDate
                  ? birthDate?.toLocaleDateString()
                  : t('edit') + ' ' + t('birth_date'))}
              <Tooltip title={t('edit') + ' ' + t('birth_date')}>
                <IconButton
                  onClick={() => {
                    setDateDialogTitle(t('change_birthday'));
                    dateDialogBoxOpenClose();
                  }}
                >
                  <Iconify icon={'eva:edit-outline'} />
                </IconButton>
              </Tooltip>
            </Box>
          }
          action={
            <ButtonDropDown
              title={t('action')}
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              sx={{ mt: smUp ? 2 : '' }}
              actions={
                <>
                  <MenuItem
                    onClick={() => {
                      setPointsDialogTitle(t('give_points'));
                      setPointsDialogContent(
                        t('points_approved_immediately_redeemed_for_rewards')
                      );
                      setPointType('add');
                      pointsDialogBoxOpenClose();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'bi:plus-lg'} sx={{ color: '#637381' }} />
                    {t('give_points')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setPendingDialogue({
                        ...pendingDialogue,
                        open: !pendingDialogue.open,
                        title: t('pending_points'),
                        content:
                          customerDetails?.point_balance.pending_points !==
                            undefined &&
                          customerDetails?.point_balance.pending_points === 0
                            ? t('no_pending_points_available')
                            : customerDetails?.point_balance.pending_points.toString() +
                              ' ' +
                              t('points') +
                              t(' - ') +
                              t('approve_pending_points_for_customer'),
                      });
                      handleCloseMenu();
                    }}
                  >
                    <Iconify
                      icon={'ic:outline-pending-actions'}
                      sx={{ color: '#637381' }}
                    />
                    {t('pending_points')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setPointsDialogTitle(t('remove_points'));
                      setPointsDialogContent(
                        t('remove_approve_points_from_customer')
                      );
                      setPointType('remove');
                      pointsDialogBoxOpenClose();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify
                      icon={'gridicons:cross'}
                      sx={{ color: '#637381' }}
                    />
                    {t('remove_points')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (blockType !== 'block') {
                        setBlockDialogTitle(t('block_customer'));
                        setBlockDialogContent(
                          t('block_your_customer_from_loyalty_program')
                        );
                      } else {
                        setBlockDialogTitle(t('unblock_customer'));
                        setBlockDialogContent(
                          t('unblock_your_customer_from_loyalty_program')
                        );
                      }
                      blockDialogBoxOpenClose();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify
                      icon={'ri:spam-2-fill'}
                      sx={{ color: '#637381' }}
                    />
                    {blockType === 'block' ? t('unblock') : t('block')}
                  </MenuItem>
                </>
              }
            />
          }
          sx={{
            padding: smUp ? '16px 32px' : '16px 10px',
          }}
        />
      </Card>
      <Grid
        container
        wrap='wrap'
        direction='row'
        rowSpacing={smUp ? 0 : 2.5}
        sx={{
          background: '#F4F6F8',
          border: '1px solid #E5E8EB',
          borderRadius: '16px',
          justifyContent: 'space-between',
          p: 2,
          m: '0 auto',
        }}
      >
        <Grid item xs={12} sm={6} md={2.25}>
          <CustomerAnalytic
            title={t('current_points')}
            total={currentPoints}
            icon='ant-design:trophy-filled'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.25}>
          <CustomerAnalytic
            title={t('activities_completed')}
            total={customerDetails?.activities_done ?? 0}
            icon='eva:briefcase-fill'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.25}>
          <CustomerAnalytic
            title={t('rewards_calimed')}
            total={customerDetails?.total_claimed_rewards ?? 0}
            icon='ant-design:star-filled'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.25}>
          <CustomerAnalytic
            title={t('total_revenue')}
            total={customerDetails?.total_revenue ?? 0}
            icon='ant-design:setting-filled'
            currency={currency}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.25}>
          <CustomerAnalytic
            title={t('referrals')}
            total={customerDetails?.referral_done ?? 0}
            icon='fa6-solid:user-group'
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 5 }} />
      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        value={currentTab}
        onChange={onChangeTab}
      >
        {EDIT_CUSTOMER_TABS.map(tab => (
          <Tab
            disableRipple
            key={t(tab.value)}
            label={capitalCase(t(tab.value))}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Box sx={{ mb: 5 }} />
      {EDIT_CUSTOMER_TABS.map(tab => {
        const isMatched = tab.value === currentTab;

        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
      <Stack>
        <PointsDialogBox
          storeId={storeId}
          customerId={customerId}
          type={pointType}
          open={pointsDialogBoxOpen}
          title={pointsDialogTitle}
          content={pointsDialogContent}
          updateActivities={(
            data: CustomerActivity[],
            updatedCurrentPoints: number
          ) => {
            setActivities(data);
            setCurrentPoints(updatedCurrentPoints);
          }}
          handleOpenCloseConfirmDialogue={() => pointsDialogBoxOpenClose()}
        />
        <PendingDialogueBox
          customerId={customerId}
          storeId={storeId}
          pendingPoints={customerDetails?.point_balance?.pending_points}
          pendingDialogue={pendingDialogue}
          pendingDialogueOpenClose={() =>
            setPendingDialogue({
              ...pendingDialogue,
              open: !pendingDialogue.open,
            })
          }
        />
        <BlockDialogBox
          toggleBlockType={toggleBlockType}
          storeId={storeId}
          customerId={customerId}
          open={blockDialogBoxOpen}
          type={blockType}
          title={blockDialogTitle}
          content={blockDialogContent}
          handleOpenCloseConfirmDialogue={() => blockDialogBoxOpenClose()}
        />
        <DateDialogBox
          storeId={storeId}
          customerId={customerId}
          open={dateDialogBoxOpen}
          birthDate={birthDate!}
          title={dateDialogTitle}
          updateDate={(date: Date) => setBirthDate(date)}
          handleOpenCloseConfirmDialogue={() => dateDialogBoxOpenClose()}
        />
      </Stack>
    </Page>
  );
};

export default CustomerForm;
