import useAuth from 'hooks/useAuth';

const useActiveApps = (portalId: string): string[] => {
  const { portals } = useAuth();
  const activePortals = portals.filter(
    portal => portalId === portal.portal_id.toString()
  );
  const connectedApps = activePortals[0].connected_apps;
  const activeApps: string[] = [];
  connectedApps.map(app => {
    if (app.is_active) {
      activeApps.push(app.app_name);
    }
  });

  return activeApps;
};

export default useActiveApps;
