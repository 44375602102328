import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs, Typography } from '@mui/material';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  PATH_USER,
} from 'routes/paths';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import usePortalId from 'hooks/portal/usePortalId';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StoreListing from './child/tabs/StoreListing';
import CouponRulesListing from './child/tabs/CouponRulesListing';
import CouponListing from './child/tabs/CouponListing';
import Notices from '../../../common/Notices/Notices';
import HubSpotUserListing from './child/tabs/HubSpotUserListing';

export default function UserAccount() {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const ACCOUNT_TABS = [
    {
      value: 'stores',
      icon: '',
      component: <StoreListing />,
    },
    {
      value: 'coupon-rules',
      icon: '',
      component: <CouponRulesListing />,
    },
    {
      value: 'coupons',
      icon: '',
      component: <CouponListing />,
    },
    {
      value: 'configuration',
      icon: '',
      component: <HubSpotUserListing />,
    },
  ];

  return (
    <Page title={t('store_and_coupons')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant='h4' gutterBottom>
          {t('stores_and_coupons')}
        </Typography>
        <HeaderBreadcrumbs
          heading={''}
          links={[
            {
              name: t('app_listing'),
              href: PATH_USER.appListing(portalId),
            },
            {
              name: t('stores_and_coupons'),
              href: '',
            },
          ]}
        />
        <Notices appName={AUTOMATEDCOUPON} />
        <Box sx={{ mb: 5 }} />

        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={tab}
          onChange={(e, tabIndex) =>
            navigate(
              DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
                portalId,
                tabIndex
              )
            )
          }
        >
          {ACCOUNT_TABS.map(tab => (
            <Tab
              disableRipple
              key={t(tab.value)}
              label={capitalCase(t(tab.value))}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map(tabVal => {
          const isMatched = tabVal.value === tab;

          return isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
