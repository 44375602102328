import { HOST_API } from 'config';
export const VERIFY_EMAIL_TOKEN = HOST_API + '/user/verifyuser/';
export const APP_LISTING_API_URL = HOST_API + '/ecommcore/panel/getApps';
export const PORTAL_LISTING_API_URL =
  HOST_API + '/ecommcore/panel/getAllPortals';
export const GET_ALL_APPS_WITH_BILLING_DETAILS_API_URL =
  HOST_API + '/ecommcore/billing/allApps';
export const DELETE_PORTAL_API_URL =
  HOST_API + '/ecommcore/panel/disconnectPortal';
export const PAYMENT_SUMMARY_API_URL =
  HOST_API + '/ecommcore/billing/getPaymentSummary';
export const PORTAL_APP_PAYMENT_API_URL = '/ecommcore/billing/getAppPayments';
export const USER_DETAILS_API_URL = HOST_API + '/user/getDetails';
export const FORGOT_PASSWORD_API_URL = HOST_API + '/user/forgot';
export const FORGOT_RESET_PASSWORD_API_URL = HOST_API + '/user/forgotreset';
export const UPDATE_PROFILE_API_URL = HOST_API + '/user/updateProfile';
export const RESET_PASSWORD_API_URL = HOST_API + '/user/resetpassword';
export const RESEND_VERIFICATION_MAIL_URL =
  HOST_API + '/user/resendConfirmation';
export const APP_PLANS_API_URL = '/ecommcore/billing/getAppPlans';
export const BILLING_START_API_URL =
  HOST_API + '/ecommcore/billing/startSubscription';
export const BILLING_CONFIRM_PAYMENT_API_URL =
  HOST_API + '/ecommcore/billing/confirmCardPayment';
export const BILLING_CANCEL_SUBSCRIPTION_API_URL =
  HOST_API + '/ecommcore/billing/cancelSubscription';
export const UPDATE_SUBSCRIPTION_API_URL =
  HOST_API + '/ecommcore/billing/updateSubscription';
export const WOO_PRODUCTS_GET_URL =
  HOST_API + '/ecommcore/store/getWooProducts';
