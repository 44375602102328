import LoadingScreen from 'components/LoadingScreen';
import EcommerceProductList from 'containers/common/dashboard/EcommerceProductList';
import WebpackLoadError from 'containers/common/others/ErrorBoundary';
import Payments from 'containers/common/payments/PaymentsContainer';
import ResendVerificationMail from 'containers/common/auth/ResendVerificationMail';
import UserAccount from 'containers/common/user/account/UserAccount';
import useAuth from 'hooks/useAuth';
import DashboardLayout from 'layouts/dashboard';
import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import VerifyEmail from '../containers/common/auth/VerifyEmail';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import { PATH_USER } from './paths';
import { discountCouponsRoutes } from './discountCoupons';
import { pointsAndRewardsRoutes } from './pointsAndRewardsRoutes';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();
  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <WebpackLoadError>
      <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
        <Component {...props} />
      </Suspense>
    </WebpackLoadError>
  );
};

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../containers/common/auth/Login')));

const Register = Loadable(
  lazy(() => import('../containers/common/auth/Register'))
);

const ResetPassword = Loadable(
  lazy(() => import('../containers/common/auth/ResetPassword'))
);
const ChangePassword = Loadable(
  lazy(() => import('../containers/common/auth/ChangePassword'))
);
const PortalListing = Loadable(
  lazy(() => import('../containers/common/user/Portal/PortalListing'))
);
const ConnectToHubspot = Loadable(
  lazy(
    () => import('../containers/common/user/ConnectHubspot/ConnectToHubspot')
  )
);
const VerifyCode = Loadable(
  lazy(() => import('../containers/common/auth/VerifyCode'))
);

const CreateCouponIframe = Loadable(
  lazy(
    () =>
      import(
        '../containers/Apps/DiscountCouponsApp/DiscountCoupons/Hubspot/CreateCouponIframe'
      )
  )
);

export default function Router() {
  return useRoutes([
    {
      path: '/automated-coupons/createcoupon/:portalId',
      element: <CreateCouponIframe />,
    },
    {
      path: 'auth',
      element: <GuestGuard />,

      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify-mail', element: <VerifyCode /> },
        { path: 'resend-mail', element: <ResendVerificationMail /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'forgotreset/:token', element: <ChangePassword /> },
      ],
    },
    {
      path: '/',
      element: <AuthGuard />,

      children: [
        {
          path: '/user',
          children: [
            {
              path: 'portals',
              element: <PortalListing />,
            },
            {
              path: 'connect-to-hubspot',
              element: <ConnectToHubspot />,
            },
          ],
        },
        {
          path: ':portalId/user/app',
          element: <DashboardLayout />,
          children: [
            {
              index: true,
              element: <EcommerceProductList />,
            },
          ],
        },
        {
          path: ':portalId/user/settings',
          element: <DashboardLayout />,
          children: [
            {
              path: ':tab',
              index: true,
              element: <UserAccount />,
            },
          ],
        },

        {
          path: ':portalId/user/:appName/',
          element: <DashboardLayout />,
          children: [
            {
              path: 'payments',
              index: true,
              element: <Payments />,
            },
          ],
        },
        {
          path: ':portalId',
          element: <DashboardLayout />,
          children: [
            {
              path: 'automated-coupons',
              children: discountCouponsRoutes,
            },
          ],
        },
        {
          path: ':portalId',
          element: <DashboardLayout />,
          children: [
            {
              path: 'loyalty-reward-points',
              children: pointsAndRewardsRoutes,
            },
          ],
        },
        { path: '', element: <Navigate to={PATH_USER.portals} /> },

        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ]);
}
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
