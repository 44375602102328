import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack } from '@mui/material';
import { RESEND_VERIFICATION_MAIL_URL } from 'api/ApiConstants/GeneralConstants';
import axios from 'axios';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import makeFormData from 'utils/getFormFromData';
import * as Yup from 'yup';
import {
  FormProvider,
  RHFTextField,
} from '../../../../../components/hook-form';

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

export default function ResendVerificationMailForm() {
  const { t } = useTranslation();
  const [sentSuccess, setSentSuccess] = useState(false);

  const isMountedRef = useIsMountedRef();
  const VerifyCodeSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is invalid'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm<FormValuesProps>({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axios.post(RESEND_VERIFICATION_MAIL_URL, makeFormData(data), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      setSentSuccess(true);
    } catch (error) {
      setSentSuccess(false);
      if (isMountedRef.current && axios.isAxiosError(error)) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction='column' spacing={2} justifyContent='center'>
        {!!errors.afterSubmit && (
          <Alert severity='error'>{errors.afterSubmit.message}</Alert>
        )}
        {sentSuccess && (
          <Alert severity='success'>{t('verification_link_resent')}</Alert>
        )}
        <RHFTextField
          name='email'
          label={t('email_address')}
          autoComplete='email'
        />
      </Stack>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        disabled={!isValid}
        sx={{ mt: 3 }}
      >
        {t('resend')}
      </LoadingButton>
    </FormProvider>
  );
}
