import { Box, Tabs, Tab, Grid, Typography } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  POINTS_AND_REWARDS_NAVIGATION,
  PATH_USER,
  POINTSANDREWARD,
} from 'routes/paths';
import { useRuleDetails } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { LoadingButton } from '@mui/lab';
import Image from 'components/Image';
import Reward from 'assets/images/rewards.svg';
import useResponsive from 'hooks/useResponsive';
import { FC } from 'react';
import RuleForm from '../../child/form/RuleForm';
import Notices from 'containers/common/Notices/Notices';

type Props = {
  storeId: string;
};

const EditRuleContent: FC<Props> = ({ storeId }) => {
  const { ruleId } = useParams<{
    ruleId: string;
  }>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const portalId = usePortalId();
  const smUp = useResponsive('up', 'sm');
  const { data, isFetching } = useRuleDetails(
    Number(portalId),
    Number(storeId),
    ruleId!
  );
  if (isFetching) {
    return <PartialPageSpinner />;
  }
  const ruleDetails = data?.data;

  return (
    <Page title={t('edit_rule')} className='px-6'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Image
            sx={{
              width: '36px',
              height: '36px',
              display: 'inline-block',
            }}
            visibleByDefault
            disabledEffect
            src={Reward}
            alt={t('points_and_rewards')}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h4' gutterBottom>
            {t('points_and_rewards')}
          </Typography>
        </Grid>
        <Grid item xs={smUp ? 7 : 6}>
          <LoadingButton
            sx={{ verticalAlign: 'text-bottom', float: 'right' }}
            variant='contained'
            loading={false}
            onClick={() =>
              navigate(PATH_USER.general.accountSettings(portalId, 'billing'))
            }
          >
            {t('manage_app')}
          </LoadingButton>
        </Grid>
      </Grid>
      <HeaderBreadcrumbs
        heading={t('')}
        links={[
          {
            name: t('all_apps'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('points_and_rewards'),
            href: '',
          },
          {
            name: t('rules'),
            href: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              'rules'
            ),
          },
          {
            name: t('edit_rule'),
            href: POINTS_AND_REWARDS_NAVIGATION.general.createRule(portalId),
          },
        ]}
      />
      <Notices appName={POINTSANDREWARD} />
      <Box sx={{ mb: 5 }} />
      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        value='rules'
        onChange={(e, tabIndex) =>
          navigate(
            POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              tabIndex
            )
          )
        }
      >
        <Tab
          disableRipple
          key={t('rules')}
          label={t('rules')}
          icon=''
          value='rules'
        />
      </Tabs>

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-3/6 py-5'>
          <RuleForm type='edit' ruleDetails={ruleDetails} storeId={storeId} />
        </div>
        <div className='sm:basis-3/6'></div>
      </div>
    </Page>
  );
};

export default EditRuleContent;
