import {
  Box,
  Card,
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  DELETE_STORE_API_URL,
  STORE_LISTING_API_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import { StoreListingType, StoreType } from '../../DiscountCouponsType';
import Page from 'components/Page';
import StoreTableRow from '../table/StoreTableRow';
import axiosInstance from 'utils/axios';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/Iconify';
import useActiveApps from 'hooks/apps/useActiveApps';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  PATH_PAGE,
} from 'routes/paths';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';

const ROWS_PER_PAGE = 10;

export default function StoreListing() {
  useIfValidityExpired(AUTOMATEDCOUPON);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'storeUrl', label: t('store_url'), align: 'left' },
    { id: 'storeUrl', label: t('connection'), align: 'left' },
    { id: 'action', label: t('action') },
  ];
  const portalId = usePortalId();
  const activeApps = useActiveApps(portalId);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);

  async function fetchData(skip = 0) {
    const response = await axiosInstance.post<StoreListingType>(
      STORE_LISTING_API_URL,
      undefined,
      {
        params: {
          portalId: portalId,
          appCode: 'automatedcoupon',
          skip,
          limit: ROWS_PER_PAGE,
        },
      }
    );

    return response.data;
  }

  const {
    data: storeResponse,
    isFetching,
    refetch,
  } = useQuery<StoreListingType>(
    [STORE_LISTING_API_URL, skip],
    () => fetchData(skip as number),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: lastPage => {
        return lastPage.hasMore === true
          ? lastPage.skip + ROWS_PER_PAGE
          : undefined;
      },
    }
  );

  const navigate = useNavigate();

  if (!activeApps.includes('automatedcoupon')) {
    navigate(PATH_PAGE.page404);
  }
  if (!storeResponse) {
    return <PartialPageSpinner />;
  }
  const totalPage = Math.ceil(storeResponse?.totalCount / ROWS_PER_PAGE);

  const handleEditRow = (id: number) => {
    navigate(DISCOUNT_COUPONS_NAVIGATION.general.editStore(portalId, id));
  };

  const handleDiconnectRow = async (_id: number) => {
    try {
      const res = await axiosInstance.post(DELETE_STORE_API_URL, {
        portalId: Number(portalId),
        storeId: _id,
        appCode: AUTOMATEDCOUPON,
      });
      if (res.data.success) {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        if (storeResponse.data.length === 1) {
          setSkip(skip => (skip > 0 ? skip - ROWS_PER_PAGE : 0));
        }
        refetch();
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  let contentLoad: React.ReactNode = {};

  if (isFetching || loading) {
    contentLoad = (
      <TableBody sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </TableBody>
    );
  }

  const mapPages = (data: StoreType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <StoreTableRow
          key={row._id}
          row={row}
          onEditRow={() => handleEditRow(row._id)}
          onDiconnectRow={() => handleDiconnectRow(row._id)}
        />
      );
    });

    return rows;
  };

  if (!isFetching) {
    contentLoad = storeResponse.data.length ? (
      <TableBody>{mapPages(storeResponse.data)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('store')} />
      </>
    );
  }

  return (
    <Page title={t('store_listing')}>
      <Container>
        <Box sx={{ mb: 5 }}>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row-reverse' }}>
            <LoadingButton
              sx={{ marginTop: '35px' }}
              variant='contained'
              onClick={() =>
                navigate(
                  DISCOUNT_COUPONS_NAVIGATION.general.createStore(portalId)
                )
              }
              startIcon={<Iconify icon='eva:plus-fill' />}
              loading={false}
            >
              {t('create_new_store')}
            </LoadingButton>
          </Box>
        </Box>

        <Card sx={{ height: '750px' }}>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                <TableHeadCustom headLabel={TABLE_HEAD} />
                {contentLoad}
              </Table>
              <Card
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingBottom: '25px',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                {storeResponse && totalPage > 1 ? (
                  <Pagination
                    color='primary'
                    variant='outlined'
                    count={totalPage}
                    page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                    onChange={(e, pageNo) =>
                      setSkip((pageNo - 1) * ROWS_PER_PAGE)
                    }
                  />
                ) : null}
              </Card>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
