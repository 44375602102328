import CreateCoupon from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/child/CreateCoupon';
import CreateStore from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/child/CreateStore';
import EditCoupon from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/child/EditCoupon';
import EditStore from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/child/EditStore';
import DiscountCoupons from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCoupons';
import CreateCouponIframe from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/Hubspot/CreateCouponIframe';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from '../config';

export const discountCouponsRoutes = [
  {
    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    index: true,
  },
  {
    path: '',
    children: [
      {
        path: 'create-store',
        element: <CreateStore />,
      },
      {
        path: 'create-coupon',
        element: <CreateCoupon />,
      },
      {
        path: ':tab',
        index: true,
        element: <DiscountCoupons />,
      },
      {
        path: 'store/edit/:storeId',
        element: <EditStore />,
      },
      {
        path: 'coupon/edit/:couponId',
        element: <EditCoupon />,
      },
      {
        path: 'createcoupon/',
        element: <CreateCouponIframe />,
      },
    ],
  },
];
