import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VERIFY_EMAIL_TOKEN } from 'api/ApiConstants/GeneralConstants';
import axios from 'axios';
import Invalid from 'components/Invalid';
import LoadingScreen from 'components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Correct from '../../../assets/images/correct.png';
import Email from '../../../assets/images/Email.png';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import Page from '../../../components/Page';
import LogoOnlyLayout from '../../../layouts/LogoOnlyLayout';
import { PATH_AUTH } from '../../../routes/paths';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

export default function VerifyEmail() {
  const { t } = useTranslation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [tokenStatus, setTokenStatus] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      try {
        axios
          .get(VERIFY_EMAIL_TOKEN, {
            params: {
              token,
            },
          })
          .then(result => {
            if (result.data.success) {
              setTokenStatus(true);
              setLoaderStatus(true);
            } else {
              setLoaderStatus(false);
            }
          });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else {
      setTokenStatus(true);
      setLoaderStatus(false);
    }
  }, [token, navigate]);

  if (loaderStatus && !tokenStatus) {
    return <LoadingScreen />;
  }
  if (tokenStatus && loaderStatus) {
    return (
      <Page title={t('verify')} sx={{ height: 1 }}>
        <RootStyle>
          <LogoOnlyLayout />
          <Container>
            <Box
              sx={{
                maxWidth: '100%',
                mx: 'auto',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                sx={{
                  width: '245px',
                  height: '245px',
                  padding: '20px',
                  objectFit: 'contain',
                  margin: '0 auto',
                }}
                visibleByDefault
                disabledEffect
                src={Correct}
                alt='Invalid'
              />
              <Typography variant='h4'>
                {t('email_verified_successfully')}
              </Typography>
              <Button
                size='large'
                variant='contained'
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 3 }}
              >
                {t('go_to_login')}
              </Button>
            </Box>
          </Container>
        </RootStyle>
      </Page>
    );
  }
  if (tokenStatus && !loaderStatus) {
    return (
      <Page title={t('verify')} sx={{ height: 1 }}>
        <RootStyle>
          <LogoOnlyLayout />
          <Container>
            <Box
              sx={{
                maxWidth: '100%',
                mx: 'auto',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                sx={{
                  width: '245px',
                  height: '245px',
                  objectFit: 'contain',
                  margin: '0 auto',
                }}
                visibleByDefault
                disabledEffect
                src={Email}
                alt='Invalid'
              />
              <Typography variant='h4'>
                {t('please_check_your_email')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {t('emailed_a_link_to_verify')}
              </Typography>

              <Typography variant='body2' align='center'>
                {t('dont_have_email')} &nbsp;
                <Button
                  size='small'
                  component={RouterLink}
                  to={PATH_AUTH.resendEmail}
                >
                  {t('resend_email')}
                </Button>
              </Typography>
              <Button
                size='large'
                variant='contained'
                component={RouterLink}
                to={PATH_AUTH.register}
                startIcon={
                  <Iconify
                    icon={'eva:arrow-ios-back-fill'}
                    width={15}
                    height={15}
                  />
                }
                sx={{ mt: 3 }}
              >
                {t('back_to_register')}
              </Button>
            </Box>
          </Container>
        </RootStyle>
      </Page>
    );
  }
  if (!tokenStatus && !loaderStatus) {
    return (
      <Invalid
        invalidMessage={t('email_not_verified_try_again')}
        detailedMessage={t('recheck_your_email_or_register_again')}
      />
    );
  }

  return <LoadingScreen />;
}
