import { TableRow, TableCell, Typography, MenuItem, Link } from '@mui/material';
import Iconify from 'components/Iconify';
import Label from 'components/Label';
import { TableMoreMenu } from 'components/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerListingObjectType } from '../PointsRewardsType';

type Props = {
  row: CustomerListingObjectType;
  onEditRow: VoidFunction;
};

export default function CustomerTableRow({ row, onEditRow }: Props) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    username,
    activities_done,
    point_balance,
    total_claimed_rewards,
    status,
  } = row;

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>
          <Link
            sx={{ cursor: 'pointer' }}
            variant='subtitle2'
            color='#2196F3'
            onClick={() => onEditRow()}
          >
            {username}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{activities_done}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{point_balance.available_points}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{total_claimed_rewards}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>
          <Label
            color={(status === 'active' && 'success') || 'default'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </Typography>
      </TableCell>
      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                {t('edit')}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
