import {
  useAppSettings,
  useHubspotOwner,
  useWooCommerceStores,
} from 'api/ApiHooks/PointsRewardsApiHooks';
import Page from 'components/Page';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { t } from 'i18next';
import { POINTSANDREWARD } from 'routes/paths';
import AddStore from '../../AddStore/AddStore';
import GeneralSettingsForm from './GeneralSettingsForm';

export default function General() {
  const portalId = usePortalId();
  const appSettingsData = useAppSettings(Number(portalId), POINTSANDREWARD);
  const hubspotOwnerData = useHubspotOwner(Number(portalId));
  const stores = useWooCommerceStores(Number(portalId), POINTSANDREWARD);

  if (
    hubspotOwnerData.data === undefined ||
    appSettingsData.data === undefined ||
    stores.data === undefined
  ) {
    return <PartialPageSpinner />;
  }
  if (!stores.data.data.isConnected) {
    return <AddStore />;
  }
  const hubspotOwners = hubspotOwnerData.data?.data;
  const appSettings = appSettingsData.data?.data;

  return (
    <Page
      sx={{ textTransform: 'capitalize' }}
      title={
        t('general').charAt(0).toUpperCase().charAt(0).toUpperCase() +
        t('general').slice(1)
      }
      className='px-6'
    >
      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <GeneralSettingsForm
          appSettings={appSettings}
          hubspotOwners={hubspotOwners}
        />
        <div className='sm:basis-3/6'></div>
      </div>
    </Page>
  );
}
