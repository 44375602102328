import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import usePortalId from 'hooks/portal/usePortalId';
import axiosInstance from 'utils/axios';
import { UPDATE_CUSTOMER_BIRTH_DATE_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { isApiError } from 'utils/apiFunctions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
  storeId: string;
  customerId: string;
  open: boolean;
  title: string;
  birthDate: Date;
  updateDate: (date: Date) => void;
  handleOpenCloseConfirmDialogue: () => void;
}
type Props = OwnProps;

const DateDialogBox: React.FC<Props> = ({
  storeId,
  customerId,
  open,
  title,
  birthDate,
  updateDate,
  handleOpenCloseConfirmDialogue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeDate, setActiveDate] = React.useState<Date | null>(birthDate);

  const updatePoints = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
        day: activeDate?.getDate(),
        month: activeDate?.getMonth()
          ? activeDate?.getMonth() + 1
          : birthDate?.getMonth() + 1,
        year: activeDate?.getFullYear(),
      };

      const result = await axiosInstance.post(
        UPDATE_CUSTOMER_BIRTH_DATE_API_URL + '/' + customerId,
        params
      );

      if (result.data.success) {
        updateDate(activeDate!);
        enqueueSnackbar(result.data.message, { variant: 'info' });
        handleOpenCloseConfirmDialogue();
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    updatePoints();
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          onClick={handleOpenCloseConfirmDialogue}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DatePicker
          mask=''
          disableFuture
          inputFormat='yyyy/MM/dd'
          renderInput={props => (
            <TextField
              {...props}
              fullWidth
              sx={{
                mt: '25px',
              }}
            />
          )}
          label={t('birth_date')}
          value={activeDate}
          onChange={setActiveDate}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pt: '0px !important' }}>
        <LoadingButton
          loading={isLoading}
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('activate_gateway')}
          onClick={() => handleSubmit()}
        >
          {t('change')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DateDialogBox;
