import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HubSpotUserDetails } from '../../DiscountCouponsType';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useActiveApps from 'hooks/apps/useActiveApps';
import { AUTOMATEDCOUPON, PATH_PAGE } from 'routes/paths';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import {
  useAppDetail,
  useHubSpotUser,
} from 'api/ApiHooks/DiscountCouponsApiHooks';
import useResponsive from 'hooks/useResponsive';
import HubSpotUserTable from '../table/HubSpotUserTable';
import { useAppListing, usePortalListing } from 'api/ApiHooks/GeneralHooks';
import { installApp } from 'components/AppCard';

const ROWS_PER_PAGE = 10;

export default function HubSpotUserListing() {
  const portalId = usePortalId();
  useIfValidityExpired(AUTOMATEDCOUPON);
  const appDetailsData = useAppDetail(Number(portalId), AUTOMATEDCOUPON);
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslation();
  const { data: appData } = useAppListing(Number(portalId));
  const TABLE_HEAD = [
    {
      id: 'couponCode',
      label: t('hubspot') + ' ' + t('user_id'),
      align: 'left',
    },
    { id: 'rulename', label: t('email'), align: 'left' },
    { id: 'expiresAt', label: t('name') },
    { id: 'created_at', label: t('action') },
  ];

  const [skip, setSkip] = useState(0);
  const [lastLimit, setLastLimit] = useState(10);
  const [accessedUser, setAccessedUser] = useState<number[]>([]);
  const activeApps = useActiveApps(portalId);
  const { data: hubSpotUserResponse, isFetching } = useHubSpotUser(
    Number(portalId)
  );
  const { data: portalData } = usePortalListing();

  useEffect(() => {
    if (hubSpotUserResponse?.data !== undefined) {
      const arr: any = [];
      hubSpotUserResponse?.data.forEach(item => {
        if (item.is_coupon_enabled) {
          arr.push(item.userId);
        }
      });

      setAccessedUser(arr);
    }
  }, [hubSpotUserResponse]);
  const navigate = useNavigate();

  if (!activeApps.includes('automatedcoupon')) {
    navigate(PATH_PAGE.page404);
  }
  if (
    !hubSpotUserResponse ||
    appDetailsData === undefined ||
    portalData === undefined ||
    appData === undefined
  ) {
    return <PartialPageSpinner />;
  }

  const getDateDifferentInDays = (time: number) => {
    const date1 = new Date(time);
    const date2 = new Date(1704173610000);

    const diffInTime = date1.getTime() - date2.getTime();

    const diffInDays = diffInTime / (1000 * 3600 * 24);

    const currentDifferenceInDays = Math.floor(diffInDays);

    if (currentDifferenceInDays <= 0) {
      return false;
    } else return true;
  };

  const portalsData = portalData?.data;

  const activePortal = portalsData.filter(function (portal: any) {
    return portal.portal_id.toString() === portalId;
  });

  const totalPage = Math.ceil(
    hubSpotUserResponse?.data?.length / ROWS_PER_PAGE
  );

  let contentLoad: React.ReactNode = {};

  if (isFetching) {
    contentLoad = (
      <TableBody sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </TableBody>
    );
  }

  const handleCouponAccess = (id: number) => {
    let arr: number[] = [];
    if (accessedUser.includes(id)) {
      arr = accessedUser.filter(item => item !== id);
    } else {
      arr = [...accessedUser, id];
    }

    setAccessedUser(arr);
  };

  const mapPages = (data: HubSpotUserDetails[]) => {
    const rows: React.ReactNode[] = [];
    for (let i = skip; i < lastLimit && i < data.length; i++) {
      rows.push(
        <HubSpotUserTable
          key={i}
          row={data[i]}
          handleCouponAccess={handleCouponAccess}
          accessedUsers={accessedUser}
        />
      );
    }

    return rows;
  };

  if (!isFetching) {
    contentLoad = hubSpotUserResponse.data.length ? (
      <TableBody>{mapPages(hubSpotUserResponse.data)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('hubSpotUser')} />
      </>
    );
  }

  return (
    <Page title={t('coupon_listing')}>
      <Container>
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ mt: 2, width: '100%' }}>
            {activePortal.length > 0 &&
            getDateDifferentInDays(Number(activePortal[0]?.updated_at)) ===
              false &&
            appData?.data[0].name === 'automatedcoupon' ? (
              <Alert severity='info'>
                <span>{t('please_reauthorize')}</span>{' '}
                <Button
                  onClick={() => installApp(appData?.data[0].connection_url)}
                >
                  {t('reauthorize_now')}
                </Button>
              </Alert>
            ) : (
              <Alert severity='info'>
                {t('manage_who_can_create_coupons')}
              </Alert>
            )}
          </Stack>
        </Box>
        <Card sx={{ height: '820px' }}>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                <TableHeadCustom headLabel={TABLE_HEAD} />
                {contentLoad}
              </Table>
              <Card
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingBottom: '25px',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                {hubSpotUserResponse && totalPage > 1 ? (
                  <Pagination
                    color='primary'
                    variant='outlined'
                    count={totalPage}
                    page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                    onChange={(e, pageNo) => {
                      setSkip((pageNo - 1) * ROWS_PER_PAGE);
                      setLastLimit(pageNo * ROWS_PER_PAGE);
                    }}
                  />
                ) : null}
              </Card>
            </TableContainer>
          </Scrollbar>
          {!smUp &&
          !appDetailsData.data?.data.payment_plan.custom_plan &&
          appDetailsData.data?.data.payment_plan.max_coupon_count &&
          appDetailsData.data?.data.payment_plan.current_month_coupons ? (
            <Stack sx={{ padding: '20px', float: 'right' }}>
              <Typography variant='button'>
                {t('total_coupons')}:{' '}
                {appDetailsData.data?.data.payment_plan.max_coupon_count}
              </Typography>
              <Typography variant='button'>
                {t('coupons_used')} :{' '}
                {appDetailsData.data?.data.payment_plan.current_month_coupons}
              </Typography>
            </Stack>
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
