import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs, Typography, Grid } from '@mui/material';
import {
  POINTS_AND_REWARDS_NAVIGATION,
  PATH_USER,
  POINTSANDREWARD,
} from 'routes/paths';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import usePortalId from 'hooks/portal/usePortalId';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RulesListing from './Rules/RulesListing';
import RewardsListing from './Rewards/RewardsListing';
import Image from 'components/Image';
import Reward from 'assets/images/rewards.svg';
import { LoadingButton } from '@mui/lab';
import useResponsive from 'hooks/useResponsive';
import CustomerListing from './Customer/CustomerListing';
import Notices from 'containers/common/Notices/Notices';

export default function PointsAndRewards() {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const smUp = useResponsive('up', 'sm');

  const ACCOUNT_TABS = [
    {
      value: 'rules',
      icon: '',
      component: <RulesListing />,
    },
    {
      value: 'rewards',
      icon: '',
      component: <RewardsListing />,
    },
    {
      value: 'customer',
      icon: '',
      component: <CustomerListing />,
    },
  ];

  const getTitle = () => {
    switch (tab) {
      case 'rules':
        return t('rules') + ' ' + t('listing');
      case 'rewards':
        return t('rewards') + ' ' + t('listing');
      default:
        return t('points_and_rewards');
    }
  };

  return (
    <Page title={getTitle()}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Image
              sx={{
                width: '36px',
                height: '36px',
                display: 'inline-block',
              }}
              visibleByDefault
              disabledEffect
              src={Reward}
              alt={t('points_and_rewards')}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant='h4' gutterBottom>
              {t('points_and_rewards')}
            </Typography>
          </Grid>
          <Grid item xs={smUp ? 6 : 5}>
            <LoadingButton
              sx={{ verticalAlign: 'text-bottom', float: 'right' }}
              variant='contained'
              loading={false}
              onClick={() =>
                navigate(PATH_USER.general.accountSettings(portalId, 'billing'))
              }
            >
              {t('manage_app')}
            </LoadingButton>
          </Grid>
        </Grid>
        <HeaderBreadcrumbs
          heading={''}
          links={[
            {
              name: t('all_apps'),
              href: PATH_USER.appListing(portalId),
            },
            {
              name: t('points_and_rewards'),
              href: '',
            },
            {
              name: t(tab!),
              href: '',
            },
          ]}
        />
        <Notices appName={POINTSANDREWARD} />

        <Box sx={{ mb: 5 }} />

        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={tab}
          onChange={(e, tabIndex) =>
            navigate(
              POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
                portalId,
                tabIndex
              )
            )
          }
        >
          {ACCOUNT_TABS.map(tabVal => {
            const isMatched = tabVal.value === tab;

            return (
              isMatched && (
                <Tab
                  disableRipple
                  key={t(tabVal.value)}
                  label={capitalCase(t(tabVal.value))}
                  icon={tabVal.icon}
                  value={tabVal.value}
                />
              )
            );
          })}
        </Tabs>

        {ACCOUNT_TABS.map(tabVal => {
          const isMatched = tabVal.value === tab;

          return isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
