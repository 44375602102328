import {
  Grid,
  Typography,
  Card,
  Box,
  Tabs,
  Tab,
  Container,
  Stack,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Pagination,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { FunctionComponent, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Image from 'components/Image';
import Reward from 'assets/images/rewards.svg';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import axiosInstance from 'utils/axios';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { TableHeadCustom, TableNoData } from 'components/table';
import RewardCouponTableRow from '../../Table/RewardCouponTableRow';
import useActiveApps from 'hooks/apps/useActiveApps';
import {
  PATH_PAGE,
  POINTS_AND_REWARDS_NAVIGATION,
  PATH_USER,
  POINTSANDREWARD,
} from 'routes/paths';
import { useRewardDetails } from 'api/ApiHooks/PointsRewardsApiHooks';
import {
  RewardCouponListingType,
  RewardCouponListingObjType,
} from '../../PointsRewardsType';
import { REWARD_COUPON_LISTING_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import Notices from 'containers/common/Notices/Notices';

const ROWS_PER_PAGE = 10;

type Props = {
  storeId: string;
};

const ManageCodesReward: FunctionComponent<Props> = ({ storeId }) => {
  const { rewardId } = useParams<{
    rewardId: string;
  }>();

  const { t } = useTranslation();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const activeApps = useActiveApps(portalId);
  const [skip, setSkip] = useState(0);

  const TABLE_HEAD = [
    { id: 'date', label: t('date'), align: 'left' },
    { id: 'code', label: t('Code'), align: 'left' },
    { id: 'status', label: t('status'), align: 'left' },
    { id: 'claimed_by', label: t('claimed_by'), align: 'left' },
    { id: 'used_at', label: t('used_at'), align: 'left' },
  ];

  const rewardDetailResponse = useRewardDetails(
    Number(portalId),
    storeId,
    rewardId!
  );
  const rewardDetail = rewardDetailResponse.data?.data;
  const rewardTitle = rewardDetail !== undefined ? rewardDetail.title : '';

  async function fetchData(storeId: string, reward_id: string, skip = 0) {
    const response = await axiosInstance.get<RewardCouponListingType>(
      REWARD_COUPON_LISTING_API_URL,
      {
        params: {
          reward_id,
          storeId,
          portalId,
          skip,
          limit: ROWS_PER_PAGE,
        },
      }
    );

    return response.data;
  }

  const { data: couponResponse, isFetching } =
    useQuery<RewardCouponListingType>(
      [REWARD_COUPON_LISTING_API_URL, skip],
      () => fetchData(storeId, rewardId!, skip as number),
      {
        refetchOnWindowFocus: false,
      }
    );

  if (!activeApps.includes('pointsandreward')) {
    navigate(PATH_PAGE.page404);
  }

  let contentLoad: React.ReactNode = {};

  if (isFetching) {
    contentLoad = (
      <TableBody sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </TableBody>
    );
  }

  const totalPage = Math.ceil(
    couponResponse?.totalCount ? couponResponse?.totalCount / ROWS_PER_PAGE : 0
  );

  const mapPages = (data: RewardCouponListingObjType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(<RewardCouponTableRow key={row._id} row={row} />);
    });

    return rows;
  };

  if (!isFetching) {
    contentLoad =
      couponResponse !== undefined && couponResponse.data.length ? (
        <TableBody>{mapPages(couponResponse.data)}</TableBody>
      ) : (
        <>
          <TableNoData isNotFound={true} Message={t('code')} />
        </>
      );
  }

  return (
    <Page title={t('manage_codes')} className='px-6'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Image
            sx={{
              width: '36px',
              height: '36px',
              display: 'inline-block',
            }}
            visibleByDefault
            disabledEffect
            src={Reward}
            alt={t('points_and_rewards')}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h4' gutterBottom>
            {t('points_and_rewards')}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <LoadingButton
            sx={{ verticalAlign: 'text-bottom', float: 'right' }}
            variant='contained'
            loading={false}
            onClick={() =>
              navigate(PATH_USER.general.accountSettings(portalId, 'billing'))
            }
          >
            {t('manage_app')}
          </LoadingButton>
        </Grid>
      </Grid>
      <HeaderBreadcrumbs
        heading={''}
        links={[
          {
            name: t('all_apps'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('points_and_rewards'),
            href: '',
          },
          {
            name: t('rewards'),
            href: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              'rewards'
            ),
          },
          {
            name: rewardTitle,
            href: POINTS_AND_REWARDS_NAVIGATION.general.editReward(
              portalId,
              rewardId!
            ),
          },
          {
            name: t('manage_code'),
            href: '',
          },
        ]}
      />
      <Notices appName={POINTSANDREWARD} />
      <Box sx={{ mb: 5 }} />

      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        value='rewards'
        onChange={(e, tabIndex) =>
          navigate(
            POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              tabIndex
            )
          )
        }
      >
        <Tab
          disableRipple
          key={t('rewards')}
          label={capitalCase(t('rewards'))}
          icon=''
          value='rewards'
        />
      </Tabs>
      <Container>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <LoadingButton
            sx={{ marginTop: '24px', marginBottom: '15px', float: 'right' }}
            variant='contained'
            onClick={() => navigate(-1)}
            loading={false}
          >
            {t('back')}
          </LoadingButton>
        </Stack>
        <Card sx={{ height: '750px' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800 }} aria-label='simple table'>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              {contentLoad}
            </Table>
            <Card
              sx={{
                display: 'flex',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                paddingBottom: '25px',
                justifyContent: 'center',
                paddingTop: '10px',
              }}
            >
              {couponResponse && totalPage > 1 ? (
                <Pagination
                  color='primary'
                  variant='outlined'
                  count={totalPage}
                  page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                  onChange={(e, pageNo) =>
                    setSkip((pageNo - 1) * ROWS_PER_PAGE)
                  }
                />
              ) : null}
            </Card>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
};

export default ManageCodesReward;
