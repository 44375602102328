import { useWooCommerceStores } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import usePortalId from 'hooks/portal/usePortalId';
import { POINTSANDREWARD } from 'routes/paths';
import AddStore from '../AddStore/AddStore';
import CustomerListingTable from './child/CustomerListingTable';

export default function CustomerListing() {
  useIfValidityExpired(POINTSANDREWARD);
  const portalId = usePortalId();
  const { data } = useWooCommerceStores(Number(portalId), POINTSANDREWARD);
  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  return (
    <div>
      {data.data.isConnected ? (
        <CustomerListingTable storeId={data.data._id} />
      ) : (
        <AddStore />
      )}
    </div>
  );
}
