import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card, InputAdornment, IconButton, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import axiosInstance from 'utils/axios';
import makeFormData from 'utils/getFormFromData';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import Iconify from 'components/Iconify';
import { useTranslation } from 'react-i18next';
import { RESET_PASSWORD_API_URL } from 'api/ApiConstants/GeneralConstants';
type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountChangePassword() {
  const { t } = useTranslation();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('old_password_is_required')),
    newPassword: Yup.string()
      .min(8, t('password_must_be_eight_char'))
      .required(t('new_password_is_required')),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      t('passwords_must_match')
    ),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axiosInstance.post(
        RESET_PASSWORD_API_URL,
        makeFormData({
          old_password: data.oldPassword,
          new_password: data.newPassword,
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        logout();
      }
    } catch (error: any) {
      reset();
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3, mb: 3 }}>
                <RHFTextField
                  id='oldPassword'
                  name='oldPassword'
                  label={t('old_password') + '*'}
                  type={showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          edge='end'
                        >
                          <Iconify
                            icon={
                              showOldPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  id='newPassword'
                  name='newPassword'
                  label={t('new_password') + '*'}
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge='end'
                        >
                          <Iconify
                            icon={
                              showNewPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  id='confirmNewPassword'
                  name='confirmNewPassword'
                  label={t('confirm_new_password') + '*'}
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowConfirmNewPassword(!showConfirmNewPassword)
                          }
                          edge='end'
                        >
                          <Iconify
                            icon={
                              showConfirmNewPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Card>
  );
}
