function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
export const AUTOMATEDCOUPON = 'automatedcoupon';
export const POINTSANDREWARD = 'pointsandreward';
const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';
const ROOTS_FORMPAY_HOME = '/formpay';
const DISCOUNT_COUPONS_HOME = '/automated-coupons';
const POINTS_AND_REWARDS_HOME = '/loyalty-reward-points';

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact:
    'https://makewebbetter.com/contact-us/?utm_source=mwbappsm&utm_medium=mwb&utm_campaign=CTA',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password'),
  resendEmail: path(ROOTS_AUTH, '/resend-mail'),
};

export const PATH_USER = {
  root: ROOTS_USER,
  appListing: (portalId: string) => '/' + portalId + path(ROOTS_USER, '/app'),
  portals: path(ROOTS_USER, '/portals'),
  forgotReset: path(ROOTS_USER, '/forgotreset'),
  profile: path(ROOTS_USER, '/profile'),
  general: {
    accountSettings: (portalId: string, tab: string) =>
      '/' + portalId + path(ROOTS_USER, '/settings/' + tab),
  },
  payments: {
    payments: (portalId: string, appName: string) =>
      '/' + portalId + path(ROOTS_USER, '/' + appName + '/payments'),
  },
  connectToHubspot: path(ROOTS_USER, '/connect-to-hubspot'),
};

export const ROOT_NAVIGATION = {
  root: ROOTS_FORMPAY_HOME,
  general: {
    home: (portalId: string) =>
      '/' + portalId + path(ROOTS_FORMPAY_HOME, '/app'),
    forms: (portalId: string) => '/' + portalId + '/forms/list',
    formSubmission: (portalId: string) =>
      '/' + portalId + '/formsubmission/list',
    transactions: (portalId: string) => '/' + portalId + '/transactions/list',
    gateways: (portalId: string) =>
      '/' + portalId + path(ROOTS_FORMPAY_HOME, '/gateways'),
    gatewayList: (portalId: string) => '/' + portalId + '/gateways',
    gatewayEdit: (portalId: string, gatewayId: string) =>
      '/' + portalId + path(ROOTS_FORMPAY_HOME, '/gateways/edit/' + gatewayId),
    formEdit: (portalId: string, formId: string) =>
      '/' + portalId + path(ROOTS_FORMPAY_HOME, '/forms/edit/' + formId),
  },
};

export const DISCOUNT_COUPONS_NAVIGATION = {
  root: DISCOUNT_COUPONS_HOME,
  general: {
    couponStoreListingTabLinks: (portalId: string, tabIndex: string) =>
      '/' + portalId + path(DISCOUNT_COUPONS_HOME, '/' + tabIndex),
    createStore: (portalId: string) =>
      '/' + portalId + path(DISCOUNT_COUPONS_HOME, '/create-store'),
    createCoupon: (portalId: string) =>
      '/' + portalId + path(DISCOUNT_COUPONS_HOME, '/create-coupon'),
    editStore: (portalId: string, storeId: number) =>
      '/' + portalId + path(DISCOUNT_COUPONS_HOME, '/store/edit/' + storeId),
    editCoupon: (portalId: string, couponId: string) =>
      '/' + portalId + path(DISCOUNT_COUPONS_HOME, '/coupon/edit/' + couponId),
  },
};

export const POINTS_AND_REWARDS_NAVIGATION = {
  root: POINTS_AND_REWARDS_HOME,
  general: {
    createStore: (portalId: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/settings/store-setup'),
    pointsRewardsListingTabLinks: (portalId: string, tabIndex: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/' + tabIndex),
    createRule: (portalId: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/rules/create'),
    editRule: (portalId: string, ruleId: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/rules/edit/' + ruleId),
    createReward: (portalId: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/rewards/create'),
    editReward: (portalId: string, rewardId: string) =>
      '/' +
      portalId +
      path(POINTS_AND_REWARDS_HOME, '/rewards/edit/' + rewardId),
    manageCodesReward: (portalId: string, rewardId: string) =>
      '/' +
      portalId +
      path(POINTS_AND_REWARDS_HOME, '/rewards/manage/' + rewardId),
    editCustomer: (portalId: string, customerId: string) =>
      '/' +
      portalId +
      path(POINTS_AND_REWARDS_HOME, '/customer/edit/' + customerId),
    settings: (portalId: string, tabIndex: string) =>
      '/' + portalId + path(POINTS_AND_REWARDS_HOME, '/settings/' + tabIndex),
  },
};

export const SUPPORT_URL =
  'https://makewebbetter.com/contact-us/?utm_source=mwbappsm&utm_medium=mwb&utm_campaign=CTA';

export const TERMS_AND_CONDITIONS_URL =
  'https://makewebbetter.com/terms-and-conditions/?utm_source=mwbapps&utm_medium=mwb&utm_campaign=CTA';

export const PRIVACY_POLICY_URL =
  'https://makewebbetter.com/privacy-policy/?utm_source=mwbapps&utm_medium=mwb&utm_campaign=CTA';

export const SCHEDULE_NOW_MEETING_URL =
  'https://meetings.hubspot.com/makewebbetter/free-hubspot-consultation?utm_source=mwbapps&utm_medium=mwb&utm_campaign=CTA';

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const TUTORIAL_URL =
  'https://docs.makewebbetter.com/formpay-hubspot-integration/?utm_source=app&utm_medium=mwb&utm_campaign=formpay';

export const MANAGE_HUBSPOT_URL = (portalId: string) =>
  'https://app.hubspot.com/forms/' + portalId;
