import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  PATH_USER,
} from 'routes/paths';
import CouponForm from './form/CouponForm';

export default function CreateCoupon() {
  useIfValidityExpired(AUTOMATEDCOUPON);
  const { t } = useTranslation();
  const portalId = usePortalId();

  return (
    <Page title={t('create_coupon_rule')} className='px-6'>
      <HeaderBreadcrumbs
        heading={t('coupon_rules')}
        links={[
          {
            name: t('app_listing'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('coupons'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
              portalId,
              'coupons'
            ),
          },
          {
            name: t('add_coupon_rule'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.createCoupon(portalId),
          },
        ]}
      />
      <Typography variant='subtitle1'>{t('create_new_coupon_rule')}</Typography>

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-3/6 py-5'>
          <CouponForm type='add' />
        </div>
        <div className='sm:basis-3/6'>
          <Typography variant='h5'>{t('about_discount_coupon')}</Typography>
          <div className='text-gray-500'>{t('coupon_content_line_one')}</div>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_two')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_three')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_four')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_five')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('coupon_content_line_four')}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <div className='text-gray-500'>{t('coupon_content_line_seven')}</div>
          <div className='mt-[20px] flex justify-center'>
            {sessionStorage.getItem('automatedDocLink') && (
              <Button
                variant='contained'
                target='_blank'
                href={sessionStorage.getItem('automatedDocLink') || ''}
              >
                {t('documentation')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}
