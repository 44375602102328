import { TableRow, TableCell, Typography, MenuItem } from '@mui/material';
import { fDate } from 'utils/formatTime';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CouponRuleType } from '../../DiscountCouponsType';

type Props = {
  row: CouponRuleType;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CouponRuleTableRow({
  row,
  onEditRow,
  onDeleteRow,
}: Props) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { couponName, discountType, discountValue, created_at, couponCount } =
    row;

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>{couponName}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{discountType}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{discountValue}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{fDate(Number(created_at))}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{couponCount}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography noWrap>
          {row?.successRate ? row?.successRate + '%' : '--'}
        </Typography>
      </TableCell> */}
      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {t('delete')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                {t('edit')}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
