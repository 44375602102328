// @mui
import PartialPageSpinner from 'components/PartialPageSpinner';
import {
  Grid,
  Card,
  Table,
  Typography,
  Stack,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Button,
  Link,
} from '@mui/material';
import { TableEmptyRows, TableNoData } from 'components/table';
import useTable, { emptyRows } from 'hooks/useTable';
import usePortalId from 'hooks/portal/usePortalId';
import { fDate } from 'utils/formatTime';
import AccountBillingTableRow from 'containers/common/user/account/child/AccountBiilingTableRow';
import { useSnackbar } from 'notistack';
import axiosInstance from 'utils/axios';
import { t } from 'i18next';
import { PATH_USER } from 'routes/paths';
import {
  useGetAllAppsWithBillingDetails,
  usePaymentSummary,
  usePortalAppPayment,
} from 'api/ApiHooks/GeneralHooks';
import Image from 'components/Image';
import { AppAppsWithBillingDetails } from 'containers/common/payments/PaymentType';
import { HOST_API } from 'config';
import { BILLING_CANCEL_SUBSCRIPTION_API_URL } from 'api/ApiConstants/GeneralConstants';
import useResponsive from 'hooks/useResponsive';
import ConfirmCancelSubscription from './child/ConfirmCancelSubscription';
import { useState } from 'react';

export default function AccountBilling() {
  const smUp = useResponsive('up', 'sm');
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [selectedAppName, setSelectedAppName] = useState<string>();
  const { dense, page, rowsPerPage } = useTable({
    defaultOrderBy: 'createdAt',
  });
  const portalId = usePortalId();
  const { enqueueSnackbar } = useSnackbar();
  const allAppsWithBillingDetails = useGetAllAppsWithBillingDetails(
    Number(portalId)
  );
  const paymentSummary = usePaymentSummary(Number(portalId));
  const portalAppPayment = usePortalAppPayment(Number(portalId));

  if (
    allAppsWithBillingDetails.data === undefined ||
    paymentSummary.data === undefined ||
    portalAppPayment.data === undefined
  ) {
    return <PartialPageSpinner />;
  }
  const allAppsWithBillingDetailsData = allAppsWithBillingDetails.data.data;
  const paymentSummaryData = paymentSummary.data.data;
  const portalAppPaymentData = portalAppPayment.data.data;
  const isNotFound = !portalAppPaymentData.length;

  const denseHeight = dense ? 60 : 80;

  const cancelSubscription = async (app_name: string) => {
    try {
      const response = await axiosInstance.delete(
        `${BILLING_CANCEL_SUBSCRIPTION_API_URL}?portal_id=${portalId}&app_name=${app_name}`
      );
      setConfirmDialogueOpen(!confirmDialogueOpen);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      window.location.reload();
    } catch (error: any) {
      setConfirmDialogueOpen(!confirmDialogueOpen);
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  };

  const getValidityString = (app: AppAppsWithBillingDetails) => {
    if (app.payment_plan.type === 'paid' && app.is_subscribed) {
      return (
        t('next_payment_date') + ' ' + fDate(Number(app.payment_plan.validity))
      );
    }
    if (app.payment_plan.type === 'trial') {
      return (
        t('trial_expires_on') + ' ' + fDate(Number(app.payment_plan.validity))
      );
    }
  };

  const confirmDialogueOpenClose = (appName?: string) => {
    setConfirmDialogueOpen(!confirmDialogueOpen);
    if (appName) {
      setSelectedAppName(appName);
    }
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            <Card sx={{ p: 3, backgroundColor: '#F4F6F8' }}>
              <Typography
                variant='overline'
                sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
              >
                {t('summary')}
              </Typography>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant='h4'>{t('total_amount')}</Typography>
                <Typography variant='h3'>
                  ${paymentSummaryData.total_amount}
                  <span style={{ color: '#637381' }}>
                    <sub>/{t('month')}</sub>
                  </span>
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '50px',
                  paddingBottom: '50px',
                }}
              >
                <Typography variant='h4'>{t('apps')}</Typography>
                <Typography variant='h4'>
                  {paymentSummaryData.active_app_count}
                </Typography>
              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Typography variant='h6' sx={{ mb: 3, display: 'block' }}>
                {t('apps')}
              </Typography>
              {allAppsWithBillingDetailsData.length &&
                allAppsWithBillingDetailsData.map(app => {
                  return (
                    <Card sx={{ p: 3, m: 2 }}>
                      <Stack direction='row' alignItems='center' spacing={2}>
                        <Box
                          sx={{
                            width: 48,
                            height: 48,
                            flexShrink: 0,
                            display: 'flex',
                            borderRadius: 1.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'background.neutral',
                          }}
                        >
                          <Image
                            sx={{
                              width: '24px',
                              height: '24px',
                              objectFit: 'contain',
                              margin: '0 auto',
                            }}
                            visibleByDefault
                            disabledEffect
                            src={HOST_API + '/' + app.images.icon}
                            alt={app.app_name}
                          />
                        </Box>
                        <Box sx={{ flexGrow: 1, minWidth: 160 }}>
                          <Typography
                            variant='h5'
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {app.title}
                          </Typography>
                          <Typography
                            sx={{ display: 'block', color: 'text.secondary' }}
                          >
                            {getValidityString(app)}
                            {(app.is_active &&
                              !app.is_subscribed &&
                              app.payment_plan.type === 'trial') ||
                            (app.is_active && !app.is_subscribed) ? (
                              <Typography variant='body2'>
                                <Link
                                  target='_blank'
                                  variant='body2'
                                  href={PATH_USER.payments.payments(
                                    portalId,
                                    app.app_name
                                  )}
                                >
                                  {t('subscribe_now')}
                                </Link>
                              </Typography>
                            ) : app.payment_plan.subscription.plan !==
                              'custom_plan' ? (
                              <Typography variant='body2'>
                                <Link
                                  target='_blank'
                                  variant='body2'
                                  href={PATH_USER.payments.payments(
                                    portalId,
                                    app.app_name
                                  )}
                                >
                                  {t('change_plan')}
                                </Link>
                              </Typography>
                            ) : null}
                          </Typography>
                          {app.is_active && app.is_subscribed && !smUp ? (
                            <Stack sx={{ paddingTop: '20px' }}>
                              <Typography variant='h4'>
                                $
                                {`${app.payment_plan.subscription.amount}/${t(
                                  'month'
                                )}`}
                              </Typography>
                              {app.payment_plan.subscription.plan ? (
                                <Stack>
                                  <Button
                                    variant='contained'
                                    onClick={() =>
                                      confirmDialogueOpenClose(app.app_name)
                                    }
                                  >
                                    {t('cancel_subscription')}
                                  </Button>
                                </Stack>
                              ) : null}
                            </Stack>
                          ) : null}
                        </Box>
                        {app.is_active && app.is_subscribed && smUp ? (
                          <>
                            <Typography variant='h4'>
                              $
                              {`${app.payment_plan.subscription.amount}/${t(
                                'month'
                              )}`}
                            </Typography>
                            {app.payment_plan.subscription.plan !==
                            'custom_plan' ? (
                              <Stack>
                                <Button
                                  variant='contained'
                                  onClick={() =>
                                    confirmDialogueOpenClose(app.app_name)
                                  }
                                >
                                  {t('cancel_subscription')}
                                </Button>
                              </Stack>
                            ) : null}
                          </>
                        ) : null}
                      </Stack>
                    </Card>
                  );
                })}
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ maxHeight: '760px', overflow: 'auto' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <Typography sx={{ p: 2 }} variant='h5'>
                    {t('transaction_history')}
                  </Typography>
                  {portalAppPaymentData.map((data, index) => {
                    return <AccountBillingTableRow key={data._id} row={data} />;
                  })}
                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      portalAppPaymentData.length
                    )}
                  />
                  <TableNoData isNotFound={isNotFound} Message={'Invoice'} />
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
      <ConfirmCancelSubscription
        open={confirmDialogueOpen}
        handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
        cancelSubscription={() => cancelSubscription(selectedAppName!)}
      />
    </>
  );
}
