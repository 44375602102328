import {
  useAppSettings,
  useWooCommerceStores,
} from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import usePortalId from 'hooks/portal/usePortalId';
import { useParams } from 'react-router-dom';
import { POINTSANDREWARD } from 'routes/paths';
import AddStore from '../AddStore/AddStore';
import CustomerForm from './child/CustomerForm';

export default function EditCustomer() {
  useIfValidityExpired(POINTSANDREWARD);
  const portalId = usePortalId();
  const appSettingsData = useAppSettings(Number(portalId), POINTSANDREWARD);

  const { customerId } = useParams<{
    customerId: string;
  }>();
  const { data } = useWooCommerceStores(Number(portalId), POINTSANDREWARD);
  if (data === undefined || appSettingsData.data === undefined) {
    return <PartialPageSpinner />;
  }

  return (
    <div>
      {data.data.isConnected ? (
        <CustomerForm
          currency={
            appSettingsData.data.data.appSettings.store_setting.store_currency
              .symbol
          }
          storeId={data.data._id}
          customerId={customerId!}
        />
      ) : (
        <AddStore />
      )}
    </div>
  );
}
