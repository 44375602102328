import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import LogoOnlyLayout from '../../../layouts/LogoOnlyLayout';
import { PATH_AUTH } from '../../../routes/paths';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import ResendVerificationMailForm from 'containers/common/auth/child/ResendVerificationMailForm/ResendVerificatioinMailForm';
import { useTranslation } from 'react-i18next';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

export default function ResendVerificationMail() {
  const { t } = useTranslation();

  return (
    <Page title={t('verify')} sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 500, mx: 'auto' }}>
            <Button
              size='small'
              component={RouterLink}
              to={PATH_AUTH.login}
              startIcon={
                <Iconify
                  icon={'eva:arrow-ios-back-fill'}
                  width={20}
                  height={20}
                />
              }
              sx={{ mb: 3 }}
            >
              {t('back')}
            </Button>

            <Typography variant='h4' paragraph>
              {t('resend_verification')}
            </Typography>

            <ResendVerificationMailForm />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
