import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import usePortalId from 'hooks/portal/usePortalId';
import axiosInstance from 'utils/axios';
import { BLOCK_CUSTOMER_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { isApiError } from 'utils/apiFunctions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
  storeId: string;
  customerId: string;
  open: boolean;
  type: 'block' | 'unblock';
  title: string;
  content: string;
  handleOpenCloseConfirmDialogue: () => void;
  toggleBlockType: () => void;
}
type Props = OwnProps;

const BlockDialogBox: React.FC<Props> = ({
  storeId,
  customerId,
  open,
  type,
  title,
  content,
  handleOpenCloseConfirmDialogue,
  toggleBlockType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [isLoading, setIsLoading] = React.useState(false);
  console.log(type);
  const updatePoints = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
        status: type === 'unblock' ? 'blocked' : 'active',
      };

      const result = await axiosInstance.post(
        BLOCK_CUSTOMER_API_URL + '/' + customerId,
        params
      );

      if (result.data.success) {
        toggleBlockType();
        enqueueSnackbar(result.data.message, {
          variant: 'info',
        });
        handleOpenCloseConfirmDialogue();
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    updatePoints();
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          onClick={handleOpenCloseConfirmDialogue}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: '14px', paddingTop: '25px' }} gutterBottom>
          {content}
        </Typography>
        {type !== 'block' && (
          <Stack
            sx={{
              borderLeft: '3px solid #FF4842',
              fontSize: '14px',
              mt: '15px',
              pl: '15px',
            }}
          >
            <Typography component='span' variant='body2'>
              {t('blocked_customer_will')}
              <strong> {t('no_loger_earn_new_points')} </strong>
              {t('and_will')}
              <strong> {t('not_able_to_use_points_they_earned')}</strong>
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant='outlined'
          size='large'
          sx={{
            background: '#DFE3E8',
            border: '1px solid #DFE3E8',
            color: '#212B36',
          }}
          onClick={handleOpenCloseConfirmDialogue}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('activate_gateway')}
          onClick={() => handleSubmit()}
          sx={{ width: '94px' }}
        >
          {t('ok')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default BlockDialogBox;
