import { TableRow, TableCell, Typography, MenuItem } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreType } from '../../DiscountCouponsType';

type Props = {
  row: StoreType;
  onEditRow: VoidFunction;
  onDiconnectRow: VoidFunction;
};

export default function StoreTableRow({
  row,
  onEditRow,
  onDiconnectRow,
}: Props) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { storeUrl, isConnected } = row;

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>{storeUrl}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>
          {isConnected ? t('connected') : t('not_connected')}
        </Typography>
      </TableCell>
      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {isConnected ? (
                <MenuItem
                  onClick={() => {
                    onDiconnectRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  {t('disconnect')}
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                {t('edit')}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
