import { TableRow, TableCell, Typography } from '@mui/material';
import { fDate } from 'utils/formatTime';
import { CouponListingObjType } from '../../DiscountCouponsType';

type Props = {
  row: CouponListingObjType;
};

export default function CouponTableRow({ row }: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { couponCode, rulename, expiresAt, created_at } = row;

  return (
    <TableRow>
      <TableCell>
        <Typography noWrap>{couponCode}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{rulename ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>
          {expiresAt ? fDate(Number(expiresAt)) : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{fDate(Number(created_at))}</Typography>
      </TableCell>
    </TableRow>
  );
}
