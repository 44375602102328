import { Switch, MenuItem, Typography, Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'components/Image';
import Iconify from 'components/Iconify';
import { RewardListingObjType } from '../PointsRewardsType';
import Star from 'assets/images/icons/star.svg';
import StarFade from 'assets/images/icons/star-fade.svg';
import useResponsive from 'hooks/useResponsive';
import MoreMenuButton from 'components/MoreMenuButton';

type Props = {
  row: RewardListingObjType;
  onManageCode: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onChangeSwitch(enabled: boolean): Promise<void>;
};

export default function RewardTableRow({
  row,
  onChangeSwitch,
  onManageCode,
  onEditRow,
  onDeleteRow,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, points_cost, redeemed_rewards_count, is_enabled } = row;

  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [isEnabled, setIsEnabled] = useState(is_enabled);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <Card
      sx={{
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid rgba(145, 158, 171, 0.24)',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: smUp ? 'row' : 'column',
        justifyContent: 'space-between',
        marginBottom: '10px',
        padding: '20px',
      }}
    >
      <Stack
        sx={{ flexBasis: '10%', flexWrap: 'wrap', pb: smUp ? '' : '20px' }}
      >
        <Image
          src={isEnabled ? Star : StarFade}
          sx={{
            width: '30px',
            height: '30px',
            display: 'inline-block',
          }}
          visibleByDefault
          disabledEffect
          alt='Star'
        ></Image>
      </Stack>
      <Stack
        sx={{ flexBasis: '20%', flexDirection: 'row', pb: smUp ? '' : '20px' }}
      >
        <Typography
          variant='subtitle1'
          noWrap
          sx={{ color: isEnabled ? '#212B36' : '#919EAB' }}
        >
          {title}
        </Typography>
      </Stack>
      {smUp ? (
        <>
          <Stack sx={{ flexBasis: '15%', flexDirection: 'row' }}>
            {points_cost && (
              <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  noWrap
                  sx={{ color: isEnabled ? '#212B36' : '#919EAB' }}
                >
                  {points_cost}
                </Typography>
                <Typography variant='caption' noWrap sx={{ color: '#919EAB' }}>
                  {t('cost')}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack sx={{ flexBasis: '15%', flexDirection: 'row' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                sx={{ color: isEnabled ? '#212B36' : '#919EAB' }}
              >
                {redeemed_rewards_count
                  ? String(redeemed_rewards_count).padStart(2, '0')
                  : '00'}{' '}
                times
              </Typography>
              <Typography variant='caption' noWrap sx={{ color: '#919EAB' }}>
                {t('redeemed')}
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack sx={{ flexBasis: '35%', flexDirection: 'row', pb: '20px' }}>
          {points_cost && (
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                sx={{ color: isEnabled ? '#212B36' : '#919EAB' }}
              >
                {points_cost}
              </Typography>
              <Typography variant='caption' noWrap sx={{ color: '#919EAB' }}>
                {t('cost')}
              </Typography>
            </Stack>
          )}
          <Stack sx={{ display: 'flex', flexDirection: 'column', ml: '20px' }}>
            <Typography
              noWrap
              sx={{ color: isEnabled ? '#212B36' : '#919EAB' }}
            >
              {redeemed_rewards_count
                ? String(redeemed_rewards_count).padStart(2, '0')
                : '00'}{' '}
              times
            </Typography>
            <Typography variant='caption' noWrap sx={{ color: '#919EAB' }}>
              {t('redeemed')}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack sx={{ flexBasis: '15%', flexDirection: 'row', pb: '20px' }}>
        <Switch
          checked={isEnabled}
          size='small'
          onChange={e => {
            setIsEnabled(!isEnabled);
            onChangeSwitch(e.target.checked);
          }}
        />
      </Stack>
      <Stack sx={{ flexBasis: '20%', flexDirection: 'row' }}>
        <LoadingButton
          variant='contained'
          onClick={() => onManageCode()}
          loading={false}
        >
          {t('manage_codes')}
        </LoadingButton>
      </Stack>
      <Stack sx={{ flexBasis: '5%', flexDirection: 'row', ml: 0 }}>
        <MoreMenuButton
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          smUp={smUp}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                {t('edit')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {t('delete')}
              </MenuItem>
            </>
          }
        />
      </Stack>
    </Card>
  );
}
