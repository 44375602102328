import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import usePortalId from 'hooks/portal/usePortalId';
import axiosInstance from 'utils/axios';
import { UPDATE_CUSTOMER_POINTS_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { isApiError } from 'utils/apiFunctions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
  CustomerActivity,
  pointDialogBoxType,
} from '../../../PointsRewardsType';

interface OwnProps {
  storeId: string;
  customerId: string;
  open: boolean;
  type: 'add' | 'remove';
  title: string;
  content: string;
  updateActivities: (data: CustomerActivity[], currentPoints: number) => void;
  handleOpenCloseConfirmDialogue: () => void;
}
type Props = OwnProps;

const initialErrorState = {
  points: '',
  reason: '',
};

const PointsDialogBox: React.FC<Props> = ({
  storeId,
  customerId,
  type,
  open,
  title,
  content,
  updateActivities,
  handleOpenCloseConfirmDialogue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [isLoading, setIsLoading] = React.useState(false);
  const defaultValues = {
    points: 0,
    reason: '',
  };
  const [pointsData, setPointsData] = React.useState(defaultValues);
  const [errorMessage, setErrorMessage] = React.useState(initialErrorState);

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setErrorMessage(error => ({
        ...error,
        [event.target.name]: '',
      }));
    }
    setPointsData(pointData => ({
      ...pointData,
      [event.target.name]:
        event.target.name === 'points'
          ? Number(event.target.value)
          : event.target.value,
    }));
  };

  function validatePointsForm(newData: pointDialogBoxType) {
    let flag = true;
    const tempError = { ...errorMessage };

    if (newData.points === 0) {
      flag = false;
      tempError.points = t('points_are_required');
    }

    if (newData.reason.length === 0) {
      flag = false;
      tempError.reason = t('reason_is_required');
    }

    if (flag) {
      setErrorMessage(initialErrorState);

      return true;
    } else {
      setErrorMessage(tempError);

      return false;
    }
  }

  const updatePoints = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
        points: pointsData.points,
        reason: pointsData.reason,
        method: 'added',
      };

      if (type === 'remove') {
        params.method = 'deducted';
      }

      const result = await axiosInstance.post(
        UPDATE_CUSTOMER_POINTS_API_URL + '/' + customerId,
        params
      );

      if (result.data.success) {
        if (type === 'add') {
          enqueueSnackbar(t('points_added_successfully'), { variant: 'info' });
        } else {
          enqueueSnackbar(t('points_removed_successfully'), {
            variant: 'info',
          });
        }
        setPointsData({ ...pointsData, points: 0, reason: '' });

        updateActivities(
          result.data?.data?.activities ?? [],
          result.data?.data?.point_balance?.available_points ?? 0
        );
        handleOpenCloseConfirmDialogue();
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    if (validatePointsForm(pointsData)) {
      updatePoints();
    }
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          onClick={handleOpenCloseConfirmDialogue}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ paddingTop: '25px' }} gutterBottom>
          {content}
        </Typography>
        {type === 'remove' && (
          <Stack
            sx={{
              borderLeft: '3px solid #FF4842',
              fontSize: '14px',
              mt: '15px',
              pl: '15px',
            }}
          >
            <Typography variant='subtitle2' gutterBottom>
              {t('dont_remove_points_for_order_returns_refunds_etc')}
            </Typography>
            {t('removed_by_pointsandreward_automatically')}
          </Stack>
        )}
        <TextField
          id='points'
          name='points'
          defaultValue={pointsData.points}
          label={t('points')}
          sx={{ mt: '25px' }}
          type='number'
          fullWidth
          error={!!errorMessage.points}
          onChange={handleChangeEvent}
          helperText={<span>{errorMessage.points}</span>}
        />
        <TextField
          id='reason'
          name='reason'
          defaultValue={pointsData.reason}
          label={t('reason')}
          sx={{ mt: '25px' }}
          fullWidth
          error={!!errorMessage.reason}
          onChange={handleChangeEvent}
          helperText={<span>{errorMessage.reason}</span>}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton
          loading={isLoading}
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('activate_gateway')}
          onClick={() => handleSubmit()}
        >
          {type === 'add' ? t('give_points') : t('remove_points')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default PointsDialogBox;
