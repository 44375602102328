import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Congratulations from '../../../../../assets/images/congratulations.png';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Image from 'components/Image';

interface OwnProps {
  open: boolean;
  handleOpenCloseConfirmDialogue: () => void;
}
type Props = OwnProps;
const SuccessOnCouponCreation: React.FC<Props> = ({
  open,
  handleOpenCloseConfirmDialogue,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>{t('thank_you_coupon')}</DialogTitle>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <DialogContent>
            <DialogContentText>
              <b>{t('thank_you_coupon')}</b>
            </DialogContentText>
          </DialogContent>
          <Image
            sx={{
              width: '50%',
              objectFit: 'contain',
              margin: '0 auto',
            }}
            visibleByDefault
            disabledEffect
            src={Congratulations}
            alt='login'
          />
        </Box>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent='space-between'
          spacing={2}
        ></Stack>
        <DialogActions>
          <Button onClick={handleOpenCloseConfirmDialogue}>{t('close')}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default SuccessOnCouponCreation;
