import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'hooks/useAuth';
import { FormProvider, RHFTextField } from 'components/hook-form';
import axiosInstance from 'utils/axios';
import 'yup-phone';
import { useTranslation } from 'react-i18next';
import { UPDATE_PROFILE_API_URL } from 'api/ApiConstants/GeneralConstants';
type FormValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: number;
  country: string;
  address: string;
  state: string;
  city: string;
  zipCode: number;
};

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user } = useAuth();
  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(t('first_name_is_required')),
    lastName: Yup.string().trim().required(t('last_name_is_required')),
    email: Yup.string().trim().required(t('email_is_required')),
    phoneNumber: Yup.string()
      .max(10, t('phone_must_contain_10_digit'))
      .min(10, t('phone_must_contain_10_digit'))
      .required(t('phone_number_not_valid')),
    country: Yup.string().trim().required(t('country_is_required')),
    address: Yup.string().trim().required(t('address_is_required')),
    state: Yup.string().trim().required(t('state_is_required')),
    city: Yup.string().trim().required(t('city_is_required')),
    zipCode: Yup.string().trim().required(t('zipcode_is_required')),
  });

  const defaultValues = {
    firstName: user?.firstname || '',
    lastName: user?.lastname || '',
    email: user?.email || '',
    phoneNumber: Number(user?.phone) || undefined,
    country: user?.country || '',
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
    zipCode: Number(user?.zip) || undefined,
  };

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const respo = await axiosInstance.post(`${UPDATE_PROFILE_API_URL}`, {
        firstname: data.firstName,
        lastname: data.lastName,
        email: data.email,
        phone: data.phoneNumber,
        country: data.country,
        state: data.state,
        city: data.city,
        zip: data.zipCode,
        address: data.address,
      });
      if (respo.data.success) {
        enqueueSnackbar(respo.data.message, { variant: 'success' });
      }
    } catch (error) {
      // setTokenStatus(false);
      // setLoaderStatus(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, m: 2 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <RHFTextField name='firstName' label={t('first_name') + '*'} />
              <RHFTextField name='lastName' label={t('last_name') + '*'} />
              <RHFTextField
                disabled
                name='email'
                label={t('email_address') + '*'}
              />
              <RHFTextField
                type='number'
                name='phoneNumber'
                label={t('phone') + '*'}
                inputProps={{ maxLength: 10 }}
              />
            </Box>

            <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3, mb: 3 }}>
              <RHFTextField
                className='field'
                name='address'
                label={t('address') + '*'}
              />
            </Stack>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <RHFTextField name='country' label={t('country') + '*'} />

              <RHFTextField name='state' label={t('state_region') + '*'} />

              <RHFTextField name='city' label={t('city') + '*'} />
              <RHFTextField
                type='number'
                name='zipCode'
                label={t('zip') + '*'}
              />
            </Box>
          </Card>
          <LoadingButton
            fullWidth
            size='large'
            name='submit'
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            {t('save_details')}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
