import _ from 'lodash';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import {
  PATH_PAGE,
  POINTSANDREWARD,
  POINTS_AND_REWARDS_NAVIGATION,
} from 'routes/paths';
import {
  useStoreListing,
  useStoreListingById,
} from 'api/ApiHooks/GeneralHooks';
import { useNavigate } from 'react-router-dom';
import useActiveApps from 'hooks/apps/useActiveApps';
import PartialPageSpinner from 'components/PartialPageSpinner';
import {
  StoreDetailType,
  StoreType,
} from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import { useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardHeader,
  MenuItem,
  Typography,
} from '@mui/material';
import { StoreForm } from '../../child/form/StoreForm';
import { TableMoreMenu } from 'components/table';
import Iconify from 'components/Iconify';
import CopyClipboard from 'components/CopyClipboard';
import { DELETE_STORE_API_URL } from 'api/ApiConstants/DiscountCouponsApiConstants';
import axiosInstance from 'utils/axios';
import { useSnackbar } from 'notistack';

export default function CreateStore() {
  // useIfValidityExpired(POINTSANDREWARD);
  const portalId = usePortalId();
  const { t } = useTranslation();
  const activeApps = useActiveApps(portalId);
  const [storeDetails, setStoreDetails] = useState<StoreType | undefined>();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [apiKey, setApiKey] = useState('');
  const [notification, setNotification] = useState<string | null>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  if (!activeApps.includes(POINTSANDREWARD)) {
    navigate(PATH_PAGE.page404);
  }

  const onSuccess = (data: StoreDetailType) => {
    if ('isConnected' in data?.data && data?.data?.isConnected) {
      setStoreDetails(data?.data);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const onSuccessCallback = (data: StoreDetailType) => {
    if (data?.data) {
      setApiKey(data?.data?.apiKey ?? '');
    }
    if (data?.data?.pluginNotification && !data?.data?.isAppConnected) {
      setNotification(data?.data?.pluginNotification);
    } else {
      setNotification('');
    }
  };

  const { data, isFetching } = useStoreListing(
    {
      portalId: Number(portalId),
      appCode: POINTSANDREWARD,
    },
    true,
    onSuccess
  );

  useStoreListingById(
    {
      portalId: Number(portalId),
      appCode: POINTSANDREWARD,
      storeId: storeDetails?._id ?? 0,
    },
    !!data?.data?._id,
    onSuccessCallback
  );

  const handleDiconnectRow = async (_id: number) => {
    try {
      const res = await axiosInstance.post(DELETE_STORE_API_URL, {
        portalId: Number(portalId),
        storeId: _id,
        appCode: POINTSANDREWARD,
      });
      if (res.data.success) {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        setStoreDetails(undefined);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (isFetching) {
    return <PartialPageSpinner />;
  }

  return (
    <Page title={t('create_store')}>
      {notification ? (
        <Alert severity='info'>
          <span dangerouslySetInnerHTML={{ __html: notification }}></span>
        </Alert>
      ) : null}

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-5/6 py-5'>
          <Card sx={{ mb: 1, padding: '25px', width: '100%' }}>
            <CardHeader
              title={
                <Typography variant='subtitle1' component='div'>
                  {t('store_details')}
                  {storeDetails && (
                    <Box component='span' sx={{ color: '#2196F3', ml: 2 }}>
                      {t('connected')}
                    </Box>
                  )}
                </Typography>
              }
              sx={{ p: 0, pb: 2 }}
              action={
                storeDetails && (
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        {storeDetails ? (
                          <MenuItem
                            onClick={() => {
                              handleDiconnectRow(storeDetails._id);
                              handleCloseMenu();
                            }}
                            sx={{ color: 'error.main' }}
                          >
                            <Iconify icon={'eva:trash-2-outline'} />
                            {t('disconnect')}
                          </MenuItem>
                        ) : null}
                      </>
                    }
                  />
                )
              }
            />
            <StoreForm
              storeDetails={storeDetails}
              returnUrl={POINTS_AND_REWARDS_NAVIGATION.general.settings(
                portalId,
                'store-setup'
              )}
              appCode={POINTSANDREWARD}
            />
          </Card>
          {storeDetails && apiKey && (
            <Card sx={{ padding: '25px', width: '100%' }}>
              <Typography sx={{ mb: 2 }} variant='subtitle1' component='div'>
                {t('api_key')}
              </Typography>
              <CopyClipboard value={apiKey} />
            </Card>
          )}
        </div>
        <div className='sm:basis-1/6'></div>
      </div>
    </Page>
  );
}
