import {
  Box,
  Card,
  Container,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from '@mui/material';
import axios from 'axios';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  COUPON_RULE_LISTING_API_URL,
  DELETE_COUPON_RULE_API_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import {
  CouponRuleListingType,
  CouponRuleType,
} from '../../DiscountCouponsType';
import Page from 'components/Page';
import axiosInstance from 'utils/axios';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import CouponTableRow from '../table/CouponRuleTableRow';
import Iconify from 'components/Iconify';
import { LoadingButton } from '@mui/lab';
import useActiveApps from 'hooks/apps/useActiveApps';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  PATH_PAGE,
} from 'routes/paths';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';

const ROWS_PER_PAGE = 10;

export default function CouponRulesListing() {
  const portalId = usePortalId();
  useIfValidityExpired(AUTOMATEDCOUPON);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'label', label: t('coupon_name'), align: 'left' },
    { id: 'is_enable', label: t('discount_type'), align: 'left' },
    { id: 'discountValue', label: t('discount_value') },
    { id: 'created_at', label: t('created') },
    { id: 'coupon_created', label: t('coupon_created') },
    // { id: 'success_rate', label: t('success_rate') },
    { id: 'action', label: t('action') },
  ];
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const activeApps = useActiveApps(portalId);

  async function fetchData(search: string, skip = 0) {
    const response = await axiosInstance.get<CouponRuleListingType>(
      COUPON_RULE_LISTING_API_URL,
      {
        params: {
          search,
          portalId,
          skip,
          limit: ROWS_PER_PAGE,
          status: true,
        },
      }
    );

    return response.data;
  }

  const {
    data: couponResponse,
    isFetching,
    refetch,
  } = useQuery<CouponRuleListingType>(
    [COUPON_RULE_LISTING_API_URL, skip, search],
    () => fetchData(search, skip as number),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: lastPage => {
        return lastPage.hasMore === true
          ? lastPage.skip + ROWS_PER_PAGE
          : undefined;
      },
    }
  );

  const navigate = useNavigate();

  if (!activeApps.includes('automatedcoupon')) {
    navigate(PATH_PAGE.page404);
  }
  if (!couponResponse) {
    return <PartialPageSpinner />;
  }

  const totalPage = Math.ceil(couponResponse?.totalCount / ROWS_PER_PAGE);

  const handleEditRow = (id: string) => {
    navigate(DISCOUNT_COUPONS_NAVIGATION.general.editCoupon(portalId, id));
  };

  const handleDeleteRow = async (_id: string) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(DELETE_COUPON_RULE_API_URL + _id);
      if (res.data.success) {
        setLoading(false);
        enqueueSnackbar(res.data.message, { variant: 'success' });
        if (couponResponse.data.length === 1) {
          setSkip(skip => (skip > 0 ? skip - ROWS_PER_PAGE : 0));
        }

        refetch();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  let contentLoad: React.ReactNode = {};

  if (isFetching || loading) {
    contentLoad = (
      <TableBody sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </TableBody>
    );
  }

  const mapPages = (data: CouponRuleType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <CouponTableRow
          key={row._id}
          row={row}
          onEditRow={() => handleEditRow(row._id)}
          onDeleteRow={() => handleDeleteRow(row._id)}
        />
      );
    });

    return rows;
  };

  if (!isFetching) {
    contentLoad = couponResponse.data.length ? (
      <TableBody>{mapPages(couponResponse.data)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('coupon')} />
      </>
    );
  }

  const onSearchChange = (value: string) => {
    setSkip(0);
    setSearch(value);
  };

  return (
    <Page title={t('coupon_rule_listing')}>
      <Container>
        <Box sx={{ mb: 5 }}>
          <Box sx={{ mt: 2 }}>
            <TextField
              value={search}
              onChange={event => onSearchChange(event.target.value)}
              placeholder={t('search_coupon_rule')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              sx={{ marginTop: '24px', marginBottom: '15px', float: 'right' }}
              variant='contained'
              onClick={() =>
                navigate(
                  DISCOUNT_COUPONS_NAVIGATION.general.createCoupon(portalId)
                )
              }
              startIcon={<Iconify icon='eva:plus-fill' />}
              loading={false}
            >
              {t('create_new_coupon_rule')}
            </LoadingButton>
          </Box>
        </Box>
        <Box sx={{ paddingTop: '40px' }}>
          <Card sx={{ height: '750px' }}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                  <TableHeadCustom headLabel={TABLE_HEAD} />
                  {contentLoad}
                </Table>
                <Card
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    paddingBottom: '25px',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  {couponResponse && totalPage > 1 ? (
                    <Pagination
                      color='primary'
                      variant='outlined'
                      count={totalPage}
                      page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                      onChange={(e, pageNo) =>
                        setSkip((pageNo - 1) * ROWS_PER_PAGE)
                      }
                    />
                  ) : null}
                </Card>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
