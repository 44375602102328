import React, { FunctionComponent, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, Stack, TextField, Typography } from '@mui/material';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { POINTSANDREWARD, POINTS_AND_REWARDS_NAVIGATION } from 'routes/paths';
import { RuleListingObjectType } from '../../PointsRewardsType';
import { useRulesType } from 'api/ApiHooks/PointsRewardsApiHooks';
import { useWooProducts } from 'api/ApiHooks/GeneralHooks';
import { IWooProducts } from 'containers/common/dashboard/EcommerceProductListType';
import ComboBox, { CrmComboBoxType } from 'components/ComboBox';
import { isApiError } from 'utils/apiFunctions';
import { useSnackbar } from 'notistack';
import {
  CREATE_RULE_API_URL,
  UPDATE_RULE_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import axiosInstance from 'utils/axios';

type Props = {
  type: 'add' | 'edit';
  storeId: string;
  ruleDetails?: RuleListingObjectType;
};

const initialErrorState = {
  title: '',
  description: '',
  name: '',
  value: '',
  is_enabled: false,
  points_through_rule: 0,
  limit_per_customer: {
    usage_limit: '',
    interval: '',
  },
  product: '',
};

const RuleForm: FunctionComponent<Props> = ({ type, storeId, ruleDetails }) => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { data } = useRulesType();
  const ruleTypesData = data?.data;
  const navigate = useNavigate();
  const [searchProductVal, setSearchProductVal] = useState('');
  const defaultValues = {
    _id: ruleDetails?._id || '',
    title: ruleDetails?.title || '',
    name: ruleDetails?.name || '',
    description: ruleDetails?.description || '',
    value: ruleDetails?.value || 0,
    is_enabled: ruleDetails?.is_enabled || false,
    custom_rule: ruleDetails?.custom_rule || true,
    points_through_rule: ruleDetails?.points_through_rule || 0,
    portalId: ruleDetails?.portalId || Number(portalId),
    rewarded_count: ruleDetails?.rewarded_count || 0,
    rule_icon: ruleDetails?.rule_icon || '',
    limit_per_customer: {
      interval: ruleDetails?.limit_per_customer?.interval || '',
      usage_limit: ruleDetails?.limit_per_customer?.usage_limit || 0,
    },
    product: {
      id: ruleDetails?.product?.id || -1,
      price: Number(ruleDetails?.product?.price) || 0,
      title: ruleDetails?.product?.title || '',
      url: ruleDetails?.product?.url || '',
    },
    storeId: Number(storeId),
    created_at: ruleDetails?.created_at || '',
    updated_at: ruleDetails?.updated_at || '',
  };
  const [ruleData, setRuleData] = useState(defaultValues);
  const [errorMessage, setErrorMessage] = useState(initialErrorState);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<IWooProducts[] | []>(
    ruleDetails?.product
      ? ruleDetails.product.id > 0
        ? [
            {
              id: ruleDetails.product.id,
              name: ruleDetails.product.title,
              price: ruleDetails.product.price.toString(),
              permalink: ruleDetails.product.url,
            },
          ]
        : []
      : []
  );

  const setProducts = (products: any) => {
    if (products.length > 1) {
      return false;
    }
    if (products.length > 0) {
      ruleData.product = {
        id: products[0].id,
        title: products[0].name,
        price: Number(products[0].price),
        url: products[0].permalink,
      };
      errorMessage.product = '';
    }
    setSelectedProducts(products);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { data: wooProducts } = useWooProducts({
    search: searchProductVal,
    storeId: Number(storeId),
    appCode: POINTSANDREWARD,
    portalId: Number(portalId),
    brand: '' || [],
  });

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setErrorMessage(error => ({
        ...error,
        [event.target.name]: '',
      }));
    }
    setRuleData(ruleData => ({
      ...ruleData,
      [event.target.name]:
        event.target.name === 'value'
          ? Number(event.target.value)
          : event.target.value,
    }));
  };

  const handleUsageChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newData = { ...ruleData };
    event.target.name === 'usage_limit'
      ? (newData.limit_per_customer.usage_limit = Number(event.target.value))
      : (newData.limit_per_customer.interval = event.target.value);

    setRuleData(newData);

    const tempError = { ...errorMessage };
    if (event.target.value !== '') {
      tempError.limit_per_customer.usage_limit = '';
    }
    setErrorMessage(tempError);
  };

  const options = React.useMemo(() => {
    const allProducts: IWooProducts[] = [];

    wooProducts?.pages.forEach(page => {
      allProducts.push(...page.data);
    });

    return allProducts;
  }, [wooProducts?.pages.length]);

  function validateRuleForm(newData: RuleListingObjectType) {
    let flag = true;
    const tempError = { ...errorMessage };
    let scrollTo = '';

    if (newData.title.length === 0) {
      flag = false;
      tempError.title = t('title_is_required');
      scrollTo = scrollTo === '' ? 'title' : scrollTo;
    }

    if (newData.description.length === 0) {
      flag = false;
      tempError.description = t('description_is_required');
      scrollTo = scrollTo === '' ? 'description' : scrollTo;
    }

    if (newData.name.length === 0 && type === 'add') {
      flag = false;
      tempError.name = t('rule_type_is_required');
      scrollTo = scrollTo === '' ? 'rule_type' : scrollTo;
    }

    if (newData.value === 0) {
      flag = false;
      tempError.value = t('points_are_required');
      scrollTo = scrollTo === '' ? 'points' : scrollTo;
    }

    if (newData.limit_per_customer.usage_limit === 0) {
      flag = false;
      tempError.limit_per_customer.usage_limit = t('limit_is_required');
      scrollTo = scrollTo === '' ? 'only_give_points' : scrollTo;
    }

    if (newData.name === 'purchase_product' && selectedProducts.length === 0) {
      flag = false;
      tempError.product = t('product_type_is_required');
      scrollTo = scrollTo === '' ? 'woo_product' : scrollTo;
    }

    if (flag) {
      setErrorMessage(initialErrorState);

      return true;
    } else {
      setErrorMessage(tempError);
      scrollToElement(scrollTo);

      return false;
    }
  }

  const createRule = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
        title: ruleData.title,
        name: ruleData.name,
        is_enabled: ruleData.is_enabled,
        value: ruleData.value,
        description: ruleData.description,
        limit_per_customer: {
          usage_limit: ruleData.limit_per_customer.usage_limit,
          interval: ruleData.limit_per_customer.interval,
        },
      };
      if (ruleData.name === 'purchase_product') {
        Object.assign(params, {
          product: {
            id: ruleData.product.id,
            title: ruleData.product.title,
            price: Number(ruleData.product.price),
            url: ruleData.product.url,
          },
        });
      }
      const result = await axiosInstance.post(CREATE_RULE_API_URL, params);
      if (result.data.success) {
        navigate(
          POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
            portalId,
            'rules'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  const updateRule = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
        title: ruleData.title,
        is_enabled: ruleData.is_enabled,
        value: ruleData.value,
        description: ruleData.description,
        limit_per_customer: {
          usage_limit: ruleData.limit_per_customer.usage_limit,
          interval: ruleData.limit_per_customer.interval,
        },
      };

      if (ruleData.name === 'purchase_product') {
        Object.assign(params, {
          product: {
            id: ruleData.product.id,
            title: ruleData.product.title,
            price: Number(ruleData.product.price),
            url: ruleData.product.url,
          },
        });
      }

      const result = await axiosInstance.post(
        UPDATE_RULE_API_URL + '/' + ruleData?._id,
        params
      );

      if (result.data.success) {
        navigate(
          POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
            portalId,
            'rules'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    if (validateRuleForm(ruleData)) {
      if (type === 'add') {
        createRule();
      } else {
        updateRule();
      }
    }
  };

  function scrollToElement(ele: string) {
    let elmnt;
    if (ele === 'primary') {
      elmnt = document.querySelector('input[name="primary"]:checked');
    } else {
      elmnt = document.getElementById(ele);
    }

    elmnt?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }

  if (!data) {
    return <PartialPageSpinner />;
  }

  return (
    <>
      <Stack spacing={3}>
        {type === 'add' && (
          <Card sx={{ padding: '25px' }}>
            <Typography sx={{ mb: 2 }} variant='h6' component='div'>
              {t('rule_type')}:
            </Typography>
            <TextField
              id='rule_type'
              placeholder={t('store_source')}
              name='name'
              select
              fullWidth
              error={!!errorMessage.name}
              SelectProps={{ native: true }}
              onChange={handleChangeEvent}
              helperText={<span>{errorMessage.name}</span>}
            >
              <option value='' key='-1' className='text-gray-300'>
                {t('select_rule_type')}
              </option>
              {_.map(ruleTypesData, function (ruleType, key) {
                if (ruleType.can_be_duplicate)
                  return (
                    <option key={key} value={key}>
                      {ruleType.title}
                    </option>
                  );
              })}
            </TextField>
          </Card>
        )}
        <Card sx={{ padding: '25px' }}>
          <Typography sx={{ mb: 2 }} variant='h6' component='div'>
            {t('display')}:
          </Typography>
          <TextField
            id='title'
            name='title'
            label={t('title')}
            defaultValue={ruleData.title}
            error={!!errorMessage.title}
            fullWidth
            onChange={handleChangeEvent}
            helperText={<span>{errorMessage.title}</span>}
          />
          <TextField
            id='description'
            name='description'
            label={t('Description')}
            defaultValue={ruleData.description}
            error={!!errorMessage.description}
            sx={{ mt: '25px' }}
            fullWidth
            onChange={handleChangeEvent}
            helperText={<span>{errorMessage.description}</span>}
          />
        </Card>
        <Card sx={{ padding: '25px' }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant='h6' component='div'>
            {t('Details')}
          </Typography>
          <TextField
            id='points'
            name='value'
            label={t('points')}
            type='number'
            defaultValue={ruleData.value}
            error={!!errorMessage.value}
            fullWidth
            onChange={handleChangeEvent}
            helperText={<span>{errorMessage.value}</span>}
          />
          {ruleData.name !== 'create_account' && ruleData.name !== 'birthday' && (
            <Stack>
              <Typography
                sx={{ mt: 2, mb: '15px' }}
                variant='h6'
                component='div'
              >
                {t('limit')}
              </Typography>
              {ruleData.name === 'visit_website' ? (
                <Stack direction='row' justifyContent='space-between'>
                  <div>
                    <TextField
                      id='only_give_points'
                      name='usage_limit'
                      defaultValue={ruleData.limit_per_customer.usage_limit}
                      label={t('only_give_points')}
                      sx={{ width: '50%' }}
                      error={!!errorMessage.limit_per_customer.usage_limit}
                      onChange={handleUsageChangeEvent}
                      helperText={
                        <span>
                          {errorMessage.limit_per_customer.usage_limit}
                        </span>
                      }
                    />
                    <span
                      style={{
                        position: 'absolute',
                        marginTop: '18px',
                        marginLeft: '5px',
                      }}
                    >
                      {t('times_per')}
                    </span>
                  </div>
                  <TextField
                    id='rule_usage_interval'
                    placeholder=''
                    label=''
                    name='interval'
                    sx={{ width: '48%' }}
                    select
                    fullWidth
                    value={ruleData.limit_per_customer.interval}
                    error={!!errorMessage.limit_per_customer.interval}
                    SelectProps={{ native: true }}
                    onChange={handleUsageChangeEvent}
                    helperText={
                      <span>{errorMessage.limit_per_customer.interval}</span>
                    }
                  >
                    <option value='week'>{t('week')}</option>
                    <option value='month'>{t('month')}</option>
                    <option value='year'>{t('year')}</option>
                  </TextField>
                </Stack>
              ) : (
                <div>
                  <TextField
                    id='only_give_points'
                    name='usage_limit'
                    defaultValue={ruleData.limit_per_customer.usage_limit}
                    label={t('only_give_points')}
                    error={!!errorMessage.limit_per_customer.usage_limit}
                    sx={{ width: '50%' }}
                    onChange={handleUsageChangeEvent}
                    helperText={
                      <span>{errorMessage.limit_per_customer.usage_limit}</span>
                    }
                  />
                  <span
                    style={{
                      position: 'absolute',
                      marginTop: '18px',
                      marginLeft: '5px',
                    }}
                  >
                    {t('times')}
                  </span>
                </div>
              )}
            </Stack>
          )}
        </Card>
        {ruleData.name === 'purchase_product' && (
          <Card sx={{ padding: '25px' }}>
            <Typography sx={{ mb: 2 }} variant='h6' component='div'>
              {t('product_field')}
            </Typography>
            <ComboBox
              id='woo_product'
              multiple={true}
              isMultiple={false}
              //loading={isFetching}
              label={t('select_product')}
              options={options}
              values={selectedProducts}
              onChange={products => setProducts(products as CrmComboBoxType[])}
              inputSearchValue={searchProductVal}
              onInputChange={setSearchProductVal}
              error={errorMessage.product}
              loading={false}
            />
          </Card>
        )}
        <LoadingButton
          fullWidth
          loading={isLoading}
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('activate_gateway')}
          onClick={() => handleSubmit()}
        >
          {t('save')}
        </LoadingButton>
      </Stack>
    </>
  );
};

export default RuleForm;
