import { FunctionComponent, useState } from 'react';
import { Box, Card, Container, Pagination, Stack } from '@mui/material';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useActiveApps from 'hooks/apps/useActiveApps';
import { useQuery } from 'react-query';
import { PATH_PAGE, POINTS_AND_REWARDS_NAVIGATION } from 'routes/paths';
import { TableNoData } from 'components/table';
import PartialPageSpinner from 'components/PartialPageSpinner';
import axiosInstance from 'utils/axios';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
  DELETE_RULE_API_URL,
  RULE_ENABLE_DISABLE_API_URL,
  RULE_LISTING_API_URL,
} from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/Iconify';
import {
  RuleListingType,
  RuleListingObjectType,
} from '../../PointsRewardsType';
import RulesTableRow from '../../Table/RulesTableRow';
import DeleteConfirmDialogue from './DeleteConfirmDialogue';

type Props = {
  storeId: string;
};
const RulesListingTable: FunctionComponent<Props> = ({ storeId }) => {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const activeApps = useActiveApps(portalId);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState('');
  const [skip, setSkip] = useState(0);

  const ROWS_PER_PAGE = 10;

  const handleEditRow = (id: string) => {
    navigate(POINTS_AND_REWARDS_NAVIGATION.general.editRule(portalId, id));
  };

  const handledDeleteRule = (ruleId: string) => {
    confirmDialogueOpenClose();
    if (ruleId) {
      setSelectedRuleId(ruleId);
    }
  };

  const confirmDialogueOpenClose = () => {
    setConfirmDialogueOpen(!confirmDialogueOpen);
  };

  async function fetchData() {
    const response = await axiosInstance.get<RuleListingType>(
      RULE_LISTING_API_URL,
      {
        params: {
          storeId: storeId,
          portalId,
          limit: ROWS_PER_PAGE,
          ...(skip ? { skip } : {}),
        },
      }
    );

    return response.data;
  }

  const {
    data: rulesResponse,
    isFetching,
    refetch,
  } = useQuery<RuleListingType>(
    [RULE_LISTING_API_URL, skip],
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteRule = async (ruleId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        DELETE_RULE_API_URL +
          '/' +
          ruleId +
          `?portalId=${portalId}&storeId=${storeId}`
      );
      enqueueSnackbar(response.data.message, { variant: 'success' });
      refetch();
    } catch (res: any) {
      enqueueSnackbar(res.message, { variant: 'error' });
    }
    setLoading(false);
  };

  if (!activeApps.includes('pointsandreward')) {
    navigate(PATH_PAGE.page404);
  }

  if (!rulesResponse) {
    return <PartialPageSpinner />;
  }

  let contentLoad: React.ReactNode = {};

  const handleChangeSwitch = async (_id: string, enabled: boolean) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(RULE_ENABLE_DISABLE_API_URL + _id, {
        storeId: storeId,
        portalId: portalId,
        is_enabled: enabled,
      });
      if (res.data.success) {
        setLoading(false);
        refetch();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const mapPages = (data: RuleListingObjectType[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <RulesTableRow
          key={row._id}
          row={row}
          onEditRow={() => handleEditRow(row._id)}
          onChangeSwitch={(enabled: boolean) =>
            handleChangeSwitch(row._id, enabled)
          }
          onDeleteRow={() => handledDeleteRule(row._id)}
        />
      );
    });

    return rows;
  };

  if (isFetching || loading) {
    contentLoad = (
      <Box sx={{ display: 'flex', position: 'relative', left: '34em' }}>
        <PartialPageSpinner />
      </Box>
    );
  }

  const totalPage = rulesResponse
    ? Math.ceil((rulesResponse?.totalCount ?? 0) / ROWS_PER_PAGE)
    : 0;

  if (rulesResponse) {
    contentLoad = rulesResponse?.data.length ? (
      <>{mapPages(rulesResponse.data)}</>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('rules')} />
      </>
    );
  }

  return (
    <Container>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <LoadingButton
          sx={{
            alignItems: 'center',
            borderRadius: '8px',
            padding: '6px 8px',
            marginBottom: '10px',
            width: '155px',
          }}
          variant='text'
          onClick={() =>
            navigate(POINTS_AND_REWARDS_NAVIGATION.general.createRule(portalId))
          }
          startIcon={<Iconify icon='eva:plus-fill' />}
          loading={false}
        >
          {t('create_new_rule')}
        </LoadingButton>
      </Stack>
      {contentLoad}
      {rulesResponse && totalPage > 1 && (
        <Card
          sx={{
            display: 'flex',
            paddingBottom: '25px',
            justifyContent: 'center',
            paddingTop: '10px',
          }}
        >
          <Pagination
            color='primary'
            variant='outlined'
            count={totalPage}
            page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
            onChange={(e, pageNo) => setSkip((pageNo - 1) * ROWS_PER_PAGE)}
          />
        </Card>
      )}
      <Stack>
        <DeleteConfirmDialogue
          open={confirmDialogueOpen}
          title={t('delete_rule')}
          content={t('do_you_want_to_delete_this_rule')}
          handleOpenCloseConfirmDialogue={() => confirmDialogueOpenClose()}
          handleDelete={() => {
            confirmDialogueOpenClose();
            deleteRule(selectedRuleId);
          }}
        />
      </Stack>
    </Container>
  );
};
export default RulesListingTable;
