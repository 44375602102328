import PointsAndRewards from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/PointsAndRewards';
import CreateRule from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Rules/CreateRule';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from '../config';
import Settings from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Settings/Settings';
import EditRule from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Rules/EditRule';
import CreateReward from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Rewards/CreateReward';
import EditReward from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Rewards/EditReward';
import ManageCodesReward from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Rewards/child/ManageCodesReward';
import EditCustomer from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Customer/EditCustomer';

export const pointsAndRewardsRoutes = [
  {
    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    index: true,
  },
  {
    path: '',
    children: [
      {
        path: ':tab',
        index: true,
        element: <PointsAndRewards />,
      },
      {
        path: 'settings/:tab',
        index: true,
        element: <Settings />,
      },
      {
        path: 'rules/create',
        element: <CreateRule />,
      },
      {
        path: 'customer/edit/:customerId',
        element: <EditCustomer />,
      },
      {
        path: 'rewards/create',
        element: <CreateReward />,
      },
      {
        path: 'rules/edit/:ruleId',
        element: <EditRule />,
      },
      {
        path: 'rewards/edit/:rewardId',
        element: <EditReward />,
      },
      {
        path: 'rewards/manage/:rewardId',
        element: <ManageCodesReward />,
      },
    ],
  },
];
