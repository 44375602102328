import {
  HubspotOwnerType,
  RewardDetailType,
  RuleListingObjectType,
  CustomerDetailType,
  RuleType,
  WoocommerceCurrencyType,
} from '../../containers/Apps/PointsAndRewardsApp/PointsAndReward/PointsRewardsType';
import axiosInstance from '../../utils/axios';
import { useQuery, UseQueryResult } from 'react-query';
import {
  APP_SETTINGS_API_URL,
  HUBSPOT_OWNER_API_URL,
  REWARD_DETAIL_API_URL,
  RULES_TYPE_API_URL,
  WOOCOMMERCE_CURRENCY_API_URL,
  RULE_DETAIL_API_URL,
  CUSTOMER_DETAILS_API_URL,
  GET_HUBSPOT_CREATED_CONFIG,
} from '../ApiConstants/PointsAndRewardsApiConstants';
import {
  AppSettingsType,
  CreatedConfigType,
} from 'containers/Apps/PointsAndRewardsApp/PointsAndReward/Settings/SettingsType';
import { WooStore } from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import { STORE_LISTING_API_URL } from 'api/ApiConstants/DiscountCouponsApiConstants';

const rewardDetailRequest = async (
  portalId: number,
  storeId: string,
  rewardId: string
): Promise<RewardDetailType> => {
  const { data } = await axiosInstance.get<RewardDetailType>(
    `${REWARD_DETAIL_API_URL}/${rewardId}`,
    {
      params: {
        portalId,
        storeId,
      },
    }
  );

  return data;
};

export const useRewardDetails = (
  portalId: number,
  storeId: string,
  rewardId: string
): UseQueryResult<RewardDetailType> => {
  return useQuery(
    [REWARD_DETAIL_API_URL, storeId],
    () => rewardDetailRequest(portalId, storeId, rewardId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const ruleDetailRequest = async (
  portalId: number,
  storeId: number,
  ruleId: string
): Promise<RuleListingObjectType> => {
  const { data } = await axiosInstance.get<RuleListingObjectType>(
    `${RULE_DETAIL_API_URL}/${ruleId}`,
    {
      params: {
        portalId,
        storeId,
      },
    }
  );

  return data;
};
export const customerDetailRequest = async (
  portalId: number,
  storeId: string,
  customerId: string
): Promise<CustomerDetailType> => {
  const { data } = await axiosInstance.get<CustomerDetailType>(
    `${CUSTOMER_DETAILS_API_URL}/${customerId}`,
    {
      params: {
        portalId,
        storeId,
      },
    }
  );

  return data;
};

export const useRuleDetails = (
  portalId: number,
  storeId: number,
  ruleId: string
): UseQueryResult<{
  data: RuleListingObjectType;
}> => {
  return useQuery(
    [REWARD_DETAIL_API_URL],
    () => ruleDetailRequest(portalId, storeId, ruleId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useCustomerDetails = (
  portalId: number,
  storeId: string,
  customerId: string
): UseQueryResult<CustomerDetailType> => {
  return useQuery(
    [CUSTOMER_DETAILS_API_URL],
    () => customerDetailRequest(portalId, storeId, customerId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const ruleTypesRequest = async (): Promise<{
  data: RuleType[];
}> => {
  const data = await axiosInstance.get<{
    data: RuleType[];
  }>(RULES_TYPE_API_URL);

  return data.data;
};

export const useRulesType = (): UseQueryResult<{
  data: RuleType[];
}> => {
  return useQuery([RULES_TYPE_API_URL], () => ruleTypesRequest(), {
    refetchOnWindowFocus: false,
  });
};

const hubspotOwnerRequest = async (
  portalId: number
): Promise<HubspotOwnerType> => {
  const { data } = await axiosInstance.get<HubspotOwnerType>(
    `${HUBSPOT_OWNER_API_URL}`,
    {
      params: {
        portalId,
      },
    }
  );

  return data;
};

export const useHubspotOwner = (
  portalId: number
): UseQueryResult<HubspotOwnerType> => {
  return useQuery(
    [HUBSPOT_OWNER_API_URL],
    () => hubspotOwnerRequest(portalId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const getWoocommerceCurrencies = async (
  portalId: number,
  storeId: number
): Promise<WoocommerceCurrencyType> => {
  const { data } = await axiosInstance.get<WoocommerceCurrencyType>(
    `${WOOCOMMERCE_CURRENCY_API_URL}`,
    {
      params: {
        portalId,
        storeId,
      },
    }
  );

  return data;
};

export const useWoocommerceCurrencies = (
  portalId: number,
  storeId: number
): UseQueryResult<WoocommerceCurrencyType> => {
  return useQuery(
    [WOOCOMMERCE_CURRENCY_API_URL],
    () => getWoocommerceCurrencies(portalId, storeId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const getAppSettings = async (
  portalId: number,
  appCode: string
): Promise<AppSettingsType> => {
  const { data } = await axiosInstance.get<AppSettingsType>(
    `${APP_SETTINGS_API_URL}`,
    {
      params: {
        portalId,
        appCode,
      },
    }
  );

  return data;
};

export const useAppSettings = (
  portalId: number,
  appCode: string
): UseQueryResult<AppSettingsType> => {
  return useQuery(
    [APP_SETTINGS_API_URL],
    () => getAppSettings(portalId, appCode),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const getCreatedConfig = async (
  portalId: number,
  search: string
): Promise<CreatedConfigType> => {
  const { data } = await axiosInstance.get<CreatedConfigType>(
    `${GET_HUBSPOT_CREATED_CONFIG}`,
    {
      params: {
        portalId,
        search,
      },
    }
  );

  return data;
};

export const useCreatedConfiguration = (
  portalId: number,
  search: string
): UseQueryResult<CreatedConfigType> => {
  return useQuery(
    [GET_HUBSPOT_CREATED_CONFIG],
    () => getCreatedConfig(portalId, search),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const wooCommerceStoresRequest = async (
  portalId: number,
  appCode: string
): Promise<{
  data: WooStore;
}> => {
  const data = await axiosInstance.get<{
    data: WooStore;
  }>(STORE_LISTING_API_URL, {
    params: {
      portalId,
      appCode,
    },
  });

  return data.data;
};

export const useWooCommerceStores = (
  portalId: number,
  appCode: string
): UseQueryResult<{
  data: WooStore;
}> => {
  return useQuery(
    [STORE_LISTING_API_URL],
    () => wooCommerceStoresRequest(portalId, appCode),
    {
      refetchOnWindowFocus: false,
    }
  );
};
