import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import {
  PATH_USER,
  POINTSANDREWARD,
  POINTS_AND_REWARDS_NAVIGATION,
} from 'routes/paths';
import RuleForm from '../child/form/RuleForm';
import Reward from 'assets/images/rewards.svg';
import Image from 'components/Image';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { capitalCase } from 'change-case';
import useResponsive from 'hooks/useResponsive';
import { useWooCommerceStores } from 'api/ApiHooks/PointsRewardsApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import Notices from 'containers/common/Notices/Notices';

export default function CreateRule() {
  useIfValidityExpired(POINTSANDREWARD);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const portalId = usePortalId();
  const smUp = useResponsive('up', 'sm');
  const { data } = useWooCommerceStores(Number(portalId), POINTSANDREWARD);

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  return (
    <Page title={t('rules')} className='px-6'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Image
            sx={{
              width: '36px',
              height: '36px',
              display: 'inline-block',
            }}
            visibleByDefault
            disabledEffect
            src={Reward}
            alt={t('points_and_rewards')}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h4' gutterBottom>
            {t('points_and_rewards')}
          </Typography>
        </Grid>
        <Grid item xs={smUp ? 7 : 6}>
          <LoadingButton
            sx={{ verticalAlign: 'text-bottom', float: 'right' }}
            variant='contained'
            loading={false}
            onClick={() =>
              navigate(PATH_USER.general.accountSettings(portalId, 'billing'))
            }
          >
            {t('manage_app')}
          </LoadingButton>
        </Grid>
      </Grid>
      <HeaderBreadcrumbs
        heading={t('')}
        links={[
          {
            name: t('app_listing'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('points_and_rewards'),
            href: '',
          },
          {
            name: t('create_new_rule'),
            href: '',
          },
        ]}
      />
      <Notices appName={POINTSANDREWARD} />
      <Box sx={{ mb: 5 }} />
      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        value='rules'
        onChange={(e, tabIndex) =>
          navigate(
            POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
              portalId,
              tabIndex
            )
          )
        }
      >
        <Tab
          disableRipple
          key={t('rules')}
          label={capitalCase(t('create_new_rule'))}
          icon=''
          value='rules'
        />
      </Tabs>

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-3/6 py-5'>
          <RuleForm type='add' storeId={data?.data._id} />
        </div>
        <div className='sm:basis-3/6'></div>
      </div>
    </Page>
  );
}
