import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import _ from 'lodash';
import Page from 'components/Page';
import usePortalId from 'hooks/portal/usePortalId';
import { useTranslation } from 'react-i18next';
import {
  AUTOMATEDCOUPON,
  DISCOUNT_COUPONS_NAVIGATION,
  SCHEDULE_NOW_MEETING_URL,
  PATH_USER,
} from 'routes/paths';
import { StoreForm } from './form/StoreForm';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import useIfValidityExpired from 'hooks/apps/useIfValidityExpired';
import Notices from '../../../../common/Notices/Notices';

export default function CreateStore() {
  useIfValidityExpired(AUTOMATEDCOUPON);
  const { t } = useTranslation();
  const portalId = usePortalId();

  return (
    <Page title={t('create_store')} className='px-6'>
      <HeaderBreadcrumbs
        heading={t('stores')}
        links={[
          {
            name: t('app_listing'),
            href: PATH_USER.appListing(portalId),
          },
          {
            name: t('store'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
              portalId,
              'stores'
            ),
          },
          {
            name: t('add_store'),
            href: DISCOUNT_COUPONS_NAVIGATION.general.createStore(portalId),
          },
        ]}
      />

      <Notices appName={AUTOMATEDCOUPON} />

      <div className='flex flex-wrap gap-[15%] sm:flex-nowrap'>
        <div className='flex-grow basis-4/6 py-5'>
          <StoreForm
            returnUrl={DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
              portalId,
              'stores'
            )}
            appCode={AUTOMATEDCOUPON}
          />
        </div>
        <div className='sm:basis-3/6'>
          <Typography variant='h5'>{t('about_store')}</Typography>
          <div className='text-gray-500'>{t('store_content_line_one')}</div>

          <List sx={{ listStyleType: 'disc' }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('store_content_line_two')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('store_content_line_three')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  className='text-gray-500'
                  primary={'-  ' + t('store_content_line_four')}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <div className='text-gray-500'>{t('store_content_line_five')}</div>
          <div className='mt-[20px] flex justify-between'>
            <Button
              target='_blank'
              variant='contained'
              href={SCHEDULE_NOW_MEETING_URL}
            >
              {t('lets_connect')}
            </Button>
            {sessionStorage.getItem('automatedDocLink') && (
              <Button
                target='_blank'
                variant='contained'
                href={sessionStorage.getItem('automatedDocLink') || ''}
              >
                {t('documentation')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}
