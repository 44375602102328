import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  open: boolean;
  handleOpenCloseConfirmDialogue: () => void;
  deleteReward: () => void;
}
type Props = OwnProps;
const DeleteRewardConfirmDialog: React.FC<Props> = ({
  open,
  handleOpenCloseConfirmDialogue,
  deleteReward,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>{t('delete_reward')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingTop: '25px' }}>
          <b>{t('warning')}: </b>
          {t('do_you_want_to_delete_this_reward')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteReward}>{t('yes')}</Button>
        <Button onClick={handleOpenCloseConfirmDialogue}>{t('no')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRewardConfirmDialog;
