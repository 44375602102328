import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import usePortalId from 'hooks/portal/usePortalId';
import axiosInstance from 'utils/axios';
import { APPROVE_PENDING_POINTS_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { isApiError } from 'utils/apiFunctions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
  customerId: string;
  storeId: string;
  pendingDialogue: {
    open: boolean;
    title: string;
    content: string;
  };
  pendingPoints: number | undefined;
  pendingDialogueOpenClose: () => void;
}
type Props = OwnProps;

const PendingDialogueBox: React.FC<Props> = ({
  customerId,
  storeId,
  pendingDialogue,
  pendingPoints,
  pendingDialogueOpenClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const params = {
        portalId: Number(portalId),
        storeId: Number(storeId),
      };

      const result = await axiosInstance.post(
        APPROVE_PENDING_POINTS_API_URL + '/' + customerId,
        params
      );

      if (result.data.success) {
        enqueueSnackbar(result.data.message, {
          variant: 'info',
        });
        pendingDialogueOpenClose();
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={pendingDialogue.open}
      onClose={pendingDialogueOpenClose}
    >
      <DialogTitle>
        {pendingDialogue.title}
        <IconButton
          aria-label='close'
          onClick={pendingDialogueOpenClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: '14px', paddingTop: '25px' }} gutterBottom>
          {pendingDialogue.content}
        </Typography>
        {pendingPoints !== undefined && pendingPoints > 0 ? (
          <Stack
            sx={{
              borderLeft: '3px solid #FF4842',
              fontSize: '14px',
              mt: '15px',
              pl: '15px',
            }}
          >
            <Typography component='span' variant='body2'>
              <strong>{t('by_clicking_approve_pending_points')}</strong>
            </Typography>
          </Stack>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant='outlined'
          size='large'
          sx={{
            background: '#DFE3E8',
            border: '1px solid #DFE3E8',
            color: '#212B36',
          }}
          onClick={pendingDialogueOpenClose}
        >
          {t('cancel')}
        </Button>
        {pendingPoints !== undefined && pendingPoints > 0 ? (
          <LoadingButton
            loading={isLoading}
            size='large'
            type='submit'
            variant='contained'
            aria-label={t('approve')}
            onClick={() => handleSubmit()}
            sx={{ width: '94px' }}
          >
            {t('approve')}
          </LoadingButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
export default PendingDialogueBox;
