import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  open: boolean;
  handleOpenCloseConfirmDialogue: () => void;
  cancelSubscription: () => void;
}
type Props = OwnProps;
const ConfirmCancelSubscription: React.FC<Props> = ({
  open,
  handleOpenCloseConfirmDialogue,
  cancelSubscription,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={'xs'}
      open={open}
      onClose={handleOpenCloseConfirmDialogue}
    >
      <DialogTitle>{t('cancel_subscription')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingTop: '25px' }}>
          <b>{t('warning')}: </b>
          {t('cancel_subscription_warning')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelSubscription}>{t('cancel_subscription')}</Button>
        <Button onClick={handleOpenCloseConfirmDialogue}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmCancelSubscription;
