import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import usePortalId from 'hooks/portal/usePortalId';
import { Payments } from './child/Payments';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { defaultLang, STRIPE_PUBLISHER_KEY } from 'config';
import { useAppPlans } from 'api/ApiHooks/GeneralHooks';
import { useParams } from 'react-router-dom';
import { useAppDetail } from 'api/ApiHooks/DiscountCouponsApiHooks';
export default function PaymentsContainer() {
  const lang = localStorage.getItem('i18nextLng') || defaultLang.value;
  const { appName } = useParams<{ appName: string }>();
  const portalId = usePortalId();
  const { data } = useAppPlans(appName!);
  const appDetails = useAppDetail(Number(portalId), appName!);
  const appDetailsData = appDetails.data;
  if (data === undefined || appDetailsData === undefined) {
    return <PartialPageSpinner />;
  }

  const stripePromise = loadStripe(STRIPE_PUBLISHER_KEY, {
    //@ts-ignore
    locale: lang,
  });

  return (
    <Elements stripe={stripePromise}>
      <Payments appPlans={data.data} appDetails={appDetailsData.data} />
    </Elements>
  );
}
