import axios from 'axios';
import { defaultLang, HOST_API } from '../config';
import AxiosMockAdapter from 'axios-mock-adapter';

// add axios mock support
const axiosMockInstance = axios.create({
  baseURL: HOST_API,
});

const axiosLiveInstance = axios.create({
  baseURL: HOST_API,
});

export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockInstance,
  { delayResponse: 0 }
);

const axiosInstance =
  process.env.REACT_APP_IS_AXIOS_MOCK === 'true'
    ? axiosMockInstance
    : axiosLiveInstance;

axiosInstance.interceptors.request.use(
  config => {
    const locale = localStorage.getItem('i18nextLng') || defaultLang.value;
    if (locale) {
      //@ts-ignore
      config.headers.locale = locale;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(response => {
  if (response.data.success === false) return Promise.reject(response.data);

  return response;
});

export default axiosInstance;
