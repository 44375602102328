import { Stack, Alert, Button } from '@mui/material';
import { SCHEDULE_COUPONS_API_URL } from 'api/ApiConstants/DiscountCouponsApiConstants';
import { useAppDetail } from 'api/ApiHooks/DiscountCouponsApiHooks';
import usePortalId from 'hooks/portal/usePortalId';
import { useSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_USER, SUPPORT_URL } from 'routes/paths';
import axiosInstance from 'utils/axios';
import { fDate } from 'utils/formatTime';
type Props = {
  appName: string;
};
const Notices: FunctionComponent<Props> = ({ appName }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { data, refetch } = useAppDetail(Number(portalId), appName);
  if (data === undefined) {
    return <div />;
  }

  const navigateToPayment = () => {
    navigate(PATH_USER.payments.payments(portalId, appName));
  };

  let expire = null;
  if (data.data?.payment_plan?.validity) {
    const todayDate = new Date();
    const today =
      todayDate.getFullYear() +
      '-' +
      (todayDate.getMonth() + 1) +
      '-' +
      todayDate.getDate();
    const validityDate = new Date(Number(data.data.payment_plan.validity));
    const validity =
      validityDate.getFullYear() +
      '-' +
      (validityDate.getMonth() + 1) +
      '-' +
      validityDate.getDate();

    if (today === validity) {
      expire = t('will_expire_today');
    } else {
      expire =
        t('will_expire_on') +
        ' ' +
        fDate(Number(data.data.payment_plan.validity));
    }
  }

  const processPayment = async () => {
    try {
      const response = await axiosInstance.post(
        `${SCHEDULE_COUPONS_API_URL}?portalId=${portalId}`
      );
      enqueueSnackbar(response.data.message, { variant: 'success' });
      refetch();
    } catch (res: any) {
      enqueueSnackbar(res.message, { variant: 'error' });
    }
  };

  return (
    <>
      {data?.data.payment_plan?.automated_coupon_notice !== undefined ||
      data?.data.payment_plan?.type !== 'noSubscription' ? (
        <div>
          {data.data.payment_plan.automated_coupon_notice ? (
            <Stack sx={{ paddingBottom: '15px' }}>
              <Alert
                severity='error'
                action={
                  <>
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={navigateToPayment}
                      sx={{ marginRight: '15px' }}
                    >
                      {t('upgrade')}
                    </Button>
                    <Button size='small' variant='outlined' href={SUPPORT_URL}>
                      {t('support')}
                    </Button>
                  </>
                }
              >
                {data.data.payment_plan.automated_coupon_notice}
              </Alert>
            </Stack>
          ) : null}

          {data.data.payment_plan.type === 'trial' && expire ? (
            <Stack sx={{ paddingBottom: '15px' }}>
              <Alert severity='error'>
                {t('your_plan')} {expire}
              </Alert>
            </Stack>
          ) : null}
        </div>
      ) : null}
      {data.data?.pending_coupon_data?.notice !== undefined &&
      data?.data.payment_plan?.type !== 'noSubscription' ? (
        <Stack sx={{ paddingBottom: '15px' }}>
          <Alert
            severity='error'
            action={
              data?.data.payment_plan.current_month_coupons >=
              data.data.payment_plan.max_coupon_count ? (
                <div></div>
              ) : !data.data.pending_coupon_data.in_progress ? (
                <Button
                  size='small'
                  variant='outlined'
                  onClick={processPayment}
                >
                  {t('process')}
                </Button>
              ) : (
                <Button
                  size='small'
                  variant='outlined'
                  disabled
                  onClick={() => {}}
                >
                  {t('in progress')}
                </Button>
              )
            }
          >
            {data.data.pending_coupon_data.notice}
          </Alert>
        </Stack>
      ) : null}
    </>
  );
};

export default Notices;
