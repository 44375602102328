import { LoadingButton } from '@mui/lab';
import {
  FormHelperText,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Card,
  Typography,
  Box,
  ButtonGroup,
  Button,
} from '@mui/material';
import { UPDATE_APP_SETTINGS_API_URL } from 'api/ApiConstants/PointsAndRewardsApiConstants';
import { useWoocommerceCurrencies } from 'api/ApiHooks/PointsRewardsApiHooks';
import Image from 'components/Image';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { POINTSANDREWARD } from 'routes/paths';
import { isApiError } from 'utils/apiFunctions';
import axiosInstance from 'utils/axios';
import { HubspotOwner } from '../../PointsRewardsType';
import {
  AppSettingErrorDataType,
  AppSettings,
  AppSettingStateDataType,
} from '../SettingsType';

type Props = {
  hubspotOwners: HubspotOwner[];
  appSettings: AppSettings;
};

const initialErrorStateData: AppSettingErrorDataType = {
  currencyCode: '',
  couponPrefix: '',
  hubspotContactOwner: '',
  widgetPosition: '',
  appTitle: '',
  primaryColor: '',
  textColor: '',
  bodyBackgroundColor: '',
  headingColor: '',
  bodyTextColor: '',
  accentColor: '',
  widgetIcon: '',
};

const GeneralSettingsForm: FunctionComponent<Props> = ({
  hubspotOwners,
  appSettings,
}) => {
  const isErrorRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [stateError, setStateError] = useState(initialErrorStateData);
  const portalId = usePortalId();
  const { t } = useTranslation();
  const [state, setState] = useState<AppSettingStateDataType>({
    currencyCode:
      appSettings.appSettings.store_setting.store_currency.code || 'INR',
    widgetPosition:
      appSettings.appSettings.widget_setting.widget_position || 'right',
    couponPrefix: appSettings.appSettings.coupon_setting.coupon_prefix || '',
    hubspotContactOwner:
      appSettings.appSettings.hubspot_setting.hubspot_contact_owner.toString() ||
      '-1',
    appTitle: appSettings.appSettings.widget_setting.app_title || '',
    primaryColor:
      appSettings.appSettings.widget_setting.global_color.primary_color ||
      '#772BFF',
    textColor:
      appSettings.appSettings.widget_setting.global_color.text_color ||
      '#FFFFFF',
    bodyBackgroundColor:
      appSettings.appSettings.widget_setting.body_background_color || '#FFFFFF',
    headingColor:
      appSettings.appSettings.widget_setting.heading_color || '#212B36',
    bodyTextColor:
      appSettings.appSettings.widget_setting.body_text_color || '#637381',
    accentColor:
      appSettings.appSettings.widget_setting.accent_color || '#E9F5FE',
    widgetIcon: appSettings.appSettings.widget_setting.widget_icon || '',
  });

  const currencyData = useWoocommerceCurrencies(
    Number(portalId),
    appSettings.storeId
  );

  if (currencyData.data === undefined) {
    return <PartialPageSpinner />;
  }

  const onChangeElement = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | SelectChangeEvent
  ) => {
    const { name, value } = event.target;

    setState({ ...state, [name]: value });
  };

  const validateBase64Image = (base64Image: string): boolean => {
    const regex =
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;

    return regex.test(base64Image);
  };

  const validateForm = (validateFormData: AppSettingStateDataType) => {
    const validate = {
      currencyCode: (currencyCode: string) =>
        currencyCode.length > 0 && currencyCode !== '-1'
          ? true
          : t('store_currency') + ' ' + t('is_required'),
      widgetPosition: (widgetPosition: string) => true,
      appTitle: (appTitle: string) => true,
      primaryColor: (primaryColor: string) => true,
      textColor: (textColor: string) => true,
      bodyBackgroundColor: (bodyBackgroundColor: string) => true,
      headingColor: (headingColor: string) => true,
      bodyTextColor: (bodyTextColor: string) => true,
      accentColor: (accentColor: string) => true,
      widgetIcon: (widgetIcon: string) =>
        validateBase64Image(widgetIcon)
          ? true
          : t('please_provide_valid_widget_icon'),
      couponPrefix: (couponPrefix: string) =>
        couponPrefix.trim().length > 0
          ? true
          : t('coupon_prefix') + ' ' + t('is_required'),
      hubspotContactOwner: (hubspotContactOwner: string) =>
        hubspotContactOwner !== '-1'
          ? true
          : t('hubspot_owner') + ' ' + t('is_required'),
    };
    const errObj = {
      ...initialErrorStateData,
    };
    let errorFieldsVar = false;
    _.forIn(validateFormData, (value, key) => {
      const error = validate[key as keyof typeof validate](value as never);
      if (error !== true) {
        errorFieldsVar = true;
      }
      errObj[key as keyof AppSettingErrorDataType] = (error as string) ?? '';
    });
    isErrorRef.current = errorFieldsVar;
    setStateError({ ...stateError, ...errObj });
  };

  const getCurrencyObject = (currency: string) => {
    const obj = _.filter(currencyData.data.data, function (o) {
      return o.code === currency;
    });

    return obj[0];
  };

  const updateSettings = async () => {
    validateForm(state);
    if (isErrorRef.current) {
      enqueueSnackbar(t('fill_the_required_fields'), { variant: 'error' });

      return false;
    }
    try {
      setSubmitting(true);

      const response = await axiosInstance.put(
        `${UPDATE_APP_SETTINGS_API_URL}`,
        {
          portalId: portalId,
          appCode: POINTSANDREWARD,
          appSettings: {
            store_setting: {
              store_currency: getCurrencyObject(state.currencyCode),
            },
            widget_setting: {
              widget_position: state.widgetPosition,
              app_title: '',
              global_color: {
                primary_color: state.primaryColor,
                text_color: state.textColor,
              },
              body_background_color: state.bodyBackgroundColor,
              heading_color: state.headingColor,
              body_text_color: state.bodyTextColor,
              accent_color: state.accentColor,
              widget_icon: state.widgetIcon,
            },
            coupon_setting: {
              coupon_prefix: state.couponPrefix,
            },
            hubspot_setting: {
              hubspot_contact_owner: Number(state.hubspotContactOwner),
            },
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      }
    } catch (error) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleDrop = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (e.target.files) {
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const files = e.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = event => {
        //@ts-ignore
        setState({ ...state, [e.target.name]: event.target.result });
      };
    }
  };

  return (
    <div className='flex-grow basis-4/6 py-5'>
      <Card sx={{ padding: '15px' }}>
        <Typography sx={{ paddingBottom: '25px' }} variant='body1'>
          {t('store_settings')}
        </Typography>
        <Stack sx={{ paddingTop: '25px' }}>
          <FormControl>
            <InputLabel id='demo-simple-select-label-currency'>
              {t('store_currency')}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select-currency'
              name='currencyCode'
              value={state.currencyCode}
              label={t('store_currency')}
              onChange={onChangeElement}
            >
              <MenuItem key={-1} value={-1}>
                {t('select')}
              </MenuItem>
              {currencyData.data.data.map((row, index) => {
                return (
                  <MenuItem key={row.code} value={row.code}>
                    {row.code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {stateError.currencyCode.length > 0 && (
            <FormHelperText error={true}>
              <span>{stateError.currencyCode}</span>
            </FormHelperText>
          )}
        </Stack>
      </Card>
      <Box sx={{ mb: 5 }} />
      <Card sx={{ padding: '15px' }}>
        <Typography sx={{ paddingBottom: '25px' }} variant='body1'>
          {t('widget_settings')}
        </Typography>
        {/* <TextField
          type='text'
          sx={{ paddingBottom: '25px' }}
          fullWidth
          id='outlined-basic'
          name='appTitle'
          label={t('app_title')}
          variant='outlined'
          value={state.appTitle}
          onChange={onChangeElement}
          error={stateError.appTitle.length ? true : false}
          helperText={<span>{stateError.appTitle}</span>}
        /> */}
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='primaryColor'
          label={t('primary_color')}
          variant='outlined'
          value={state.primaryColor}
          onChange={onChangeElement}
          error={stateError.primaryColor.length ? true : false}
          helperText={<span>{stateError.primaryColor}</span>}
        />
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='textColor'
          label={t('text_color')}
          variant='outlined'
          value={state.textColor}
          onChange={onChangeElement}
          error={stateError.textColor.length ? true : false}
          helperText={<span>{stateError.textColor}</span>}
        />
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='bodyBackgroundColor'
          label={t('body_background_color')}
          variant='outlined'
          value={state.bodyBackgroundColor}
          onChange={onChangeElement}
          error={stateError.bodyBackgroundColor.length ? true : false}
          helperText={<span>{stateError.bodyBackgroundColor}</span>}
        />
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='headingColor'
          label={t('heading_color')}
          variant='outlined'
          value={state.headingColor}
          onChange={onChangeElement}
          error={stateError.headingColor.length ? true : false}
          helperText={<span>{stateError.headingColor}</span>}
        />
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='bodyTextColor'
          label={t('body_text_color')}
          variant='outlined'
          value={state.bodyTextColor}
          onChange={onChangeElement}
          error={stateError.bodyTextColor.length ? true : false}
          helperText={<span>{stateError.bodyTextColor}</span>}
        />
        <TextField
          sx={{ paddingBottom: '25px' }}
          type='color'
          fullWidth
          id='outlined-basic'
          name='accentColor'
          label={t('accent_color')}
          variant='outlined'
          value={state.accentColor}
          onChange={onChangeElement}
          error={stateError.accentColor.length ? true : false}
          helperText={<span>{stateError.accentColor}</span>}
        />
        <TextField
          fullWidth
          sx={{ direction: 'rtl' }}
          type='file'
          name='widgetIcon'
          label={t('widget_icon')}
          onChange={e => handleDrop(e)}
          // error={stateError.widgetIcon ? false : true}
          helperText={stateError.widgetIcon}
        ></TextField>
        {state.widgetIcon ? (
          <div style={{ padding: '25px' }}>
            <Image
              sx={{
                width: '25%',
                height: '25%',
              }}
              visibleByDefault
              disabledEffect
              src={state.widgetIcon}
            />
          </div>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& > *': {
              m: 1,
            },
            paddingTop: '25px',
          }}
        >
          <Typography variant='body1'>{t('position')}</Typography>
          <ButtonGroup
            sx={{ paddingLeft: '25px' }}
            color='secondary'
            aria-label='large secondary button group'
          >
            <Button
              sx={{
                backgroundColor:
                  state.widgetPosition === 'left' ? '#DFE3E8' : '#ffff',
              }}
              onClick={() => setState({ ...state, widgetPosition: 'left' })}
            >
              {t('left')}
            </Button>
            <Button
              sx={{
                backgroundColor:
                  state.widgetPosition === 'right' ? '#DFE3E8' : '#ffff',
              }}
              onClick={() => setState({ ...state, widgetPosition: 'right' })}
            >
              {t('right')}
            </Button>
          </ButtonGroup>
        </Box>
      </Card>
      <Box sx={{ mb: 5 }} />
      <Card sx={{ padding: '15px' }}>
        <Typography sx={{ paddingBottom: '25px' }} variant='body1'>
          {t('coupon_settings')}
        </Typography>
        <TextField
          fullWidth
          inputProps={{ maxLength: 10 }}
          id='outlined-basic'
          name='couponPrefix'
          label={t('coupon_prefix')}
          variant='outlined'
          value={state.couponPrefix}
          onChange={onChangeElement}
          error={stateError.couponPrefix.length ? true : false}
          helperText={<span>{stateError.couponPrefix}</span>}
        />
      </Card>
      <Box sx={{ mb: 5 }} />
      <Card sx={{ padding: '15px' }}>
        <Typography sx={{ paddingBottom: '25px' }} variant='body1'>
          {t('hubspot')}
        </Typography>
        <Stack sx={{ paddingTop: '25px' }}>
          <FormControl>
            <InputLabel id='demo-simple-select-label'>
              {t('hubspot_owner')}
            </InputLabel>
            <Select
              name='hubspotContactOwner'
              labelId='demo-simple-select-label-owner'
              id='demo-simple-select-hubspot-owner'
              value={state.hubspotContactOwner.toString()}
              label={t('hubspot_owner')}
              onChange={onChangeElement}
            >
              <MenuItem key={-1} value='-1'>
                {t('select')}
              </MenuItem>
              {hubspotOwners.map((row, index) => {
                return (
                  <MenuItem key={row.id} value={row.id}>
                    {row.email}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {stateError.hubspotContactOwner.length > 0 && (
            <FormHelperText error={true}>
              <span>{stateError.hubspotContactOwner}</span>
            </FormHelperText>
          )}
        </Stack>
      </Card>
      <Box sx={{ mb: 5 }} />
      <Stack>
        <LoadingButton
          fullWidth
          onClick={updateSettings}
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('save')}
          loading={submitting}
          className='mt-5 py-6'
        >
          {t('save')}
        </LoadingButton>
      </Stack>
    </div>
  );
};

export default GeneralSettingsForm;
