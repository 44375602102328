import React, { FunctionComponent } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack } from '@mui/material';
import { CREATE_STORE_API_URL } from 'api/ApiConstants/DiscountCouponsApiConstants';
import { useGetAllSources } from 'api/ApiHooks/DiscountCouponsApiHooks';
import usePortalId from 'hooks/portal/usePortalId';
import i18next from 'i18next';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'utils/axios';
import * as Yup from 'yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '../../../../../../components/hook-form';
import { StoreType } from '../../DiscountCouponsType';
import { useSnackbar } from 'notistack';
import { isApiError } from 'utils/apiFunctions';

const StoreSchema = Yup.object().shape({
  storeSource: Yup.string()
    .not(['-1'], i18next.t('please_select_store_source'))
    .required(i18next.t('store_source_is_required')),
  storeUrl: Yup.string()
    .label(i18next.t('store_url'))
    .url()
    .required(i18next.t('store_url_is_required')),
});

type FormValuesProps = {
  storeSource: number;
  storeUrl: string;
  afterSubmit?: string;
};

type Props = {
  storeDetails?: StoreType;
  appCode: string;
  returnUrl: string;
};

export const StoreForm: FunctionComponent<Props> = ({
  storeDetails,
  appCode,
  returnUrl,
}) => {
  const { t } = useTranslation();
  const { data } = useGetAllSources();
  const portalId = usePortalId();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    storeSource: storeDetails?._id || -1,
    storeUrl: storeDetails?.storeUrl || '',
  };

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(StoreSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const result = await axiosInstance.post(
        CREATE_STORE_API_URL,
        {
          source: data.storeSource,
          storeUrl: data.storeUrl,
          portalId: Number(portalId),
          returnUrl: process.env.REACT_APP_BASE_URL + returnUrl,
          appCode: appCode,
        },
        {
          maxRedirects: 0,
        }
      );

      window.location.href = result.data;
    } catch (error: any) {
      if (isApiError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert severity='error' sx={{ alignItems: 'center' }}>
              {errors.afterSubmit.message}
            </Alert>
          )}
          <RHFSelect placeholder={t('store_source')} name='storeSource'>
            <option value='-1' disabled key='-1' className='text-gray-300'>
              {t('store_source')}
            </option>
            {_.map(data?.data, function (store, key) {
              return (
                <option
                  disabled={store.name !== 'woocommerce'}
                  key={store._id}
                  value={store.name}
                >
                  {store.label}
                </option>
              );
            })}
          </RHFSelect>
          <RHFTextField
            placeholder={t('store_url')}
            id='storeUrl'
            name='storeUrl'
          />
        </Stack>
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          aria-label={t('login')}
          loading={isSubmitting}
          className='mt-5 py-6'
        >
          {t('authorize')}
        </LoadingButton>
      </FormProvider>
    </div>
  );
};
